import axios from "axios";
const authUrl = process.env.REACT_APP_BASE_URL + "/api/auth";
const adminUrl = process.env.REACT_APP_BASE_URL + "/api/admin";
const superadminUrl = process.env.REACT_APP_BASE_URL + "/api/superadmin";

const registerUser = async (user) => {
  try {
    const response = await axios.post(`${authUrl}/register`, user);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const loginUserWithPassword = async (user) => {
  try {
    const response = await axios.post(`${authUrl}/login/password`, user);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const loginUserWithPin = async (user) => {
  try {
    const response = await axios.post(`${authUrl}/login`, user);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const fetchUser = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${adminUrl}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data;
  }
};

const loginSuperadmin = async (credentials) => {
  try {
    const response = await axios.post(`${superadminUrl}/login`, credentials);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};


export const fetchSuperadminData = async (dataType) => {
  console.log('fetchSuperadminData called with dataType:', dataType);
  try {
    const token = localStorage.getItem("superadminToken");
    console.log('Token from localStorage:', token);
    const fullUrl = `${superadminUrl}/${dataType}`;
    console.log('Fetching from URL:', fullUrl);
    const response = await axios.get(fullUrl, {
      headers: {
        "x-auth-token": token,
      },
    });
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in fetchSuperadminData:', error.response ? error.response.data : error);
    throw error.response ? error.response.data : error;
  }
};


export { registerUser, loginUserWithPassword, fetchUser, loginUserWithPin, loginSuperadmin };
