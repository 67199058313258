import React, { useEffect, useState } from "react";
import { fetchStatus } from "../../api/user";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Waiting = () => {
  const [text, setText] = useState("Checking with the cafe...");
  const order = useSelector((state) => state.user.order);
  let inter = null;

  const navigate = useNavigate();

  function pollStatus() {
    fetchStatus().then((res) => {
      if (res.data === "PREPARING") {
        window.location.href = `/order/bill/${order._id}`;
        clearInterval(inter);
      } else if (res.data === "REJECTED") {
        window.location.href = "/order/rejected";
        clearInterval(inter);
      }
    }).catch((err) => {
      clearInterval(inter);
      if (err.response.data.message === "Cafe is closed")
        navigate("/cafe/closed")
    });
  }

  const reasons = [
    "Cafe is busy, trying again!",
    "Sabka time aayega!",
    "Araam se baitho, ham karte hain prabandh!",
    "Kuch der aur ruk jao!",
    "Kuch der aur intezar karo!",
  ]

  useEffect(() => {
    pollStatus();
    inter = setInterval(() => {
      pollStatus();
      console.log({ inter })
    }, 5000);

    let index = 0;

    setInterval(() => {
      if (index < reasons.length) {
        setText(reasons[index]);
      } else {
        setText("This is humilating, can you please wait a bit more?");
      }
      index++;
    }, 9000);
  }, []);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <p>{text}</p>
      <img src="../Countdown.gif" alt="Loading" className="h-60" />
      Your order is waiting to be accepted by the cafe.
    </div>
  );
};

export default Waiting;
