import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/Header";
import CafeDetailsComponent from "../../components/cafe-admin/CafeDetailsComponent";
import { getCafe } from "../../api/cafe";
import { fetchUser } from "../../api/auth";
import GalleryImagesComponent from "../../components/cafe-admin/GalleryImagesComponent";
import OfferManagementComponent from "../../components/cafe-admin/OfferManagementComponent";
import FrontPage from "../user/frontpage";


const Profile = () => {
  const cafeData = useSelector((state) => state.auth.cafe);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("details");

  useEffect(() => {
    getCafe().catch(() => {
      localStorage.removeItem("OG_token");
      navigate("/cafe-admin/login");
    });
  }, [navigate]);

  useEffect(() => {
    checkRegisteredCafe().catch(() => {
      localStorage.removeItem("OG_token");
      navigate("/cafe-admin/login");
    });
  }, [cafeData, navigate]);

  async function checkRegisteredCafe() {
    const resp = await fetchUser();
    if (!resp.data.cafe || !resp.data.cafe.name) {
      navigate("/cafe-admin/register");
    }
  }

  function logout() {
    localStorage.removeItem("OG_token");
    navigate("/cafe-admin/login");
  }

  if (!cafeData) {
    return <div>Loading...</div>;
  }
  const renderActiveTab = () => {
    switch (activeTab) {
      case "details":
        return <CafeDetailsComponent />;
      case "gallery":
        return <GalleryImagesComponent />;
      case "offers":
        return <OfferManagementComponent />;
      default:
        return null;
    }
  };
  return (
    <>
      <Header />
      <div className="w-full min-h-screen flex flex-col items-center">
        <ToastContainer />
        {cafeData.name ? (
          <>
            
            <FrontPage cafeData={cafeData} editable="true"/>
            <button
              onClick={logout}
              className="bg-red-500 mx-4 mt-8 px-4 py-2 text-white flex items-center rounded"
            >
              <LogoutOutlined className="mr-2" />
              Logout
            </button>
          </>
        ) : (
          <img src="../loading.gif" alt="Loading" />
        )}
      </div>
      {/* <BottomMenu /> */}
    </>
  );
};

export default Profile;