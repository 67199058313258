import React, { useState, useEffect } from 'react'
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, removeFromCart, updateQuantity } from '../redux/slices/user'
import { Link } from 'react-router-dom'

const AddToCart = ({ open, setOpen, item }) => {
  const dispatch = useDispatch()
  const [variants, setVariants] = useState(item.variant?.split(',')[0] || 'Regular')
  const [count, setCount] = useState(1)
  const [inBag, setInBag] = useState(false)
  const cart = useSelector((state) => state.user.cart)

  useEffect(() => {
    const inCart = cart.find((food) => food.id === item._id)
    if (inCart) {
      setCount(inCart.quantity)
      setVariants(inCart.variant)
      setInBag(true)
    }
    else {
      setInBag(false)
    }
  }, [cart, open])

  if (!open) return null

  function addToBag() {
    const food = { variant: variants, quantity: count, id: item._id, name: item.name, price: item.price, image: item.image }
    dispatch(addToCart(food))
    setInBag(true)
    setOpen(false)
  }

  function removeFromBag() {
    dispatch(removeFromCart(item._id));
  }

  function updateQuantityInBag(quantity) {
    dispatch(updateQuantity({ id: item._id, quantity }));
  }

  function handleIncreaseQuantity() {
    setCount(count + 1)
    updateQuantityInBag(count + 1)
  }
  function handleDecreaseQuantity() {
    setCount(count - 1)
    if (count - 1 === 0) removeFromBag()
    else updateQuantityInBag(count - 1)
  }

  return (
    <div className={'fixed bottom-0 h-screen bg-black/70 w-full flex flex-col items-center z-50'}>
      <div className="h-1/3 w-full absolute top-0" onClick={() => setOpen(false)}></div>
      <div onScroll={() => setOpen(false)} className={"pb-40 bg-white w-full rounded-t-2xl opacity-100 fixed bottom-0"}>
        <div className="flex justify-between items-center px-4 py-4">
          <div className="h-1 w-20 rounded-3xl bg8-gray-400 mx-auto"></div>
          <button onClick={() => setOpen(false)} className="text-red-500 absolute right-5 rounded"><CloseOutlined /></button>
        </div>
        <div className="flex flex-col items-center">
          {item.image && <img src={`data:image/png;base64,${item?.image}`} alt={item.name} className='h-40 w-40 mb-6 rounded-full object-cover mx-auto' />}
          <div className="flex flex-col items-center mb-8">
            <div className="items-center flex">
              {item.veg ?
                <img width="16" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgUlEQVR4nM2S0QmAMAxE+51Mpu5gspIgXsURHMtBlICKaMWIiB7cT2heyiUh/EYCHiRS7zJ4OAIi9e5hqbeyKyooV1A1O78FUDA08ri1gBoXQG3yrnmFtFR4ANUpANy9Dyhbys4AS5iXIQqoSTTX7i2YLDD7svn2GsOF0oCnp/yZJmb+qB4yMqyCAAAAAElFTkSuQmCC" alt="vegetarian-food-symbol" className='mr-1' />
                :
                <img width="16" height="16" src="https://img.icons8.com/color/16/94D82D/non-vegetarian-food-symbol.png" alt="non-vegetarian-food-symbol" className='mr-1' />}
              <h2 className='text-md text-gray-600 flex'>{item.category}</h2>
            </div>
            <h1 onClick={() => setOpen} className='text-3xl font-bold'>
              {item.name}
            </h1>
          </div>
          <div className="flex justify-between items-center w-full px-8">
            <h1 className='text-3xl font-bold'>
              ₹{item.price}
            </h1>
            <div className="flex mb-2">
              <button onClick={handleDecreaseQuantity} disabled={count === 1 || inBag} className='disabled:bg-[#ed6e42] bg-[#FD7347] text-white h-10 w-8 flex items-center justify-center font-bold rounded-l-md'>
                <MinusOutlined />
              </button>
              <p className={`w-16 h-10 bg-[#FD7347] text-white flex items-center justify-center text-md font-semibold`}>
                {count}
              </p>
              <button onClick={handleIncreaseQuantity} disabled={inBag} className='disabled:bg-[#FD7347] bg-[#FD7347] text-white h-10 w-8 flex items-center justify-center font-bold rounded-r-md'>
                <PlusOutlined />
              </button>
            </div>
          </div>
          {item.variant && <div className="flex flex-col w-full px-8 mt-4">
            <h2 className='text-lg font-semibold mt-4 mb-2'>Select Variant</h2>
            <div className="flex">
              {
                item.variant?.split(',')?.map((variant, index) => {
                  const vari = variant.trim();
                  return (
                    <div onClick={() => setVariants(vari)} className={`flex p-1 rounded w-20 justify-center mr-1 ${variants === vari ? "bg-[#FD7347] shadow-2xl border" : "border-2"}`}>
                      <label for={vari} key={index} htmlFor={vari} className={`text-sm font-medium ${variants === vari ? "text-white" : "text-gray-500"}`}>{vari}</label>
                    </div>
                  )
                })
              }
            </div>

            
          </div>}
          <div className="flex justify-between px-8 mt-4 w-full">
            {item.servinginfo && (
              <div>
                <h2 className='text-lg font-semibold mt-4 mb-2'>Serving Info</h2>
                <p>{item.servinginfo}</p>
              </div>
            )}

            {item.portionsize && (
              <div>
                <h2 className='text-lg font-semibold mt-4 mb-2'>Portion Size</h2>
                <p>{item.portionsize}</p>
              </div>
            )}
          </div>
          {inBag ?
            <div className="flex flex-col absolute bottom-0 my-10">
              <Link
                to={"/cafe/bag"}
                className="flex items-center justify-center mx-4 w-80 h-16 bg-[#FD7347] text-white py-2 px-4 rounded-2xl"
              >
                Go to Bag
              </Link>
            </div>
            :
            <div className="flex flex-col absolute bottom-0 my-10">
              <button onClick={addToBag} disabled={count === 0} className='disabled:bg-[#FD7347] block mx-4 w-80 h-16 bg-[#FD7347] text-white py-2 px-4 rounded-2xl'>
                Add to bag
              </button>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default AddToCart