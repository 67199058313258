import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'; // Icons for navigation and close

const GalleryPopup = ({ images, currentIndex, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(currentIndex);

  if (!images || images.length === 0) return null;

  const handleNext = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative max-w-3xl w-full p-4">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition"
        >
          <FaTimes />
        </button>

        {/* Previous Button */}
        {currentImageIndex > 0 && (
          <button
            onClick={handlePrevious}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition"
          >
            <FaChevronLeft />
          </button>
        )}

        {/* Current Image */}
        <img
          src={`data:image/webp;base64,${images[currentImageIndex]}`}
          alt="Gallery"
          className="w-full h-80 rounded-lg object-contain"
        />

        {/* Next Button */}
        {currentImageIndex < images.length - 1 && (
          <button
            onClick={handleNext}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition"
          >
            <FaChevronRight />
          </button>
        )}

        {/* Close Button (for mobile or extra) */}
        {/* <button
          onClick={onClose}
          className="mt-4 w-full bg-purple-500 text-white py-2 rounded-lg hover:bg-purple-600 transition-colors"
        >
          Close
        </button> */}
      </div>
    </div>
  );
};

export default GalleryPopup;
