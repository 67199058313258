import axios from "axios";
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
const cafeUrl = process.env.REACT_APP_BASE_URL + "/api/cafe";




const getPincode = async (pincode) => {
  try {
    const response = await axios.get(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
    return response.data[0];
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};
const updateEmail = async (email) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/email`,
      { email },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};
const updatePhone = async (phone) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/phone`,
      { phone },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};
const updateName = async (name) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/name`,
      { name },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};
const registerCafe = async (cafe) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.post(`${cafeUrl}/register`, cafe, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};
const toggleShutter = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/shutter`,
      {},
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const addItemToMenu = async (item) => {
  try {
    const token = localStorage.getItem("OG_token");
    // If price is an empty string, set it to null
    if (item.get('price') === '') {
      item.set('price', null);
    }
    const response = await axios.patch(`${cafeUrl}/items`, item, {
      headers: {
        "x-auth-token": token,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateItem = async (item, id) => {
  try {
    const token = localStorage.getItem("OG_token");
    // If price is an empty string, set it to null
    if (item.get('price') === '') {
      item.set('price', null);
    }
    const response = await axios.patch(`${cafeUrl}/item/detail/${id}`, item, {
      headers: {
        "x-auth-token": token,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const deleteItem = async (id) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.delete(`${cafeUrl}/item/${id}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};


const getCafe = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/items`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const alterUPI = async (upiId) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/upi`,
      { upiId },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const alterReviewLink = async (reviewLink) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/review`,
      { reviewLink },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateImageBanner = async (image) => {
  let formData = new FormData();
  formData = {
    ...formData,
    image,
  }
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(`${cafeUrl}/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getInsights = async (formattedStartDate, formattedEndDate) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/analytics`, {
      params: {
        formattedStartDate: formattedStartDate,
        formattedEndDate: formattedEndDate,
      },
      headers: {
        "x-auth-token": token,
      },
    });

    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const fetchOrders = async () => {
  const token = localStorage.getItem("OG_token");
  try {
    const response = await axios.get(`${cafeUrl}/orders`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const fetchOrder = async (id) => {
  const token = localStorage.getItem("OG_token");
  try {
    const response = await axios.get(`${cafeUrl}/order/${id}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data;
  }
};

const updateStatus = async (status, order) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.post(
      `${cafeUrl}/order/status`,
      {
        status,
        order,
      },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const toggleItemInStock = async (id) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/item/stock/${id}`,
      {},
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateFssaiCertificate = async (image) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(`${cafeUrl}/fssai-certificate`, image, {
      headers: {
        "x-auth-token": token,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getFssaiCertificate = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/fssai-certificate`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateFssaiRegistrationNumber = async (fssai) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.patch(
      `${cafeUrl}/fssai`,
      { fssai },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const sendAlert = async (alertData) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.post(
      `${cafeUrl}/send-notification`,
      alertData,
      {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Send notification response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Send notification error:",
      error.response ? error.response.data : error.message
    );
    return error.response ? error.response.data : { error: error.message };
  }
};
const fetchAlert = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/notification/fetch`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};
const fetchAlert1 = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/notification/fetch1`, {
      headers: {
        "x-auth-token": token,
      },
    });

    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getOrderByStatus = async (status) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/orders/status/${status}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const fetchPermissions = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/permissions`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching permissions:", error);
    throw error;
  }
};

const updatePermissions = async (permissions) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.put(`${cafeUrl}/permissions`, permissions, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating permissions:", error);
    throw error;
  }
};


const getAnalyticsEvents = async (startDate, endDate) => {
  try {
    const token = localStorage.getItem("OG_token");
    console.log("This is the OG token: ", token);

    // Convert dates to IST
    const istStartDate = toZonedTime(new Date(startDate), 'Asia/Kolkata');
    const istEndDate = toZonedTime(new Date(endDate), 'Asia/Kolkata');

    // Format dates
    const formattedStartDate = format(istStartDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    const formattedEndDate = format(istEndDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

    console.log("Fetching analytics events with dates:", {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/cafe/analytics/events`,
      {
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
        headers: {
          "x-auth-token": token,
        },
      }
    );
    console.log("Analytics events response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching analytics events:", error);
    throw error;
  }
};

const updateCafeDetails = async (details) => {
  try {
    const token = localStorage.getItem("OG_token");
    // const formData = new FormData();

    // Object.keys(details).forEach(key => {
    //   if (key === 'logo' && details[key] instanceof File) {
    //     formData.append('logo', details[key]);
    //   } else if (key === 'other_social_links') {
    //     formData.append(key, JSON.stringify(details[key]));
    //   } else {
    //     formData.append(key, details[key]);
    //   }
    // });

    const response = await axios.put(`${cafeUrl}/details`, details, {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const removeCafeField = async (field) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.delete(`${cafeUrl}/details/${field}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getCafeDetails = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/details`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const uploadGalleryImages = async (images) => {
  try {
    const token = localStorage.getItem("OG_token");
    let formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`images`, image);
    });
    const response = await axios.post(`${cafeUrl}/gallery-images`, formData, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const deleteGalleryImage = async (index) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.delete(`${cafeUrl}/gallery-image/${index}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getGalleryImages = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/gallery-images`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const createOffer = async (offerData) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.post(`${cafeUrl}/offers`, offerData, {
      headers: {
        "x-auth-token": token,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getOffers = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/offers`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateOfferStatus = async (offerId, isActive, expirationDate = null) => {
  try {
    const token = localStorage.getItem("OG_token");
    const payload = { isActive };
    if (expirationDate) {
      payload.expirationDate = expirationDate;
    }
    const response = await axios.patch(
      `${cafeUrl}/offers/${offerId}/status`,
      payload,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    throw error.response.data;
  }
};

const getActiveOffers = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/offers/active`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getInactiveOffers = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/offers/inactive`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getCafeReviewStats = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/review-stats`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateOperatingHours = async (operatingHours) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.put(
      `${cafeUrl}/operating-hours`,
      operatingHours,
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getOperatingHours = async (cafeId) => {
  try {
    const response = await axios.get(`${cafeUrl}/operating-hours/${cafeId}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};


const getPublicCafeReviewStats = async (id) => {
  try {
    const response = await axios.post(`${cafeUrl}/public/review-stats`, {
      id,
    });
    console.log("Public cafe review stats:", response.data);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getOfferAnalytics = async (offerId, startDate, endDate) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/offers/${offerId}/analytics`, {
      params: { start_date: startDate, end_date: endDate },
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getAllOfferAnalytics = async (startDate, endDate) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/offers/analytics`, {
      params: { start_date: startDate, end_date: endDate },
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const getFacilities = async (cafeId) => {
  try {
    const response = await axios.get(`${cafeUrl}/facilities/${cafeId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching facilities:", error);
    return { success: false, message: error.message };
  }
};

const addFacility = async (facility) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.post(`${cafeUrl}/facilities`, facility, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateFacility = async (index, facility) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.put(`${cafeUrl}/facilities/${index}`, facility, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const deleteFacility = async (index) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.delete(`${cafeUrl}/facilities/${index}`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};


const updateAddress = async (addressData) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.put(`${cafeUrl}/address`, addressData, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

export const getUsername = async () => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.get(`${cafeUrl}/username`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const updateUsername = async (username) => {
  try {
    const token = localStorage.getItem("OG_token");
    const response = await axios.post(
      `${cafeUrl}/username`,
      { username },
      {
        headers: {
          "x-auth-token": token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};

const checkUsername = async (username) => {
  try {
    const response = await axios.get(`${cafeUrl}/username/check/${username}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return error.response.data;
  }
};


const getCafeIdByUsername = async (username) => {
  try {
    const response = await axios.get(`${cafeUrl}/username/${username}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching cafe ID by username:", error);
    throw error;
  }
};
export {
  getPublicCafeReviewStats,
  getPincode,
  updateEmail,
  updatePhone,
  updateName,
  registerCafe,
  toggleShutter,
  addItemToMenu,
  getCafe,
  updateItem,
  deleteItem,
  alterUPI,
  alterReviewLink,
  updateImageBanner,
  getInsights,
  fetchOrders,
  fetchOrder,
  updateStatus,
  toggleItemInStock,
  updateFssaiCertificate,
  getFssaiCertificate,
  updateFssaiRegistrationNumber,
  sendAlert,
  fetchAlert,
  fetchAlert1,
  getOrderByStatus,
  fetchPermissions,
  updatePermissions,
  getAnalyticsEvents,
  updateCafeDetails,
  removeCafeField,
  getCafeDetails,
  uploadGalleryImages,
  deleteGalleryImage,
  getGalleryImages,
  createOffer,
  getOffers,
  updateOfferStatus,
  getActiveOffers,
  getInactiveOffers,
  getCafeReviewStats,
  updateOperatingHours,
  getOperatingHours,
  getOfferAnalytics,
  getAllOfferAnalytics,
  getFacilities,
  addFacility,
  updateFacility,
  deleteFacility,
  updateAddress,
  updateUsername,
  checkUsername,
  getCafeIdByUsername
};
