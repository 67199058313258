import React from "react";
import { FaStar, FaRegSmile } from "react-icons/fa";
import StarRating from "../StarRating";

const RatingCard = ({ rating, count ,question ="how is the cafe"}) => {
  return (
    <div
      className="bg-white rounded-xl shadow-md p-4 max-w-sm  "
      style={{ boxShadow: "1px 2px 2px 2px #000000" }}
    >
      <div className="flex items-center ">
        <FaRegSmile size={30} className="text-gray-400 mr-2 text-xl" />
        <div className="flex flex-col">
          <StarRating
            rating={rating}
            count={count}
          />
          <div className="text-2xl font-bold ">
           {parseFloat(rating).toFixed(2)} ({count})
        </div>
        </div>
      </div>
      <p className="text-gray-600 text-sm  " style={{whiteSpace:"pre-wrap"}}>
        {question} 
      </p>
      <div className="flex justify-center mt-4">
        <div className="flex space-x-1">
          {[...Array(7)].map((_, i) => (
            <div
              key={i}
              className={`w-1.5 h-1.5 rounded-full ${
                i === 0 ? "bg-gray-400" : "bg-gray-200"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RatingCard;
