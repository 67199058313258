import React from "react";
import { Link } from "react-router-dom";

function Thankyou() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="inline-block rounded-full bg-green-300 p-4">
          <CheckIcon className="h-6 w-6 text-teal-700" />
        </div>
        <h1 className="mt-6 text-4xl font-bold tracking-tight text-teal-700 sm:text-5xl">
          Thank You!
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          We appreciate your review. Thank you for choosing our app!
        </p>
        <div className="mt-8">
          <Link
            to="/cafe/menu"
            className="inline-flex items-center rounded-md bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm transition-colors hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          >
            Back to App
          </Link>
        </div>
      </div>
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
}

export default Thankyou;
