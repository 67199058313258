import React from 'react'

const Switch = ({ clickAction, toggle }) => {
  const toggleClass = " transform translate-x-7";
  return (
    <div className="flex justify-between items-center p-4">
      <div
        className={`md:w-14 md:h-7 w-16 h-8 flex items-center ${toggle ? "bg-teal-500" : "bg-gray-400"} rounded-full p-1 cursor-pointer`}
        onClick={() => {
          clickAction();
        }}
      >
        <div
          className={
            "bg-white md:w-6 md:h-6 h-7 w-7 rounded-full shadow-md transform duration-300 ease-in-out" +
            (toggle ? toggleClass : null)
          }
        ></div>
      </div>
    </div>
  )
}

export default Switch