import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ViewCartBubble = () => {
  const cartItems = useSelector(state => state.cart.items);
  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);
  const navigate = useNavigate();

  if (totalItems === 0) return null;

  return (
    <div 
      className="fixed bottom-20 right-4 bg-green-500 text-white rounded-full p-4 flex items-center cursor-pointer shadow-lg"
      onClick={() => navigate('/cafe/checkout')}
    >
      <div className="mr-2">
        <span className="font-bold">View cart</span>
        <br />
        <span>{totalItems} item{totalItems !== 1 ? 's' : ''}</span>
      </div>
      <span className="text-2xl">&gt;</span>
    </div>
  );
};

export default ViewCartBubble;