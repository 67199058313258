import React from 'react'
import Offercard from '../../components/Ordercard'
import { useState,useEffect } from 'react';
import BottomMenu from '../../components/user/BottomMenu';
const Offer = () => {

  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    // Get the current visit count from localStorage
    let count = localStorage.getItem('visitCount');
    if (!count) {
      count = 0;
    } else {
      count = parseInt(count, 10);
    }

    // Increment the count
    const newCount = count + 1;

    // Save the new count back to localStorage
    localStorage.setItem('visitCount', newCount);

    // Update the state
    setVisitCount(newCount);
  }, []);
console.log("count:",visitCount);
  return (
    <div>
      <Offercard/>
      <BottomMenu/>
    </div>
  )
}

export default Offer