
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import BottomMenu from "../../components/BottomMenu";
import { fetchOrders } from "../../api/cafe";
import OrdersItem from "../../components/OrdersItem";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../redux/slices/admin";
import { Link } from "react-router-dom";
import "./style.css"; // Ensure this path is correct based on your project structure
import Universalinput from "../../components/Universalnput"

const OrdersAll = () => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [dates, setDates] = useState(new Date());
  const [o, setO] = useState({});

  const [showOrdered, setShowOrdered] = useState(true);
  const [showPreparing, setShowPreparing] = useState(true);
  const [showServed, setShowServed] = useState(true);
  const [showCompleted, setShowCompleted] = useState(true);
  const [showAll, setShowAll] = useState(true);

  const [pop, setPop] = useState(false);

  const [socket, setSocket] = useState(null);
  const [listedmessage, setListenedMessage] = useState("");

  const ordersFromState = useSelector((state) => state.admin.orders);

  function getOrders() {
    fetchOrders()
      .then((res) => {
        setDate(Object.keys(res.data)[0]);
        setDates(Object.keys(res.data));
        setO(res.data);
        dispatch(setOrders(res.data));
      })
      .catch((error) => {
        setDates(null);
        setDate(null);
        setO(null);
      });
  }
  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div className="w-full flex flex-col items-center">
      <Header/>
      <Helmet
        title="Orders"
        meta={[
          {
            name: "description",
            content: "Orders for your cafe",
          },
          {
            property: "og:title",
            content: "Orders",
          },
        ]}
      />
      <div className="w-full h-full flex flex-col justify-center items-center">
        <h1 className="text-3xl font-semibold my-4">Orders</h1>
        <div className="flex w-full overflow-x-scroll overflow-y-hidden mb-3 px-4 pb-2">
          <div className="flex items-center mr-5">
          <Universalinput
              type="checkbox"
              name="ALL"
              id="ALL"
              className="mr-2 custom-checkbox"
              checked={showAll}
              onChange={() => {
                setShowAll(!showAll);
                setShowOrdered(false);
                setShowPreparing(false);
                setShowServed(false);
                setShowCompleted(false);
              }}
            />
            <label htmlFor="ALL">ALL</label>
          </div>
          <div className="flex items-center mr-5">
          <Universalinput
              type="checkbox"
              name="ORDERED"
              id="ORDERED"
              className="mr-2 custom-checkbox"
              checked={showOrdered || showAll}
              onChange={() => {
                setShowOrdered(!showOrdered);
                showAll && setShowAll(false);
              }}
            />
            <label htmlFor="ORDERED">ORDERED</label>
          </div>
          <div className="flex items-center mr-5">
          <Universalinput
              type="checkbox"
              name="PREPARING"
              id="PREPARING"
              className="mr-2 custom-checkbox"
              checked={showPreparing || showAll}
              onChange={() => {
                setShowPreparing(!showPreparing);
                showAll && setShowAll(false);
              }}
            />
            <label htmlFor="PREPARING">PREPARING</label>
          </div>
          <div className="flex items-center mr-5">
          <Universalinput
              type="checkbox"
              name="SERVED"
              id="SERVED"
              className="mr-2 custom-checkbox"
              checked={showServed || showAll}
              onChange={() => {
                setShowServed(!showServed);
                showAll && setShowAll(false);
              }}
            />
            <label htmlFor="SERVED">SERVED</label>
          </div>
          <div className="flex items-center mr-5">
          <Universalinput
              type="checkbox"
              name="COMPLETED"
              id="COMPLETED"
              className="mr-2 custom-checkbox"
              checked={showCompleted || showAll}
              onChange={() => {
                setShowCompleted(!showCompleted);
                showAll && setShowAll(false);
              }}
            />
            <label htmlFor="COMPLETED">COMPLETED</label>
          </div>
        </div>
        <div className="flex w-full overflow-x-scroll mt-1 overflow-y-hidden mb-3 px-4 pb-2">
          {dates &&
            dates.length > 0 &&
            dates?.map((val) => (
              <button
                key={val}
                className={`px-4 py-2 rounded-lg flex items-center justify-center bg-[#FD7347] min-w-40 border-gray-300 ${
                  date === val
                    ? "bg-[#FD7347] text-white"
                    : "bg-white text-gray-500"
                }`}
                onClick={() => setDate(val)}
              >
                {val}
              </button>
            ))}
        </div>

        {!ordersFromState || !date || !ordersFromState[date] ? (
          <p>No Orders Found yet</p>
        ) : (
          <div className="w-full px-4 ">
            {(showAll || showOrdered) &&
              ordersFromState[date] &&
              ordersFromState[date]["ORDERED"]?.map((order) => (
                <OrdersItem key={order._id} order={order} />
              ))}
            {(showAll || showPreparing) &&
              ordersFromState[date] &&
              ordersFromState[date]["PREPARING"]?.map((order) => (
                <OrdersItem key={order._id} order={order} />
              ))}
            {(showAll || showServed) &&
              ordersFromState[date] &&
              ordersFromState[date]["SERVED"]?.map((order) => (
                <OrdersItem key={order._id} order={order} />
              ))}
            {(showAll || showCompleted) &&
              ordersFromState[date] &&
              ordersFromState[date]["COMPLETED"]?.map((order) => (
                <OrdersItem key={order._id} order={order} />
              ))}
          </div>
        )}
      </div>
      <Link
        to={"/cafe-admin/orders"}
        className="w-10 absolute top-[145px] left-[5%] lg:left-[25%] md:left-[15%] xl:left-[33%] text-sm lg:text-base bg-white text-black h-8 flex items-center justify-center rounded ml-2 cursor-pointer shadow"
      >
        <div> &lt; </div>
      </Link>
      <div className="w-full h-20"></div>
      <BottomMenu page="orders" />
    </div>
  );
};

export default OrdersAll;
