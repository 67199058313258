import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSession, getCafe } from "../../api/user";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setCafe } from "../../redux/slices/user";
import { getCafeIdByUsername } from "../../api/cafe"; // Add this import


const Init = () => {
  const dispatch = useDispatch();
  const { cafe } = useParams();
  const navigate = useNavigate();
  console.log({ cafe });

  const [searchParams] = useSearchParams();
  const table = searchParams.get("table");
  const [loading, setLoading] = useState(true);
  const [cafeIsOpen, setCafeIsOpen] = useState(false);
  const [isUsername, setIsUsername] = useState(false);


  async function fetchCafe(cafeId) {
    try {
      const res = await getCafe(cafeId);
      dispatch(setCafe({ ...res.data, table }));
      sessionStorage.setItem("OG_cafe", JSON.stringify({ ...res.data, table }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cafe:", error);
      setLoading(false);
    }
  }

  async function loadItems(cafeId) {
    try {
      const res = await createSession(cafeId, table);
      await fetchCafe(cafeId);
      if (res.success) {
        setCafeIsOpen(true);
      } else {
        navigate('/cafe/closed');
      }
    } catch (error) {
      console.error("Error creating session:", error);
      navigate('/cafe/closed');
    }
  }

  useEffect(() => {
    async function initializeSession() {
      let cafeId = cafe;

      // Check if cafe is not a valid UUID
      if (!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(cafe)) {
        try {
          const response = await getCafeIdByUsername(cafe);
          if (response.success) {
            cafeId = response.data.cafeId;
            setIsUsername(true);
            sessionStorage.setItem("OG_isUsername", "true");
          } else {
            navigate('/404'); // or handle the error as needed
            return;
          }
        } catch (error) {
          console.error("Error fetching cafe ID by username:", error);
          navigate('/404'); // or handle the error as needed
          return;
        }
      }

      await loadItems(cafeId);
    }

    initializeSession();
  }, [cafe]);

  useEffect(() => {
    if (cafeIsOpen && !loading) {
      const name = localStorage.getItem("OG_name");
      const phone = localStorage.getItem("OG_phone");
      if (name && phone) {
        if (isUsername) {
          navigate(`/cafe/profile`);
        } else {
          navigate(`/cafe/menu`);
        }
      } else {
        navigate(`/cafe/register`);
      }
    }
  }, [cafeIsOpen, loading, isUsername]);

  return (
    <div className="w-full flex items-center justify-center h-screen">
      <img src="../loading.gif" alt="Loading" />
    </div>
  );
};

export default Init;