import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPOSData as fetchPOSDataAPI, createUserBag as createUserBagAPI, createKOT as createKOTAPI, generateBill as generateBillAPI, fetchPOSMenuItems as fetchPOSMenuItemsAPI, fetchKOTs as fetchKOTsAPI, updateKOTStatus as updateKOTStatusAPI } from '../../api/pos';
import { fetchActiveBags as fetchActiveBagsAPI, fetchBag as fetchBagAPI, updateBag as updateBagAPI, closeBag as closeBagAPI } from '../../api/pos';

import {
  getBagItems as getBagItemsAPI,
  addItemsToBag as addItemsToBagAPI,
  createKOTFromBagItems as createKOTFromBagItemsAPI
} from '../../api/pos';
import axios from 'axios';

const posUrl = process.env.REACT_APP_BASE_URL + "/api/pos";
const cafeUrl = process.env.REACT_APP_BASE_URL + "/api/cafe";

const getToken = () => localStorage.getItem("OG_token");
export const fetchPOSData = createAsyncThunk('pos/fetchPOSData', fetchPOSDataAPI);
export const createUserBag = createAsyncThunk('pos/createUserBag', createUserBagAPI);
export const createKOT = createAsyncThunk('pos/createKOT', createKOTAPI);
export const generateBill = createAsyncThunk('pos/generateBill', generateBillAPI);
export const fetchPOSMenuItems = createAsyncThunk('pos/fetchPOSMenuItems', fetchPOSMenuItemsAPI);
export const fetchKOTs = createAsyncThunk('pos/fetchKOTs', async () => {
  const response = await fetchKOTsAPI();
  console.log('fetchKOTs response:', response); // Debug log
  return response.data; // Return only the data array
});
export const updateKOTStatus = createAsyncThunk(
  'pos/updateKOTStatus',
  async ({ kotId, status }, { rejectWithValue }) => {
    try {
      if (!kotId || !status) {
        throw new Error('KOT ID and status are required');
      }
      
      const response = await updateKOTStatusAPI({ kotId, status });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update KOT status');
    }
  }
);
export const fetchActiveBags = createAsyncThunk(
  'pos/fetchActiveBags',
  async () => {
    const response = await fetchActiveBagsAPI();
    console.log('API Response in thunk:', response);
    return response; // Return the entire response
  }
);
export const fetchBag = createAsyncThunk('pos/fetchBag', fetchBagAPI);
export const updateBag = createAsyncThunk('pos/updateBag', updateBagAPI);
export const closeBag = createAsyncThunk(
  'pos/closeBag',
  async (bagId) => {
    const response = await axios.post(
      `${posUrl}/user-bag/${bagId}/close`,
      {},
      {
        headers: { "x-auth-token": getToken() },
      }
    );
    return response.data;
  }
);

export const getBagItems = createAsyncThunk(
  'pos/getBagItems',
  async (bagId) => {
    const response = await getBagItemsAPI(bagId);
    return response.data;
  }
);

export const addItemsToBag = createAsyncThunk(
  'pos/addItemsToBag',
  async ({ bagId, items }) => {
    const response = await addItemsToBagAPI(bagId, items);
    return response.data;
  }
);

export const createKOTFromBagItems = createAsyncThunk(
  'pos/createKOTFromBagItems',
  async ({ bagId, items }) => {
    const response = await createKOTFromBagItemsAPI(bagId, items);
    return response.data;
  }
);

export const fetchBagKOTs = createAsyncThunk(
  'pos/fetchBagKOTs',
  async (bagId) => {
    const response = await axios.get(`${posUrl}/user-bag/${bagId}/kots`, {
      headers: { "x-auth-token": getToken() },
    });
    return response.data;
  }
);

const posSlice = createSlice({
  name: 'pos',
  initialState: {
    menuItems: [],
    inventoryItems: [],
    cart: [],
    currentBag: null,
    openBags: [],
    orders: [],
    kots: [],
    loading: false,
    error: null,
    activeBags: [],
    loading: false,
    error: null,
    currentBagKOTs: [],
    bagKOTsLoading: false,
    bagKOTsError: null,
  },
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.cart.find(item => item._id === action.payload._id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.push({ ...action.payload, quantity: 1 });
      }
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(item => item._id !== action.payload);
    },
    updateCartItemQuantity: (state, action) => {
      const { itemId, quantity } = action.payload;
      const item = state.cart.find(item => item._id === itemId);
      if (item) {
        item.quantity = quantity;
        if (item.quantity <= 0) {
          state.cart = state.cart.filter(item => item._id !== itemId);
        }
      }
    },
    clearCart: (state) => {
      state.cart = [];
    },
    setCurrentBag: (state, action) => {
      state.currentBag = action.payload;
    },
    clearSelectedBag: (state) => {
      state.selectedBag = null;
      state.bagItems = [];
    },
    clearBagKOTs: (state) => {
      state.currentBagKOTs = [];
      state.bagKOTsError = null;
  },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchPOSData.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchPOSData.fulfilled, (state, action) => {
      state.loading = false;
      state.menuItems = action.payload.menuItems;
      state.inventoryItems = action.payload.inventoryItems;
      state.orders = action.payload.orders;
      state.openBags = action.payload.openBags;
      state.kots = Array.isArray(action.payload.kots) ? action.payload.kots : [];

    })
    .addCase(fetchPOSData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
      .addCase(createUserBag.fulfilled, (state, action) => {
        state.currentBag = action.payload;
        if (!state.openBags) state.openBags = [];
        state.openBags.push(action.payload);
      })
      .addCase(generateBill.fulfilled, (state, action) => {
        state.openBags = state.openBags.filter(bag => bag.id !== action.payload.bagId);
        if (state.currentBag && state.currentBag.id === action.payload.bagId) {
          state.currentBag = null;
        }
      })
      .addCase(fetchPOSMenuItems.fulfilled, (state, action) => {
        state.menuItems = action.payload;
      })
      .addCase(createKOT.fulfilled, (state, action) => {
        if (Array.isArray(state.kots)) {
          state.kots.push(action.payload);
        } else {
          state.kots = [action.payload];
        }
      })
      .addCase(fetchKOTs.fulfilled, (state, action) => {
        console.log('fetchKOTs.fulfilled payload:', action.payload); // Debug log
        state.kots = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(updateKOTStatus.fulfilled, (state, action) => {
        const updatedKot = action.payload;
        const index = state.kots.findIndex(kot => kot.id === updatedKot.id);
        if (index !== -1) {
          state.kots[index] = updatedKot;
        }
      })
      .addCase(fetchActiveBags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActiveBags.fulfilled, (state, action) => {
        console.log('Payload in reducer:', action.payload);
        state.loading = false;
        // Properly extract data from the response
        state.activeBags = action.payload?.data || [];
        state.error = null;
      })
      .addCase(fetchActiveBags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.activeBags = [];
      })
      .addCase(fetchBag.fulfilled, (state, action) => {
        state.currentBag = action.payload;
      })
      .addCase(updateBag.fulfilled, (state, action) => {
        const index = state.activeBags.findIndex(bag => bag.id === action.payload.id);
        if (index !== -1) {
          state.activeBags[index] = action.payload;
        }
        state.currentBag = action.payload;
      })
      .addCase(closeBag.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(closeBag.fulfilled, (state, action) => {
        state.activeBags = state.activeBags.filter(
          bag => bag.id !== action.payload.data.bagId
      )})
      .addCase(closeBag.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBagItems.fulfilled, (state, action) => {
        state.selectedBag = action.payload;
        state.bagItems = action.payload.items || [];
      })
      .addCase(addItemsToBag.fulfilled, (state, action) => {
        state.selectedBag = action.payload;
        state.bagItems = action.payload.items || [];
      })
      .addCase(createKOTFromBagItems.fulfilled, (state, action) => {
        // Handle KOT creation success if needed
      })
      .addCase(fetchBagKOTs.pending, (state) => {
        state.bagKOTsLoading = true;
        state.bagKOTsError = null;
      })
      .addCase(fetchBagKOTs.fulfilled, (state, action) => {
        state.bagKOTsLoading = false;
        state.currentBagKOTs = action.payload.data || [];
        state.bagKOTsError = null;
      })
      .addCase(fetchBagKOTs.rejected, (state, action) => {
        state.bagKOTsLoading = false;
        state.currentBagKOTs = [];
        state.bagKOTsError = action.error.message;
      });
  },
});

export const {
  addToCart,
  removeFromCart,
  updateCartItemQuantity,
  clearCart,
  setCurrentBag,
} = posSlice.actions;

export const selectMenuItems = (state) => state.pos.menuItems;
export const selectInventoryItems = (state) => state.pos.inventoryItems;
export const selectOrders = (state) => state.pos.orders;
export const selectCart = (state) => state.pos.cart;
export const selectOpenBags = (state) => state.pos.openBags;
export const selectKOTs = (state) => {
  console.log('selectKOTs called, full state:', state); // Log the full state
  console.log('selectKOTs called, pos state:', state.pos); // Log the pos state
  console.log('selectKOTs called, kots:', state.pos.kots); // Log the kots specifically
  return state.pos.kots || [];
};
export const selectPOSLoading = (state) => state.pos.loading;
export const selectPOSError = (state) => state.pos.error;
export const selectActiveBags = (state) => {
  console.log('State in selector:', state);
  return state.pos.activeBags || [];
};
export const selectActiveBagsLoading = (state) => state.pos.loading;
export const selectActiveBagsError = (state) => state.pos.error;
export const selectCurrentBag = state => state.pos.currentBag;
export const selectSelectedBag = (state) => state.pos.selectedBag;
export const selectBagItems = (state) => state.pos.bagItems;

export const selectCurrentBagKOTs = (state) => state.pos.currentBagKOTs || [];
export const selectBagKOTsLoading = (state) => state.pos.bagKOTsLoading;
export const selectBagKOTsError = (state) => state.pos.bagKOTsError;
export default posSlice.reducer;