import React, { useEffect, useRef, useState } from 'react'
import Switch from './Switch'
import { addItemToMenu, updateItem } from '../api/cafe'
import { useDispatch } from 'react-redux'
import { addItemInState, updateItemInState } from '../redux/slices/cafe'
import Universalinput from "./Universalnput"
import { toggleNoteSaved } from "../redux/slices/toggleslice";
const AddItem = ({ closeModal, oldItem = {} }) => {
  const dispatch = useDispatch()
  const scrollContainerRef = useRef(null);

  const [category, setCategory] = useState(oldItem.category || '')
  const [subCategory, setSubCategory] = useState(oldItem.subcategory || '')
  const [name, setName] = useState(oldItem.name || '')
  const [price, setPrice] = useState(oldItem.price !== undefined ? oldItem.price : '')
  const [description, setDescription] = useState(oldItem.description || '')
  const [recommended, setRecommended] = useState(oldItem.recommended)
  const [inStock, setInStock] = useState(oldItem.instock || false)
  const [variant, setVariant] = useState(oldItem.variant || '')
  const [servingInfo, setServingInfo] = useState(oldItem.servinginfo || '')
  const [portionSize, setPortionSize] = useState(oldItem.portionsize || '')
  const [veg, setVeg] = useState(
    oldItem.veg !== undefined ? oldItem.veg : true
  );
  const [image, setImage] = useState(oldItem.image || '')
  const [loading, setLoading] = useState(false)

  function preCheck() {
    if (category === "" || name === "") {
      return false;
    }
    return true;
  }
  const removeNullUndefinedWithReduce = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        if (key === 'subCategory' && value === '') {
          // Don't include empty subCategory
        } else {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  };
  const onUpload = (e) => {
    setImage(e.target.files[0]);
    const file = e.target.files;
    if (file.length > 0) {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file[0]);
    }
  }

  async function formSumbit(e) {
    e.preventDefault();
    setLoading(true);
    if (preCheck()) {
      const item = {
        category,
        subCategory,
        name,
        price: price === '' ? null : price,
        description,
        recommended,
        inStock,
        variant,
        servingInfo,
        portionSize,
        image,
        veg
      };
      console.log("New item that is being added: ",item);
      const itemPayload = removeNullUndefinedWithReduce(item);
      let formData = new FormData();
      for (let key in itemPayload) {
        formData.append(key, itemPayload[key]);
      }
      let resp;
      if (oldItem._id) {
        resp = await updateItem(formData, oldItem._id);
        // No need to dispatch an update action, as the state will be refreshed on the next fetch
      } else {
        resp = await addItemToMenu(formData);
        dispatch(addItemInState(itemPayload));
      }
      if (resp.success) {
        dispatch(toggleNoteSaved());
        closeModal();
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    let startY;

    const touchStart = (e) => {
      startY = e.touches[0].pageY;
    };

    const touchMove = (e) => {
      if (startY === undefined) return;

      const currentY = e.touches[0].pageY;
      const diffY = startY - currentY;
      
      if (
        (scrollContainer.scrollTop === 0 && diffY < 0) ||
        (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight && diffY > 0)
      ) {
        e.preventDefault();
      }

      scrollContainer.scrollTop += diffY;
      startY = currentY;
    };

    scrollContainer.addEventListener('touchstart', touchStart, { passive: false });
    scrollContainer.addEventListener('touchmove', touchMove, { passive: false });

    return () => {
      scrollContainer.removeEventListener('touchstart', touchStart);
      scrollContainer.removeEventListener('touchmove', touchMove);
    };
  }, []);
  
  return (
    <div 
    ref={scrollContainerRef}
    className="w-full h-full overflow-y-scroll overscroll-y-contain" 
    style={{ 
      WebkitOverflowScrolling: 'touch',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }}
  >
    <form onSubmit={formSumbit} className='flex overflow-auto flex-col justify-center relative items-center'>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Name*
        </label>
          <Universalinput type='text'
          placeholder='Item Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className='focus:outline-none focus:ring-2 focus:ring-[#242424] '
          style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
         />
        </div>
        <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
          <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
            Price
          </label>
          <Universalinput type='number'
            placeholder='Item Price in Rupees (optional)'
            value={price}
            className='focus:outline-none focus:ring-2 focus:ring-[#242424] '
            onChange={(e) => setPrice(e.target.value)}
            style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
          />
        </div>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Description
        </label>
        <textarea
            placeholder='Enter the Description of Item'
            rows={5}
            value={description}
            className='focus:outline-none focus:ring-2 focus:ring-[#242424]'
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
          /></div>
      <div style={{ margin: "0.5rem 0" }} className="flex justify-center w-full">
        <div onClick={() => setVeg(true)} className={`flex p-1 rounded w-20 justify-center mr-1 ${(veg || veg === '') ? "bg-teal-500 shadow-2xl border" : "border-2"}`}>
          <label className={`text-sm font-medium text-[#242424] ${(veg || veg === '') ? "text-white" : "text-gray-500"}`}>Veg</label>
        </div>
        <div onClick={() => setVeg(false)} className={`flex p-1 rounded w-20 justify-center mr-1 ${(!veg && veg !== '') ? "bg-red-500 shadow-2xl border" : "border-2"}`}>
          <label className={`text-sm text-[#242424] font-medium ${(!veg && veg !== '') ? "text-white" : "text-gray-500"}`}>Non-Veg</label>
        </div>

        {/* <label className='text-center' style={{ fo? */}
        {/* <Switch toggle={veg} clickAction={} /> */}
      </div>
      <div style={{ margin: "0.5rem 0" }} className="flex justify-between w-full border-none">
        <div>
          <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
            Recommended
          </label>
          <Switch toggle={recommended} clickAction={() => setRecommended(!recommended)} />
        </div>
        <div>
          <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
            In Stock
          </label>
          <Switch toggle={inStock} clickAction={() => setInStock(!inStock)} /></div>
      </div>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Category*
        </label>
        <Universalinput   type='text'
          placeholder=' Enter Category'
          required
          className='placeholder:font-bold placeholder-[#c7c6c7]'
          value={category}
          onChange={(e) => setCategory(e.target.value)}
            />
        </div>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Sub Category
        </label>
        <Universalinput  type='text'
          placeholder='Enter  Sub Category'
          value={subCategory}
          className='focus:outline-none focus:ring-2 focus:ring-[#242424] '
          onChange={(e) => setSubCategory(e.target.value)}
          style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
        />
       </div>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Variant
        </label>
        <Universalinput type='text'
          placeholder='Enter Quantity ex. small,half'
          value={variant}
          className='focus:outline-none focus:ring-2 focus:ring-[#242424] '
          onChange={(e) => setVariant(e.target.value)}
          style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
        />
        </div>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Serving Info
        </label>
        <Universalinput type='text'
          placeholder='Serving Info'
          value={servingInfo}
          className='focus:outline-none focus:ring-2 focus:ring-[#242424] '
          onChange={(e) => setServingInfo(e.target.value)}
          style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
        />
        </div>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Portion Size
        </label>
        <Universalinput type='text'
          placeholder='1 Person, 2 Person, 3 Person'
          value={portionSize}
          className='focus:outline-none focus:ring-2 focus:ring-[#242424] '
          onChange={(e) => setPortionSize(e.target.value)}
          style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
        />
        </div>
      <div style={{ margin: "0.5rem 0" }} className="flex flex-col w-full">
        <label style={{ fontSize: "12px", marginBottom: "5px", color: "#242424" }} >
          Image
        </label>
        <Universalinput type='file'
          placeholder='Image'
          className='focus:outline-none focus:ring-2 focus:ring-[#242424]  '
          onChange={onUpload}
          style={{ width: '100%', borderRadius: '5px', padding: '5px 10px', border: '1px solid #aaa', fontSize: '16px' }}
      />
       </div>

      <div className="w-full flex py-2 relative bottom-0 bg-white left-0 z-20 items-center justify-center">
        <button
          disabled={loading}
          className="bg-[#FD7347]  text-white px-8 py-2 block rounded-md shadow-md"
        >
          {oldItem.name ? "Update Item" : "Add Item"}
        </button>
      </div>
    </form>
    </div>

  )
}

export default AddItem