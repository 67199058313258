import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCafeFromAlert } from "../../api/user";
import StarRating from "../../components/StarRating";
import { PhoneOutlined } from "@ant-design/icons";
import Carousel from "react-multi-carousel";

const CafeFromAlert = () => {
  const { cafe } = useParams();
  const [cafeData, setCafeDetails] = useState({});
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    getCafeFromAlert(cafe).then((res) => {
      setCafeDetails(res.data.cafeDetails);
      setAlerts(res.data.alerts);
    });
  }, [cafe]);

  return (
    <div className="w-full h-screen flex flex-col items-center">
      {cafeData?.image && (
        <div className="h-80 w-full fixed top-0 flex flex-col justify-center items-center bg-black">
          <img
            src={`data:image/png;base64,${cafeData?.image}`}
            alt="banner"
            className="w-full h-80 object-cover fixed top-0 z-0"
          />
        </div>
      )}
      {cafeData.image && <div className="h-80"></div>}
      <h1 className="text-3xl font-bold mt-8">{cafeData.name}</h1>
      <StarRating
        rating={cafeData.rating}
        count={cafeData.ratingCount}
        size="big"
      />
      <a
        href={`tel:${cafeData.phone}`}
        className="text-md mt-4 flex items-center text-sky-500 font-bold"
      >
        <PhoneOutlined className="rotate-90 mr-2" />
        {cafeData.phone}
      </a>
      <p className="text-sm mt-4 flex items-center">{cafeData.address},</p>
      <p className="text-sm flex items-center">
        {cafeData.city}, {cafeData.state}
      </p>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 6000,
              min: 1,
            },
            items: 1,
            partialVisibilityGutter: 40,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {alerts.map((alert) => (
          <div className="h-60 w-11/12 flex flex-col items-center mx-auto justify-center">
            <div className="border rounded py-2 my-2 w-11/12">
              <p className="text-lg font-semibold text-center pb-2 border-b my-2">
                {alert.title}
              </p>
              <p className="px-6 py-2 text-center">
                {alert.body.substring(0, 100)}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CafeFromAlert;
