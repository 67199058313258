import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  cafe: {},
  token: "",
};

const authSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.token = action.payload;
    },
    logoutUser: (state) => {
      state = initialState;
    },
    updateUser: (state, action) => {
      state.user = action.payload.user;
      state.cafe = action.payload.cafe;
    },
  },
});

export const { setUser, updateUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;
