import React from "react";
import { FaUpload } from "react-icons/fa";

const UploadButton = ({ text, onFileChange, accept = "*", multiple = false }) => {
  return (
    <label className="relative flex items-center justify-center w-full max-w-xl px-6 py-4 mt-4 text-lg font-semibold text-black bg-white border border-black rounded-[14px] shadow-[2px_3px_0px_0px_rgba(0,0,0,1)] cursor-pointer">
      <input
        type="file"
        onChange={onFileChange}
        accept={accept}
        multiple={multiple}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      <FaUpload className="w-5 h-5 mr-2" />
      {text}
    </label>
  );
};

export default UploadButton;