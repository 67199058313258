import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { registerUser } from "../../api/auth";
import Pin from "../../components/Pin";
import { setUser } from "../../redux/slices/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { Helmet } from "react-helmet";
import Universalinput from "../../components/Universalnput"
import UniversalInput from "../../components/Universalnput";

export default function Signup() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const token = localStorage.getItem("OG_token");

  useEffect(() => {
    if (token) {
      navigate("/cafe-admin/register");
    }
  }, []);

  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showForm, setShowForm] = useState(true);

  async function formSumbit(e) {
    e.preventDefault();
    const resp = await registerUser({ phone, password, name, pin });
    if (resp.success) {
      dispatch(setUser({ phone, name }));
      navigate("/cafe-admin/login?phone=" + phone);
    }
    toast.error(resp.message);
  }

  function submitDetails(e) {
    e.preventDefault();
    if (name.length < 4) {
      toast.error("Name should be atleast 3 characters long");
      return;
    }
    if (password.length < 8) {
      toast.error("Password should be atleast 8 characters long");
      return;
    }
    if (password !== password2) {
      toast.error("Passwords do not match");
      return;
    }
    if (phone.length !== 10 || isNaN(JSON.parse(phone))) {
      toast.error("Please enter a valid phone number");
      return;
    }
    setShowForm(false);
  }

  if (!showForm) {
    return (
      <form
        onSubmit={formSumbit}
        className="w-full h-screen flex flex-col justify-center items-center"
      >
        <Helmet title="Set PIN" />
        <Toaster />
        <img src="../Cafe-admin.png" alt="Hero" />
        <h1 className="text-xl mb-5">Set a PIN for easy login</h1>
        <Pin onCompletion={() => {}} onChange={(val) => setPin(pin + val)} />
        <button
          type="submit"
          className="bg-[#FD7347] text-white px-8 py-2 block mt-5 rounded-md shadow-md"
        >
          Register
        </button>
      </form>
    );
  }
  return (
    <form
      onSubmit={submitDetails}
      className="w-full h-screen flex flex-col justify-center items-center"
    >
      <Helmet
        title="Signup | Cafe Admin"
        meta={[
          {
            name: "description",
            content: "Signup for your cafe admin account",
          },
        ]}
      />
      <Toaster />
      <img src="../Cafe-admin.png" alt="Hero" />
      <h1 className="text-xl">Welcome to Orderji</h1>
      <p className="text-blue-400 mt-2 mb-5">
        Here you can signup yourself for Orderji
      </p>
      <UniversalInput type="text"
        placeholder="Enter your name"
        className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
        autoComplete="off"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
     <UniversalInput type="text"
        placeholder="Enter your phone"
        className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
        value={phone}
        autoComplete="mobile tel"
        onChange={(e) => setPhone(e.target.value)}
        required
      />
        <UniversalInput type="password"
        placeholder="Enter password"
        className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
        autoComplete="off"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <UniversalInput
        type="password"
        placeholder="Confirm password"
        className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
        autoComplete="off"
        value={password2}
        onChange={(e) => setPassword2(e.target.value)}
        required
      />

      <button
        type="submit"
        className="bg-[#FD7347] text-white px-8 py-2 block mt-5 rounded-md shadow-md"
      >
        Continue
      </button>
      <Link className="text-blue-500 text-md my-5" to={`/cafe-admin/login`}>
        Already registered?
      </Link>
    </form>
  );
}
