
import React, { useState, useEffect } from 'react';
import { fetchAlert1 } from '../api/cafe';
import { useNavigate } from "react-router-dom";
const Ordercard = () => {
  const [alerts, setAlerts] = useState([]);
  const [cafe, setcafe] = useState([]);
  const navigate = useNavigate();
  const getAllAlerts = async () => {
    try {
      const response = await fetchAlert1();
      if (response && response.data) {
        const { alerts ,cafe} = response.data;
        if (Array.isArray(alerts)) {
          setAlerts(alerts.map(alert => ({
            title: alert.title,
            body: alert.body,
            cafe:alert.cafe,
            url: alert.url|| `https://www.orderg.in/cafe/${alert.cafe}`,
            expiresIn: alert.expiresIn,
            
          })));
        }
        if (Array.isArray(cafe)) {
          setcafe(cafe.map(cafe => ({
            name: cafe.name,
            id: cafe._id,
            
          })));
        }
      }
    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
  };

  useEffect(() => {
    getAllAlerts();
  }, []);

  return (
    <div className="p-4">
      <h3 className="text-2xl font-bold mt-5">Offers</h3>
      <div className="flex space-x-4 mt-4 item-center justify-between">
        <button className="bg-gray-200 p-2 rounded-[20px]">Cafes Near me</button>
        <button className="bg-gray-200 p-2 rounded-[30px]">Latest Offers</button>
      </div>
      <ul className="space-y-4 mt-4">
        {alerts.map((alert, index) => (
          <li
            key={index}
            className="border p-4 rounded-[10%] shadow-md bg-[#fef4f3]"
          >
            <div className="flex justify-between items-center">
              <h4 className="font-bold text-lg">{alert.title}</h4>
             {cafe.map((cafe,index)=>(
                <li key={index}>
                 { alert.cafe==cafe.id && <div>{cafe.name}
                 </div>} 
                </li>
             ))} 
              { (
                <a
                  onClick={()=>  navigate("/cafe/profile")}
                  target="_blank"
                  rel="noreferrer"
                  className="text-orange-500 hover:underline"
                >
                  View cafe details
                </a>
              )}
            </div>
            <p className="text-gray-700">{alert.body}</p>
            <p className="text-gray-500 mt-2">Expires in {alert.expiresIn} days</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Ordercard;
