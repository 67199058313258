import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { fetchCafeStructure, updateStructure } from '../../api/superadmin';

const MenuReorderingComponent = () => {
  const [cafes, setCafes] = useState([]);
  const [selectedCafe, setSelectedCafe] = useState('');
  const [structure, setStructure] = useState({});

  useEffect(() => {
    fetchCafes();
  }, []);

  const fetchCafes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/cafes`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('superadminToken')}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        setCafes(data.data);
      } else {
        toast.error('Failed to fetch cafes');
      }
    } catch (error) {
      console.error('Error fetching cafes:', error);
      toast.error('An error occurred while fetching cafes');
    }
  };

  const handleCafeChange = async (e) => {
    const cafeId = e.target.value;
    setSelectedCafe(cafeId);
    if (cafeId) {
      try {
        const data = await fetchCafeStructure(cafeId);
        setStructure(data.data);
      } catch (error) {
        toast.error('Failed to fetch cafe structure');
      }
    } else {
      setStructure({});
    }
  };

  const onDragStart = (e, type, id, category, subcategory) => {
    const data = { type, id, category, subcategory };
    console.log('Drag started:', data);
    e.dataTransfer.setData('application/json', JSON.stringify(data));
    e.dataTransfer.effectAllowed = 'move';
    e.stopPropagation(); // Prevent event from bubbling up
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (e, targetType, targetId, targetCategory, targetSubcategory) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent event from bubbling up
    
    let droppedItem;
    try {
      const dataString = e.dataTransfer.getData('application/json');
      console.log('Raw dropped data:', dataString);
      droppedItem = JSON.parse(dataString);
    } catch (error) {
      console.error('Error parsing dropped item data:', error);
      return;
    }
    
    console.log('Dropped item:', droppedItem);
    console.log('Target:', { targetType, targetId, targetCategory, targetSubcategory });

    const newStructure = JSON.parse(JSON.stringify(structure)); // Deep clone

    if (droppedItem.type === targetType) {
      switch (droppedItem.type) {
        case 'category':
          reorderCategories(newStructure, droppedItem.id, targetId);
          break;
        case 'subcategory':
          reorderSubcategories(newStructure, droppedItem.id, targetId, targetCategory);
          break;
        case 'item':
          reorderItems(newStructure, droppedItem.id, targetId, targetCategory, targetSubcategory);
          break;
        default:
          console.log('Unknown item type:', droppedItem.type);
      }
    } else {
      console.log('Mismatched drag and drop types:', droppedItem.type, targetType);
    }
  };

  const reorderCategories = (newStructure, fromId, toId) => {
    const categories = Object.keys(newStructure);
    const fromIndex = categories.indexOf(fromId);
    const toIndex = categories.indexOf(toId);
    
    if (fromIndex !== -1 && toIndex !== -1) {
      categories.splice(toIndex, 0, categories.splice(fromIndex, 1)[0]);
      const reorderedStructure = {};
      categories.forEach((category, index) => {
        reorderedStructure[category] = { ...newStructure[category], order: index + 1 };
      });
      setStructure(reorderedStructure);
    }
  };

  const reorderSubcategories = (newStructure, fromId, toId, category) => {
    const subcategories = Object.keys(newStructure[category].subcategories);
    const fromIndex = subcategories.indexOf(fromId);
    const toIndex = subcategories.indexOf(toId);
    
    if (fromIndex !== -1 && toIndex !== -1) {
      subcategories.splice(toIndex, 0, subcategories.splice(fromIndex, 1)[0]);
      const reorderedSubcategories = {};
      subcategories.forEach((subcategory, index) => {
        reorderedSubcategories[subcategory] = {
          ...newStructure[category].subcategories[subcategory],
          order: index + 1
        };
      });
      newStructure[category].subcategories = reorderedSubcategories;
      setStructure(newStructure);
    }
  };

  const reorderItems = (newStructure, fromId, toId, category, subcategory) => {
    const items = newStructure[category].subcategories[subcategory].items;
    const fromIndex = items.findIndex(item => item._id === fromId);
    const toIndex = items.findIndex(item => item._id === toId);
    
    if (fromIndex !== -1 && toIndex !== -1) {
      const [movedItem] = items.splice(fromIndex, 1);
      items.splice(toIndex, 0, movedItem);
      items.forEach((item, index) => {
        item.item_order = index + 1;
      });
      setStructure(newStructure);
    }
  };
  const saveOrder = async () => {
    try {
      await updateStructure(selectedCafe, structure);
      toast.success('Menu structure updated successfully');
    } catch (error) {
      toast.error('Failed to update menu structure');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Menu Reordering</h2>
      <select
        value={selectedCafe}
        onChange={handleCafeChange}
        className="mb-4 p-2 border rounded"
      >
        <option value="">Select a cafe</option>
        {cafes.map(cafe => (
          <option key={cafe._id} value={cafe._id}>{cafe.name}</option>
        ))}
      </select>

      <div>
        {Object.entries(structure)
          .sort(([,a],[,b]) => a.order - b.order)
          .map(([category, categoryData]) => (
            <div
              key={category}
              draggable
              onDragStart={(e) => onDragStart(e, 'category', category)}
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, 'category', category)}
              className="mb-6 p-4 bg-gray-100 rounded cursor-move"
            >
              <h3 className="text-xl font-semibold mb-2">{category}</h3>
              {Object.entries(categoryData.subcategories)
                .sort(([,a],[,b]) => a.order - b.order)
                .map(([subcategory, subcategoryData]) => (
                  <div
                    key={`${category}-${subcategory}`}
                    draggable
                    onDragStart={(e) => onDragStart(e, 'subcategory', subcategory, category)}
                    onDragOver={onDragOver}
                    onDrop={(e) => onDrop(e, 'subcategory', subcategory, category)}
                    className="ml-4 mb-4 p-2 bg-gray-200 rounded cursor-move"
                  >
                    <h4 className="text-lg font-medium mb-2">{subcategory}</h4>
                    <ul className="space-y-2">
                      {subcategoryData.items
                        .sort((a, b) => a.item_order - b.item_order)
                        .map((item) => (
                          <li
                            key={item._id}
                            draggable
                            onDragStart={(e) => onDragStart(e, 'item', item._id, category, subcategory)}
                            onDragOver={onDragOver}
                            onDrop={(e) => onDrop(e, 'item', item._id, category, subcategory)}
                            className="bg-white shadow rounded p-2 cursor-move"
                          >
                            {item.name} - ₹{item.price}
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
            </div>
          ))}
      </div>

      {Object.keys(structure).length > 0 && (
        <button
          onClick={saveOrder}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Menu Structure
        </button>
      )}
    </div>
  );
};

export default MenuReorderingComponent;