import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('superadminToken');
  
  console.log('ProtectedRoute: isAuthenticated =', isAuthenticated);

  if (!isAuthenticated) {
    console.log('ProtectedRoute: Redirecting to login');
    return <Navigate to="/superadmin/login" />;
  }

  console.log('ProtectedRoute: Rendering protected component');
  return <Component {...rest} />;
};

export default ProtectedRoute;