import React, { useState, useEffect } from "react";
import { getCafeReviewStats } from "../../api/cafe";
import { toast } from "react-toastify";
import BottomMenu from "../../components/BottomMenu";
import RatingCard from "../../components/frontpage/review";
import LoadingOverlay from "../../components/LoadingOverlay";
import Header from "../../components/Header";
const ReviewStatsDashboard = () => {
  const [showWhatPeopleAreSaying, setShowWhatPeopleAreSaying] = useState(true);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReviewStats();
  }, []);

  const fetchReviewStats = async () => {
    try {
      const response = await getCafeReviewStats();
      console.log("API Response:", response);
      if (response.success) {
        console.log("Setting stats:", response.data);
        setStats(response.data);
      } else {
        toast.error("Failed to fetch review stats");
      }
    } catch (error) {
      console.error("Error fetching review stats:", error);
      toast.error("An error occurred while fetching review stats");
    } finally {
      setLoading(false);
    }
  };

  console.log("Current stats state:", stats);

  if (loading) {
    return <LoadingOverlay show={loading} />;
  }

  if (!stats || !stats.averageRating) {
    console.log("No stats available");
    return (
      <div>
        <Header />
        <div className="text-center py-4 font-extrabold">No review stats available</div>
        <BottomMenu />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="container mx-auto px-4 py-8">

        <h1 className="text-2xl font-bold mb-6">Review Statistics</h1>

        <div
          className="bg-[#FFF2AF] p-4 rounded-lg shadow-md max-w-sm mx-auto border border-[#000000]"
          style={{ boxShadow: "3px 3px 0px 0px #000000" }}
        >
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-4xl font-bold">{stats.totalReviews}</h2>
              <p className="text-gray-600 text-sm">Total Feedback</p>
            </div>
            <div>
              <h2 className="text-4xl font-bold">{stats.averageRating}</h2>
              <p className="text-gray-600 text-sm">Avg. Rating</p>
            </div>
          </div>
          <hr className="border-[#FFD700] mb-4" />
          <div className=" mb-2">
            <p className="text-xs ">Detailed</p>
          </div>
          <div className="grid grid-cols-3 gap-4 text-center">
            <div>
              <div className="text-sm font-bold flex gap-1">
                {" "}
                <div className="">{stats.ratingCounts.positive}</div>{" "}
                <spam className="font-medium text-sm">Positive</spam>
              </div>
              <div className="flex gap-1">
                <div className="text-2xl">😀</div>
                <div className="text-xl font-bold">
                  {stats.ratingDistribution.positive}%
                </div>
              </div>
            </div>
            <div>
              <div className="text-sm font-bold flex gap-1">
                <div className="">{stats.ratingCounts.negative}</div>{" "}
                <spam className="font-medium">Negative</spam>
              </div>

              <div className="flex gap-1">
                {" "}
                <div className="text-2xl">😞</div>
                <div className="text-xl font-bold">
                  {stats.ratingDistribution.negative}%
                </div>
              </div>
            </div>
            <div>
              <div className="text-sm font-bold flex gap-1">
                <div className="">{stats.ratingCounts.neutral}</div>{" "}
                <spam className="font-medium">Neutral</spam>
              </div>
              <div className="flex gap-1">
                {" "}
                <div className="text-2xl">😐</div>
                <div className="text-xl font-bold">
                  {stats.ratingDistribution.neutral}%
                </div>
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="mb-8 mt-6">
          <h2 className="text-xl font-semibold mb-4">Review Sources</h2>
          <div className="bg-white p-4 rounded-lg shadow-md">
            {stats.sourcesStats.map((source, index) => (
              <div key={index} className="mb-2 flex justify-between items-center">
                <span className="font-medium">{source.source}</span>
                <span>
                  {source.count}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-16">
          <div className="flex items-center justify-between">
            <div
              className={`text-xl font-semibold mt-10 mb-4 cursor-pointer ${showWhatPeopleAreSaying && "underline"
                }`}
              onClick={() => setShowWhatPeopleAreSaying(true)}
            >
              What People Are Saying
            </div>
            <div
              className={`text-xl font-semibold mt-10 mb-4 cursor-pointer ${!showWhatPeopleAreSaying && "underline"
                }`}
              onClick={() => setShowWhatPeopleAreSaying(false)}
            >
              Recent Reviews
            </div>
          </div>
          {showWhatPeopleAreSaying ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 ">
              {stats.questionStats &&
                stats.questionStats.map((question) => (
                  <div
                    key={question.question_id}
                    className="rounded-lg  p-2"
                  >
                    <RatingCard
                      rating={question.average_rating}
                      count={question.number_of_ratings}
                      question={question.question_text}
                    />
                  </div>
                ))}
            </div>
          ) : (
            <div className="space-y-4">
              {stats.reviewTexts &&
                stats.reviewTexts.map((review, index) => (
                  <div
                    key={index}
                    className="bg-white p-4 rounded-lg shadow"
                  >
                    <p className="text-gray-700">{review.review_text}</p>
                    <p className="text-sm text-gray-500 mt-2">
                      {new Date(review.created_at).toLocaleDateString()}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
        <BottomMenu page="reviews" />
      </div>
    </div>

  );
};

export default ReviewStatsDashboard;
