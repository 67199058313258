import React from 'react'
import { ToastContainer } from 'react-toastify';

const Toaster = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      limit={1}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      theme="light"
      draggableDirection='x'
    />
  )
}

export default Toaster