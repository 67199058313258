import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: [],
  cafe: {},
  order: {},
  cart: [],
};

const userSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setCafe: (state, action) => {
      state.cafe = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    addToCart: (state, action) => {
      state.cart.push(action.payload);
      sessionStorage.setItem("OG_bag", JSON.stringify(state.cart));
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.items.find(item => item.id === id);
      if (item) {
        item.quantity = quantity;
        state.total = state.items.reduce((total, item) => total + item.price * item.quantity, 0);
      }
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload);
      sessionStorage.setItem("OG_bag", JSON.stringify(state.cart));
    },
    updateCart: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      sessionStorage.setItem("OG_bag", JSON.stringify(state.cart));
    },
    addOrder: (state, action) => {
      state.order = action.payload;
    },
    emptyCart: (state) => {
      state.cart = [];
      sessionStorage.setItem("OG_bag", JSON.stringify(state.cart));
    },
  },
});

export const {
  setCafe,
  setMenu,
  updateQuantity,
  addToCart,
  removeFromCart,
  updateCart,
  addOrder,
  emptyCart,
} = userSlice.actions;
export default userSlice.reducer;
