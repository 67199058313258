import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ArrowUpOutlined } from "@ant-design/icons";

const AnalyticsDashboard = ({ analyticsData, newUsersReached, googleReviewRedirects }) => {
  if (!analyticsData || analyticsData.length === 0) {
    return <div>No analytics data available.</div>;
  }

  const pageMappings = {
    '/cafe/menu': 'Menu Page',
    '/cafe/profile': 'Profile Page',
    '/cafe/review': 'Review Page',
  };

  // Process the data
  const processedData = {
    'Menu Page': { name: 'Menu Page', visits: 0, uniqueVisitors: new Set() },
    'Profile Page': { name: 'Profile Page', visits: 0, uniqueVisitors: new Set() },
    'Review Page': { name: 'Review Page', visits: 0, uniqueVisitors: new Set() },
    'QR Code Scanned': { name: 'QR Code Scanned', visits: 0, uniqueVisitors: new Set() },
  };

  analyticsData.forEach(item => {
    if (item && item.link) {
      const url = new URL(item.link);
      const path = url.pathname;
      let pageName;

      if (pageMappings[path]) {
        pageName = pageMappings[path];
      } else if (path.match(/^\/cafe\/[a-zA-Z0-9-]+$/)) {
        pageName = 'QR Code Scanned';
      } else {
        return; // Skip this item if it doesn't match any of our categories
      }

      processedData[pageName].visits += parseInt(item.total_visits) || 0;
      for (let i = 0; i < parseInt(item.unique_users); i++) {
        processedData[pageName].uniqueVisitors.add(`user_${i}`);
      }
    }
  });

  const chartData = Object.values(processedData).map(item => ({
    ...item,
    uniqueVisitors: item.uniqueVisitors.size,
  }));

  const totalVisits = chartData.reduce((sum, item) => sum + item.visits, 0);
  const totalUniqueVisitors = analyticsData.reduce((sum, item) => sum + parseInt(item.unique_users), 0);

  return (
    <>
      {/* New Users Reached Card */}
      <div className="w-full max-w-md bg-[#00F3AE] rounded-lg px-4 py-2 m-2 flex items-center gap-x-4 shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]">
        <div className="w-6 p-1 bg-white rounded-full flex items-center">
          <ArrowUpOutlined className="" />
        </div>
        <div className="text-black font-bold">
          You have reached {newUsersReached || 0} new users this week
        </div>
      </div>

      {/* Google Review Redirects Card */}
      <div className="w-full max-w-md bg-[#00F3AE] rounded-lg px-4 py-2 m-2 flex items-center gap-x-4 shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]">
        <div className="w-6 p-1 bg-white rounded-full flex items-center">
          <ArrowUpOutlined className="" />
        </div>
        <div className="text-black font-bold">
          {googleReviewRedirects || 0} users redirected to Google Review
        </div>
      </div>

      {/* Overall Statistics */}
      <div className="w-full bg-white rounded-lg shadow-md p-4 m-2">
        <h3 className="font-bold text-lg mb-2">Overall Statistics</h3>
        <div className="flex justify-between items-center">
          <div className="text-center">
            <p className="text-3xl font-bold">{totalVisits}</p>
            <p className="text-sm text-gray-600">Total Page Views</p>
          </div>
          <div className="text-center">
            <p className="text-3xl font-bold">{totalUniqueVisitors}</p>
            <p className="text-sm text-gray-600">Unique Visitors</p>
          </div>
        </div>
      </div>

      {/* Page Performance Chart */}
      <div className="w-full bg-white rounded-lg shadow-md p-4 m-2">
        <h3 className="font-bold text-lg mb-2">Page Performance</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData}>
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            <Bar yAxisId="left" dataKey="visits" name="Page Views" fill="#8884d8" />
            <Bar yAxisId="right" dataKey="uniqueVisitors" name="Unique Visitors" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Individual Page Analytics */}
      {chartData.map((event, index) => (
        <div
          key={index}
          className="rounded-lg shadow-md p-4 m-2 w-full bg-[#D2C7FF]"
        >
          <h3 className="font-bold text-lg mb-2 truncate">{event.name}</h3>
          <div className="flex justify-between items-center mb-4">
            <div>
              <p className="text-3xl font-bold">{event.visits}</p>
              <p className="text-sm text-gray-600">Total Page Views</p>
            </div>
            <div>
              <p className="text-2xl font-bold">{((event.uniqueVisitors / (event.visits || 1)) * 100).toFixed(1)}%</p>
              <p className="text-sm text-gray-600">Unique Visitor Rate</p>
            </div>
          </div>

          <div className="relative h-2 rounded-full bg-purple-300 mb-4">
            <div 
              className="absolute top-0 left-0 h-full bg-purple-500 rounded-full" 
              style={{width: `${(event.uniqueVisitors / (event.visits || 1)) * 100}%`}}
            ></div>
          </div>

          <div className="flex justify-between text-sm text-gray-600">
            <p>Unique: {event.uniqueVisitors}</p>
            <p>Repeat: {event.visits - event.uniqueVisitors}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default AnalyticsDashboard;