import React from "react";
import OfferManagementComponent from "../../components/cafe-admin/OfferManagementComponent";
import BottomMenu from "../../components/BottomMenu";
import CampaignForm from "../../components/cafe-admin/CampaignForm";
import Header from "../../components/Header";
import { BackwardOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const OfferCreationPage = () => {
  const [creationMode, setCreationMode] = React.useState(true);
  const navigate = useNavigate();
  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Header />
      {creationMode && (
        <div
          className="flex items-center gap-x-2 cursor-pointer mt-2"
          onClick={() => {
            navigate("/cafe-admin/all-offer-analytics");
          }}
        >
          <BackwardOutlined />
          Back
        </div>
      )}
      <h1 className="text-3xl font-bold mt-2 mb-6">Create New Offer</h1>
      
      <CampaignForm setCreationMode={setCreationMode}/>
      <BottomMenu page="campaigns" />
    </div>
  );
};

export default OfferCreationPage;
