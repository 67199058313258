import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout, Menu } from 'antd';
import { 
  MenuOutlined, 
  ShoppingCartOutlined, 
  FileTextOutlined, 
  InboxOutlined, 
  CoffeeOutlined,
  OrderedListOutlined
} from '@ant-design/icons';
import { fetchPOSData } from '../../api/pos';
import { setPOSData } from '../../redux/slices/posSlice';
import CreateOrderButton from '../../components/pos/CreateOrderButton';
import POSMenu from '../../components/pos/POSMenu';
import POSCart from '../../components/pos/POSCart';
import OrdersWindow from '../../components/pos/OrdersWindow';
import POSInventory from '../../components/pos/POSInventory';
import POSKitchenDisplay from '../../components/pos/POSKitchenDisplay';
import CurrentBagDisplay from '../../components/pos/CurrentBagDisplay';
import ActiveOrders from '../../components/pos/ActiveOrders';

const { Header, Sider, Content } = Layout;

const POSPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState('createOrder');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadPOSData = async () => {
      try {
        setLoading(true);
        await dispatch(fetchPOSData()).unwrap();
        setLoading(false);
      } catch (err) {
        console.error("Failed to load POS data:", err);
        setLoading(false);
      }
    };

    loadPOSData();
  }, [dispatch]);

  const handleOrderCreated = () => {
    setActiveTab('menuSelection');
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'createOrder':
        return <CreateOrderButton onOrderCreated={handleOrderCreated} />;
      case 'menuSelection':
        return <POSMenu />;
      // case 'cart':
      //   return <POSCart />;
      case 'activeBags':
        return <ActiveOrders />;
      // case 'orders':
      //   return <OrdersWindow />;
      // case 'inventory':
      //   return <POSInventory />;
      case 'kitchen':
        return <POSKitchenDisplay />;
      default:
        return <CreateOrderButton onOrderCreated={handleOrderCreated} />;
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Layout style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <Sider 
        collapsible 
        collapsed={collapsed} 
        onCollapse={setCollapsed}
        style={{ height: '100vh', overflow: 'auto' }}
      >
        <div style={{ height: '32px', margin: '16px', background: 'rgba(255, 255, 255, 0.3)' }} />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['createOrder']}>
          <Menu.Item key="createOrder" icon={<MenuOutlined />} onClick={() => setActiveTab('createOrder')}>
            Create Order
          </Menu.Item>
          {/* <Menu.Item key="cart" icon={<ShoppingCartOutlined />} onClick={() => setActiveTab('cart')}>
            Cart
          </Menu.Item> */}
          <Menu.Item key="activeBags" icon={<OrderedListOutlined />} onClick={() => setActiveTab('activeBags')}>
            Active Orders
          </Menu.Item>
          {/* <Menu.Item key="orders" icon={<FileTextOutlined />} onClick={() => setActiveTab('orders')}>
            All Orders
          </Menu.Item>
          <Menu.Item key="inventory" icon={<InboxOutlined />} onClick={() => setActiveTab('inventory')}>
            Inventory
          </Menu.Item> */}
          <Menu.Item key="kitchen" icon={<CoffeeOutlined />} onClick={() => setActiveTab('kitchen')}>
            Kitchen
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header style={{ 
          padding: '0 16px', 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          background: '#fff',
          height: '64px',
          lineHeight: '64px'
        }}>
          <CurrentBagDisplay />
        </Header>
        <Content style={{ 
          flex: 1,
          padding: '24px', 
          overflow: 'auto',
          background: '#fff'
        }}>
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

export default POSPage;