import React, { useState, useEffect } from "react";
import Switch from "./Switch";
import { useSelector, useDispatch } from "react-redux";
import { toggleShutter } from "../api/cafe";
import { updateUser } from "../redux/slices/auth";
import { fetchUser } from "../api/auth";
import { UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import Profile from "../pages/assets/bottom-profile-icon.png";
import Settings from "../pages/assets/bottom-settings-icon.png";
import { LogoutOutlined } from "@ant-design/icons";

const HoverMenu = () => {
  function handleLogout() {
    localStorage.removeItem("OG_token");
    window.location.href = "/cafe-admin/login";
  }

  return (
    <div className="absolute top-20 bottom-0 right-4 flex flex-col  justify-center h-24 px-2 py-1 rounded-lg bg-white  text-[#CACACA]">
      <Link
        to="/cafe-admin/profile"
        className="flex items-center  gap-x-2 hover:text-[#9277FF]"
      >
        <img
          src={Profile}
          alt="Profile"
          className="w-5 h-5 object-contain hover:text-[#9277FF]"
        />{" "}
        Profile
      </Link>
      <Link
        to="/cafe-admin/settings"
        className="flex items-center gap-x-2 hover:text-[#9277FF]"
      >
        <img
          src={Settings}
          alt="Settings"
          className="w-5 h-5 object-contain hover:text-[#9277FF]"
        />
        Settings
      </Link>
      <Link
        to="#"
        className="flex items-center gap-x-2 hover:text-[#9277FF]"
        onClick={() => handleLogout()}
      >
        <LogoutOutlined className="w-5 h-5 object-contain hover:text-[#9277FF]" />
        Logout
      </Link>
    </div>
  );
};
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cafe } = useSelector((state) => state.auth);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [online, setOnline] = useState(cafe?.openstatus);
  const [time, setTime] = useState(new Date().toTimeString().split("GMT")[0]);

  async function fetchUserCall() {
    const token = localStorage.getItem("OG_token");
    try {
      if (token) {
        const resp = await fetchUser();

        resp.data.cafe && setOnline(resp.data.cafe.openstatus);
        dispatch(updateUser(resp.data));
      }
    } catch (error) {
      localStorage.removeItem("OG_token");
      navigate("/cafe-admin/login");
    }
  }

  useEffect(() => {
    fetchUserCall();
  }, [online]);

  function toggleShutterUI() {
    toggleShutter();
    setOnline(!online);
  }

  setInterval(() => {
    setTime(new Date().toTimeString().split("GMT")[0]);
  }, 1000);

  return (
    <div className="flex w-full flex-col relative">
      <div className="flex justify-between items-center p-4 text-xl border-b w-full top-0 h-16 bg-white z-20 ">
        <div className="flex items-center">
          {/* <img
            src="../icon.svg"
            alt=""
            className="h-8"
          /> */}
          <h1 className="text-center ml-4">{cafe?.name}</h1>
        </div>
        <div className=" flex flex-col justify-center ">
          {showMoreOptions && <HoverMenu />}
        </div>
        <button
          onClick={() => {
            console.log("clicked");
            setShowMoreOptions(!showMoreOptions);
          }}
          className="ml-auto bg-[#FD7347] p-2 justify-self-end text-sm text-white flex items-center rounded-full shadow"
        >
          <UserOutlined />
        </button>
      </div>
      {/* <div className="flex justify-between items-center w-full px-4 bg-gray-100"> */}
      {/* <Switch toggle={online} clickAction={toggleShutterUI} /> */}
      <div></div>
      {/* <div className="flex w-1/2 items-end flex-col p-4">
          <h1 className="text-xs mb-1">{new Date().toDateString()}</h1>
          <h2 className="text-md">{time}</h2>
        </div> */}
      {/* </div> */}
      {!cafe.openstatus && (
        <div className="bg-red-500 text-white text-xs text-center w-full">
          Cafe is offline right now
        </div>
      )}
    </div>
  );
};

export default Header;
