import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFoodcourtCafes, getFoodcourtDetails } from '../../api/foodcourt';

const FoodcourtCafeList = () => {
  const [cafes, setCafes] = useState([]);
  const [foodcourt, setFoodcourt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { foodcourtId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!foodcourtId) {
      navigate('/404');
      return;
    }

    const fetchData = async () => {
      try {
        const [cafeResponse, foodcourtResponse] = await Promise.all([
          getFoodcourtCafes(foodcourtId),
          getFoodcourtDetails(foodcourtId)
        ]);
        
        console.log('Cafe response:', cafeResponse);
        console.log('Foodcourt response:', foodcourtResponse);

        if (cafeResponse && cafeResponse.data && Array.isArray(cafeResponse.data)) {
          setCafes(cafeResponse.data);
        } else {
          console.error('Invalid cafe data structure:', cafeResponse);
          setError('Invalid cafe data received');
        }

        if (foodcourtResponse && foodcourtResponse.data) {
          setFoodcourt(foodcourtResponse.data);
        } else {
          console.error('Invalid foodcourt data structure:', foodcourtResponse);
          setError('Invalid foodcourt data received');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [foodcourtId, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full px-4 py-8">
      {foodcourt && (
        <div className="mb-6">
          <h1 className="text-2xl font-bold">{foodcourt.name}</h1>
          <p className="text-gray-600">{foodcourt.description}</p>
        </div>
      )}
      <h2 className="text-xl font-semibold mb-4">Cafes in this Foodcourt</h2>
      {cafes.length === 0 ? (
        <p>No cafes found in this foodcourt.</p>
      ) : (
        <ul className="space-y-4">
          {cafes.map((cafe) => (
            <li key={cafe._id} className="border rounded-lg p-4 shadow-sm">
              <Link to={`/cafe/${cafe._id}`} className="block">
                <h3 className="text-lg font-semibold">{cafe.name}</h3>
                <p className="text-gray-600">{cafe.description}</p>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FoodcourtCafeList;