import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const getFoodcourtCafes = async (foodcourtId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/foodcourt/${foodcourtId}/cafes`);
    console.log('API response for cafes:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching foodcourt cafes:', error);
    throw error;
  }
};

export const getFoodcourtDetails = async (foodcourtId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/foodcourt/${foodcourtId}`);
    console.log('API response for foodcourt details:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching foodcourt details:', error);
    throw error;
  }
};