import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

const ClosedCafe = () => {
    const cafeData = useSelector((state) => state.user.cafe);
    return (
        <div className="w-full h-screen flex flex-col items-center">
            <Helmet
                title={`${cafeData.name} Menu`}
                meta={[
                    {
                        name: "description",
                        content: "Your cafe menu",
                    },
                    {
                        property: "og:title",
                        content: "Menu",
                    },
                ]}
            />
            {cafeData.image && (
                <div className="h-80 w-full fixed top-0 flex flex-col justify-center items-center bg-black">
                    <img
                        src={`data:image/png;base64,${cafeData?.image}`}
                        alt="banner"
                        className="w-full h-80 object-cover absolute top-0 z-0"
                    />
                </div>
            )}
            <div className={`w-full px-4 ${cafeData.image && "mt-60"} pt-4 relative z-20 rounded-t-2xl bg-white flex items-center flex-col justify-center`}>
                <div className="h-1 w-20 rounded-3xl bg-gray-400 mx-auto"></div>
                <h1 className="text-4xl text-center font-bold mt-10"> Cafe is Closed
                </h1>
                <h1 className="text-xl text-center font-bold mt-4 opacity-50">
                    {cafeData.name}
                </h1>
                <img className='mt-20' src="../waiting.png" alt="Closed Banner" />
                <p className='text-center'>Please try again later</p>
            </div>
        </div>
    )
}

export default ClosedCafe