import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchOrder } from '../../api/user'
import { LeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner';

const Invoice = () => {
    const [order, setOrder] = useState({})
     const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams()
    useEffect(() => {
        fetchOrder(id).then((res) => {
            setOrder(res.data)
            setIsLoading(false);
        })
    }, [])

    function formatDate(isoDateString) {

        let date = new Date(isoDateString);

        let year = date.getUTCFullYear();
        let month = date.getUTCMonth() + 1; // getUTCMonth() is zero-indexed, so we add 1
        let day = date.getUTCDate();

        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        const response = {}
        response.date = `${year}-${month}-${day}`;

        response.time = `${hours}:${minutes}:${seconds}`;
        return response
    }


    const cafe = useSelector((state) => state.user.cafe)
    return (
        <div className="w-full bg-gray-100 h-screen flex flex-col items-center">
            <div className='w-full flex justify-between items-center p-4 text-xl font-semibold bg-black text-white'>
                <img src="../../Icon.svg" alt="OG" className='mr-1 h-10' />
                <h1>{cafe.name}</h1>
            </div>

            <div className='w-full text-sm flex flex-col items-center py-6 justify-between px-4 border-b-2 border-dashed'>
                <Link to={`/order/bill/${id}`} className='text-left flex items-center font-bold mb-4 text-orange-500'><LeftOutlined /> Go Back</Link>
                <div className='flex justify-between w-full items-center py-1'><span className='text-sm font-normal'>Table Number:</span> <span className='font-semibold'>{isLoading ? (
        <LoadingSpinner />
      ) : (
        <div >{order.tablenumber}</div>
      )}</span></div>
                <div className='flex justify-between w-full items-center py-1'><span className='text-sm font-normal'>Order Amount:</span> <span className='font-semibold'>{isLoading ? (
        <LoadingSpinner />
      ) : (
        <div >₹{order.amount}</div>
      )}</span></div>
                <div className='flex justify-between w-full items-center py-1'><span className='text-sm font-normal'>Order Date:</span> <span className='font-semibold'>{isLoading ? (
        <LoadingSpinner />
      ) : (
        <div >{formatDate(order.createdat).date}</div>
      )}</span></div>
            </div>

            <div className='w-full text-lg text-left flex-col font-semibold flex items-center justify-center py-6 px-4'>
                <h1 className='text-xl mb-10'>Your order Items</h1>
                {order?.items?.map((item, index) => (
                    <div key={index} className='flex w-full justify-between border-b border-gray-400 py-4 border-dashed'>
                        <p>{item.name} ({item.quantity}) </p>
                        <p>₹{item.price}</p>
                    </div>
                ))
                }
            </div>

            <div className='w-full text-lg text-left flex-col font-semibold flex items-center justify-center py-6 px-4'>
                <h1 className='text-xl mb-10'>Your order Summary</h1>
                <div className='flex justify-between w-full items-center py-1'><span className='text-sm font-normal'>Order Total:</span> <span className='font-semibold'> {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>₹{order.amount}</div>
      )}</span></div>
                <div className='flex justify-between w-full items-center py-1'><span className='text-sm font-normal'>Order SGST (5%):</span> <span className='font-semibold'>{isLoading ? (
        <LoadingSpinner />
      ) : (
        <div >₹{Math.floor(order.amount * 5 / 100, 2)}</div>
      )}</span></div>
                <div className='flex justify-between w-full items-center py-1'><span className='text-sm font-normal'>Order CGST (18%):</span> <span className='font-semibold'>{isLoading ? (
        <LoadingSpinner />
      ) : (
        <div >₹{Math.floor(Math.floor(order.amount - order.amount * 5 / 100) * 18 / 100)}</div>
      )}</span></div>
                <div className='flex justify-between w-full items-center py-1'><span className='text-sm font-normal'>Service Charges (10%)</span> <span className='font-semibold'>{isLoading ? (
        <LoadingSpinner />
      ) : (
        <div >₹{Math.floor((order.amount - Math.floor((order.amount - order.amount * 5 / 100) * 18 / 100) - Math.floor(order.amount - order.amount * 5 / 100) * 18 / 100) * 10 / 100)}</div>
      )}</span></div>
            </div>

            <p className='text-xl text-center font-semibold mb-8'>Thank You! Visit again</p>

            <div className='w-full text-left bg-black text-white text-sm flex justify-between px-4 py-8 border-b-2 border-dashed'>
                <div className='w-1/2 flex flex-col items-center text-left'>
                    <p className='font-bold'>{cafe.name}</p>
                    <p>{cafe.address}</p>
                    <p>{cafe.city}, {cafe.pincode}</p>
                    <p>{cafe.state}</p>
                </div>
                <div className='w-1/2 flex flex-col items-center text-left'>
                    <p className='font-bold'>Powered By:</p>
                    <p className='text-orange-500 font-bold'>OrderG</p>
                    <p>+91 7007992740</p>
                    <p>{cafe.state}</p>
                </div>
            </div>
        </div>
    )
}

export default Invoice