import React from "react";
import swiggy from "../pages/assets/Group 18291.svg";
import zomato from "../pages/assets/image 52.svg";
import banner from "../pages/assets/banner.png";
import vector from "../pages/assets/Vector.svg";
import { useSelector } from "react-redux";

const Card = ({ offerData }) => {
  const cafeData = useSelector((state) => state.auth.cafe);
  return (
    <div className="max-w-sm mx-auto px-4   relative bg-white rounded-lg  overflow-hidden md:max-w-md">
      <div className="relative rounded-xl mt-4">
        <img
          className="w-full h-24 rounded-t-lg  object-cover pt-4"
          src={
            offerData.bannerImage
              ? URL.createObjectURL(offerData.bannerImage) ||
                `data:image/jpeg;base64,${offerData.bannerImage}`
              : banner
          }
          alt="Strawberries"
        />
        <div
          className="flex absolute top-6 z-100 flex-row items-center justify-between  w-full h-50"
          style={{ top: "73px" }}
        >
          {offerData?.logo ? (
            <div className="  bg-white rounded-full px-3 py-1 h-10 w-10 text-xs font-bold text-black m-2">
              <img
                src={offerData.logo}
                alt="logo"
                className="h-6 w-6"
              />
            </div>
          ) : (
            <p className="bg-white rounded-full px-3 py-1 text-xs font-bold text-black m-2 text-left">
              {cafeData?.name}
            </p>
          )}
          {offerData?.rewardType !== "No Discount" && (
            <div className=" bg-white rounded-full px-3 py-1 text-xs font-bold text-black m-2">
              {offerData?.rewardType === "Redeem % discount" && (
                <span className="text-black font-bold">
                  {offerData.discount}% OFF
                </span>
              )}
              {offerData?.rewardType === "Redeem Rs discount" && (
                <span className="text-black  font-bold">
                  Rs {offerData.discount} OFF
                </span>
              )}
              {offerData?.rewardType === "Redeem a free gift" && (
                <span className="text-black font-bold">
                  Free {offerData.freeGift}
                </span>
              )}
            </div>
          )}
        </div>
        <div
          className="p-6 flex flex-col items-center justify-center"
          style={{
            backgroundColor: offerData?.backgroundColor || "red",
          }}
        >
          <p className="text-md font-bold mt-4 text-white text-center ">
            {offerData?.campaignTitle || "Offer Title"}
          </p>
          {offerData?.addMinimumPrice && (
            <p className="text-sm text-white mt-2 text-center">
              On minimum purchase of {offerData?.minimumPurchase}
            </p>
          )}
          <p className="text-xs text-white mt-2 text-center">
            {offerData?.campaignDescription ||
              "Offer description will appear here"}
          </p>

          {/* <hr className="border-t border-gray-400  " /> */}
          <div
            className="bg-white w-full h-[1px] my-2"
            style={{ background: "bg-white", height: "1px" }}
          ></div>
          <p className=" font-bold text-sm text-center text-white">
            Expires on {offerData?.expirationDate || ""}
          </p>
          <div
            className="bg-white w-full h-[1px] my-2"
            style={{ background: "bg-white", height: "1px" }}
          ></div>
          <ul className="list-disc list-inside text-xs text-center">
            <li>
              {offerData?.termsAndConditions.map((item, index) => {
                return (
                  item && (
                    <div>
                      <span>{item}</span>
                    </div>
                  )
                );
              }) || "Terms and conditions will be listed here"}
            </li>
          </ul>
        </div>
      </div>

      {(offerData?.addToSwiggy || offerData?.addToZomato) && (
        <div className="p-4 text-center text-xs text-gray-500">
          Offer also available here, click to open *
        </div>
      )}
      <div className="flex items-center justify-center space-x-2 pb-2">
        {offerData?.addToSwiggy && (
          <img
            src={swiggy}
            alt="Swiggy"
            className="h-6"
          />
        )}
        {offerData?.addToZomato && (
          <img
            src={zomato}
            alt="Zomato"
            className="h-6"
          />
        )}
      </div>
    </div>
  );
};

export default Card;
