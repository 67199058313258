import React, { useState } from "react";
import UploadButton from "../UploadButton";
import { BackwardOutlined } from "@ant-design/icons";
import { createOffer } from "../../api/cafe";
import Swiggy from "../../pages/assets/swiggy logo.jpg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay";
import DatePicker from "react-datepicker";

import zomato from "../../pages/assets/image 52.svg";
import Offercard from "../offerCard";
const rewardTypes = [
  { label: "Redeem % discount", description: "" },
  { label: "Redeem Rs discount", description: "" },
  { label: "Redeem a free gift", description: "" },
  { label: "No Discount", description: "(Remind customers about you)" },
];

const colors = [
  "#047A07",
  "#55250E",
  "#3B4218",
  "#090000",
  "#914B4B",
  "#988407",
  "#524C4C",
  "#6B422C",
];

const renderPreviewCard = (offerData) => (
  <div
    className="w-full max-w-sm mx-auto text-white rounded-lg overflow-hidden shadow-lg my-4"
    style={{ backgroundColor: offerData.backgroundColor }}
  >
    {/* <div className="relative h-48 bg-cover bg-center">
      <div className="absolute top-0 left-0 m-4 rounded-full p-2">
        <div className="w-12 h-12 rounded-full"></div>
      </div>
      <div className="absolute top-0 right-0 m-4 rounded-full py-1 px-3">
        {offerData.rewardType === "Redeem % discount" && (
          <span className="text-white font-bold">
            {offerData.discount}% OFF
          </span>
        )}
        {offerData.rewardType === "Redeem Rs discount" && (
          <span className="text-white font-bold">
            Rs {offerData.discount} OFF
          </span>
        )}
        {offerData.rewardType === "Redeem a free gift" && (
          <span className="text-white font-bold">
            Free {offerData.freeGift}
          </span>
        )}
        {offerData.minimumPurchase && (
          <span className="text-white font-bold block">
            Min Purchase: Rs {offerData.minimumPurchase}
          </span>
        )}
      </div>
    </div> */}
    {/* <div className="p-4 bg-green-800">
      <h2 className="text-xl font-bold mb-2">
        {offerData.campaignTitle || "Offer Title"}
      </h2>
      <p className="text-sm mb-4 indent-8">
        {offerData.campaignDescription || "Offer description will appear here"}
      </p>
    </div> */}
    {/* <div className="p-4">
      <h3 className="font-bold mb-2">Conditions:</h3>
      <ul className="list-disc list-inside text-sm">
        <li>
          {offerData.termsAndConditions.map((item, index) => {
            return (
              item && (
                <div>
                  {index + 1} <span>{item}</span>
                </div>
              )
            );
          }) || "Terms and conditions will be listed here"}
        </li>
      </ul>
    </div> */}
    {/* <div className="p-4 border-t">
      <p className="text-sm text-center">Offer also available on:</p>
      <div className="flex justify-center space-x-4 mt-2">
        {offerData.addToZomato && (
          <img
            src={zomato}
            alt="Zomato"
            className="h-6"
          />
        )}
        {offerData.addToSwiggy && (
          <img
            src={Swiggy}
            alt="Swiggy"
            className="h-6"
          />
        )}
      </div>
    </div> */}
    <div>
      <Offercard offerData={offerData} />
    </div>
  </div>
);

const renderCustomPosterPreview = (previewData) => (
  <div className="w-full h-full max-w-sm mx-auto text-white rounded-lg overflow-hidden shadow-lg my-4">
    <div
      className="relative bg-cover bg-center"
      style={{
        width: "100%",
        height: "200px",
        backgroundImage: `url(${URL.createObjectURL(previewData.posterImage)})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute bottom-0 right-0  w-full flex justify-center space-x-4 mt-2 text-red-500">
        {previewData.addToZomato && (
          <img
            src={zomato}
            alt="Swiggy"
            className="h-6"
          />
        )}
        {previewData.addToSwiggy && (
          <img
            src={Swiggy}
            alt="Zomato"
            className="h-6"
          />
        )}
      </div>
    </div>
  </div>
);

const CampaignForm = ({ setCreationMode }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [showCustomPosterPreview, setShowCustomPosterPreview] = useState(true);
  const [showInitialCampaignScreen, setShowInitialCampaignScreen] =
    useState(true);
  const [isCustomPoster, setIsCustomPoster] = useState(false);
  const [posterReadyForPreview, setPosterReadyForPreview] = useState(false);
  const [radioChanged, setRadioChanged] = useState(false);
  const [formData, setFormData] = useState({
    rewardType: "Redeem % discount",
    discount: "",
    freeGift: "",
    addMinimumPrice: false,
    minimumPurchase: "",
    addToZomato: false,
    addToSwiggy: false,
    otherDiscount: "",
    campaignTitle: "",
    campaignDescription: "",
    logo: null,
    expirationDate: "",
    backgroundColor: "",
    termsAndConditions: ["", "", "", "", ""],
    offerType: "card",
  });

  const [previewData, setPreviewData] = useState({
    posterImage: null,
    addToZomato: false,
    addToSwiggy: false,
    expirationDate: "",
    offerType: "poster",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "rewardType") {
      setRadioChanged(true);
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        discount: "",
        freeGift: "",
        addMinimumPrice: false,
        minimumPurchase: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCustomPosterChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File uploaded: ", file);
      setPreviewData((prevState) => ({
        ...prevState,
        posterImage: file,
      }));
      setCreationMode(false);
      setShowInitialCampaignScreen(false);
      setIsCustomPoster(true);
    } else {
      console.log("No file selected");
    }
  };

  const handleTermsChange = (index, value) => {
    setFormData((prevState) => {
      const newTerms = [...prevState.termsAndConditions];
      newTerms[index] = value;
      return { ...prevState, termsAndConditions: newTerms };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Yaha pe jo data aaya hai: ", formData);
    try {
      setLoading(true);
      await createOffer(formData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setFormData({
        rewardType: "",
        discount: "",
        minimumPurchase: "",
        addToZomato: false,
        addToSwiggy: false,
        freeGift: "",
        otherDiscount: "",
        campaignTitle: "",
        campaignDescription: "",
        logo: null,
        expirationDate: "",
        backgroundColor: null,
        termsAndConditions: ["", "", "", "", ""],
      });
      setShowInitialCampaignScreen(true);
      navigate("/cafe-admin/all-offer-analytics");
    }
  };

  const handleSubmitPoster = async () => {
    const currentFile = previewData.posterImage;
    const loadingToast = toast.loading("Uploading a new Campaign Poster...");
    if (
      !currentFile &&
      currentFile.type !== "image/jpeg" &&
      currentFile.type !== "image/png" &&
      currentFile.type !== "image/webp"
    ) {
      toast.update(loadingToast, {
        render: "Please upload a valid image file",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }
    console.log("Yaha pe jo data aaya hai: ", previewData);
    try {
      setLoading(true);
      const response = await createOffer(previewData);
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setShowInitialCampaignScreen(true);
      setPreviewData({
        posterImage: null,
        addToZomato: false,
        addToSwiggy: false,
        expirationDate: "",
      });
      navigate("/cafe-admin/all-offer-analytics");
    }
  };

  const renderDiscountInput = () => {
    switch (formData.rewardType) {
      case "Redeem % discount":
        return (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Enter Discount (%)
            </label>
            <input
              type="number"
              name="discount"
              value={Math.max(0, Math.min(100, formData.discount)) || ""}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 placeholder:"
            />
          </div>
        );
      case "Redeem Rs discount":
        return (
          <>
            <div className="flex items-center gap-x-4">
              <input
                type="checkbox"
                name="addMinimumPrice"
                checked={formData.addMinimumPrice}
                onChange={() => {
                  setFormData((prevState) => ({
                    ...prevState,
                    addMinimumPrice: !prevState.addMinimumPrice,
                    minimumPurchase: "",
                  }));
                }}
              />
              <label className="block text-sm font-medium text-gray-700">
                Add Minimum Purchase Price
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Enter Discount (Rs)
              </label>
              <input
                type="number"
                name="discount"
                value={formData.discount}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            {formData.addMinimumPrice && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Minimum Purchase (Rs)
                </label>
                <input
                  type="number"
                  name="minimumPurchase"
                  value={formData.minimumPurchase}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            )}
          </>
        );
      case "Redeem a free gift":
        return (
          <>
            <div className="flex items-center gap-x-4">
              <input
                type="checkbox"
                name="addMinimumPrice"
                checked={formData.addMinimumPrice}
                onChange={() => {
                  setFormData((prevState) => ({
                    ...prevState,
                    addMinimumPrice: !prevState.addMinimumPrice,
                  }));
                }}
              />
              <label className="block text-sm font-medium text-gray-700">
                Add Minimum Purchase Price
              </label>
            </div>
            <div></div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Add items to give for free
              </label>
              <input
                type="text"
                name="freeGift"
                value={formData.freeGift}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            {formData.addMinimumPrice && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Minimum Purchase (Rs)
                </label>
                <input
                  type="number"
                  name="minimumPurchase"
                  value={formData.minimumPurchase}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  function textAreaAdjust(element) {
    element.style.height = "1px";
    element.style.height = 25 + element.scrollHeight + "px";
  }

  function handleCreateCustomPoster() {
    setCreationMode(false);
    setShowInitialCampaignScreen(false);
    setIsCustomPoster(false);
  }

  function handleChooseBackground(index) {
    console.log("The color you chose is: ", colors[index]);
    // setFormData((prevState) => ({
    //   ...prevState,
    //   backgroundColor: colors[index],
    // }));
    // setCreationMode(false);
    // setShowInitialCampaignScreen(false);
    // setIsCustomPoster(false);
  }

  return (
    <div className="mb-20">
      {showInitialCampaignScreen ? (
        <>
          <div className="p-4 mx-auto overflow-scroll mb-4">
            <div className="my-4 w-full mx-auto">
              <UploadButton
                text="Upload Custom designed Poster and Share directly"
                onFileChange={handleCustomPosterChange}
                accept="image/*"
              />
            </div>
          </div>
          <div
            className="cursor-pointer mb-2 w-full h-20 flex justify-center items-center border-2 border-black rounded-xl shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]"
            onClick={() => handleCreateCustomPoster()}
          >
            <p className="font-bold text-xl">Create Your Custom Offer</p>
          </div>
          <div className="grid grid-col-1  w-full  gap-4 hidden">
            {colors.map((color, index) => (
              <div
                key={index}
                className="grid grid-col-1 w-full    border-2 rounded-lg cursor-pointer transition-colors duration-300"
                style={{
                  backgroundColor: color,
                  height: "320px",
                }}
                onClick={() => handleChooseBackground(index)}
              ></div>
            ))}
          </div>
        </>
      ) : (
        <div className="p-4 max-w-md mx-auto mb-20">
          {isCustomPoster ? (
            <>
              <div
                className="flex items-center gap-x-2 cursor-pointer"
                onClick={() => {
                  setCreationMode(true);
                  setShowInitialCampaignScreen(true);
                  setIsCustomPoster(false);
                  setPreviewData({
                    posterImage: null,
                    addToSwiggy: false,
                    addToZomato: false,
                    expirationDate: "",
                  });
                }}
              >
                <BackwardOutlined />
                Back
              </div>
              <h2 className="text-2xl font-bold">Custom Poster</h2>
              <p className="text-sm text-gray-500">
                Create a custom poster for your campaign
              </p>
              <div className="my-2">
                {renderCustomPosterPreview(previewData)}
              </div>
              <div className="w-full my-2">
                <label className="block text-sm font-medium text-gray-700">
                  Check if same offer available on online ordering
                </label>
                <div className="flex flex-col">
                  <div className="border-2 flex items-center px-4 py-2 justify-between my-2 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]">
                    <label className="block text-sm font-medium text-gray-700">
                      Zomato
                    </label>
                    <input
                      type="checkbox"
                      name="addToZomato"
                      checked={previewData.addToZomato}
                      onChange={() =>
                        setPreviewData((prevState) => ({
                          ...prevState,
                          addToZomato: !prevState.addToZomato,
                        }))
                      }
                      className="mt-1 block border border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
                  <div className="border-2 flex items-center px-4 py-2 justify-between my-2 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]">
                    <label className="block text-sm font-medium text-gray-700">
                      Swiggy
                    </label>
                    <input
                      type="checkbox"
                      name="addToSwiggy"
                      checked={previewData.addToSwiggy}
                      onChange={() =>
                        setPreviewData((prevState) => ({
                          ...prevState,
                          addToSwiggy: !prevState.addToSwiggy,
                        }))
                      }
                      className="mt-1 block border border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col">
                <label className="w-full text-sm font-medium text-gray-700 mb-2">
                  Campaign Expiration Date
                </label>
                <DatePicker
                  className="w-full px-4 py-2 border border-gray-300 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)] focus:outline-none focus:border-blue-500"
                  required
                  selected={previewData.expirationDate}
                  onChange={(date) =>
                    setPreviewData((prevState) => ({
                      ...prevState,
                      expirationDate: date
                        ?.toString()
                        ?.split(" ")
                        ?.splice(0, 4)
                        ?.toString()
                        ?.replace(/,/g, " "),
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                />
              </div>
              <button
                type="submit"
                className="w-full bg-purple-500 text-white p-2 rounded-md hover:bg-purple-700 mt-2"
                onClick={handleSubmitPoster}
              >
                Activate
              </button>
            </>
          ) : (
            <>
              {posterReadyForPreview ? (
                <>
                  <div
                    className="flex items-center gap-x-2 cursor-pointer"
                    onClick={() => {
                      setPosterReadyForPreview(false);
                    }}
                  >
                    <BackwardOutlined />
                    Go Back to edit details
                  </div>
                  <div>{renderPreviewCard(formData)}</div>
                  <button
                    type="submit"
                    className="w-full bg-purple-600 text-white p-2 rounded-md hover:bg-purple-700"
                    onClick={handleSubmit}
                  >
                    Activate
                  </button>
                </>
              ) : (
                <>
                  <div
                    className="flex items-center gap-x-2 cursor-pointer"
                    onClick={() => {
                      setCreationMode(true);
                      setFormData((prevState) => ({
                        ...prevState,
                        backgroundColor: null,
                      }));
                      setShowInitialCampaignScreen(true);
                    }}
                  >
                    <BackwardOutlined />
                    Back
                  </div>
                  <div>{renderPreviewCard(formData)}</div>
                  <form className="space-y-4">
                    {
                      colors.map((color, index) => (
                        //show a horizontal grid of colors, like a carosel and add a distinct border to the selected color
                        <div
                          key={index}
                          className={`w-10 h-10 border-2 inline-block cursor-pointer transition-colors duration-300 ${formData.backgroundColor === color
                              ? "border-purple-500"
                              : "border-gray-300"
                            }`}
                          style={{ backgroundColor: color }}
                          onClick={() => {
                            setFormData((prevState) => ({
                              ...prevState,
                              backgroundColor: color,
                            }));
                          }}
                        ></div>
                      ))
                    }
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Reward Type
                      </label>
                      <div className="flex flex-col gap-y-2">
                        {rewardTypes.map((rewardType, index) => (
                          <label
                            key={index}
                            className={`flex my-2 items-center justify-between p-3 border-2 rounded-lg cursor-pointer transition-colors duration-300 ${formData.rewardType === rewardType.label
                                ? "border-purple-500 bg-white shadow-md"
                                : "border-gray-300 bg-white"
                              }`}
                          >
                            <div>
                              <span className="font-medium">
                                {rewardType.label}
                              </span>
                              {rewardType.description && (
                                <span className="text-xs text-gray-500">
                                  {" "}
                                  {rewardType.description}
                                </span>
                              )}
                            </div>
                            <input
                              type="radio"
                              name="rewardType"
                              value={rewardType.label}
                              checked={
                                !radioChanged
                                  ? index === 0
                                  : formData.rewardType === rewardType.label
                              }
                              onChange={handleInputChange}
                              className="form-radio text-purple-500 focus:ring-purple-500"
                            />
                          </label>
                        ))}
                      </div>
                    </div>
                    {renderDiscountInput()}
                    <div className="w-full">
                      <label className="block text-sm font-medium text-gray-700">
                        Check if same offer available on online ordering
                      </label>
                      <div className="flex flex-col">
                        <div className="border-2 flex items-center px-4 py-2 justify-between my-2 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]">
                          <label className="block text-sm font-medium text-gray-700">
                            Zomato
                          </label>
                          <input
                            type="checkbox"
                            name="addToZomato"
                            checked={formData.addToZomato}
                            onChange={() =>
                              setFormData((prevState) => ({
                                ...prevState,
                                addToZomato: !prevState.addToZomato,
                              }))
                            }
                            className="mt-1 block border border-gray-300 rounded-md shadow-sm p-2"
                          />
                        </div>
                        <div className="border-2 flex items-center px-4 py-2 justify-between my-2 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]">
                          <label className="block text-sm font-medium text-gray-700">
                            Swiggy
                          </label>
                          <input
                            type="checkbox"
                            name="addToSwiggy"
                            checked={formData.addToSwiggy}
                            onChange={() =>
                              setFormData((prevState) => ({
                                ...prevState,
                                addToSwiggy: !prevState.addToSwiggy,
                              }))
                            }
                            className="mt-1 block border border-gray-300 rounded-md shadow-sm p-2"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Campaign Title
                      </label>
                      <input
                        type="text"
                        name="campaignTitle"
                        value={formData.campaignTitle}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border border-gray-300  justify-between my-2 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Campaign Description
                      </label>
                      <textarea
                        name="campaignDescription"
                        onKeyUp={(e) => textAreaAdjust(e.target)}
                        value={formData.campaignDescription}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border border-gray-300  justify-between my-2 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]"
                      />
                    </div>

                    {/* <div className="w-full">
                      <label className="block text-sm font-medium text-gray-700">
                        Upload your Logo
                      </label>
                      <UploadButton
                        text="Click to upload"
                        onFileChange={handleFileChange}
                      />
                      <p className="text-xs my-1">
                        Recommended size 512 x 512 px in JPG, GIF or PNG format
                        upto max size of 1 MB
                      </p>
                      {formData.logo && (
                        <p className="text-sm text-gray-500 text-center">
                          The image you uploaded: {formData.logo.name}
                        </p>
                      )}
                    </div> */}

                    <div className="w-full flex flex-col">
                      <label className="w-full text-sm font-medium text-gray-700 mb-2">
                        Campaign Expiry Date
                      </label>
                      <DatePicker
                        className="w-full px-4 py-2 border border-gray-300 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)] focus:outline-none focus:border-blue-500"
                        selected={formData.expirationDate}
                        onChange={(date) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            expirationDate: date
                              ?.toString()
                              ?.split(" ")
                              ?.splice(0, 4)
                              ?.toString()
                              ?.replace(/,/g, " "),
                          }))
                        }
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Terms & Conditions
                      </label>
                      {formData.termsAndConditions.map((term, index) => (
                        <input
                          key={index}
                          type="text"
                          value={term}
                          onChange={(e) =>
                            handleTermsChange(index, e.target.value)
                          }
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                          placeholder={`Term ${index + 1}`}
                        />
                      ))}
                    </div>

                    <button
                      className="w-full bg-purple-600 text-white p-2 rounded-md hover:bg-purple-700"
                      onClick={() => {
                        console.log("Preview data: ",formData);
                        setPosterReadyForPreview(true);
                      }}
                    >
                      Preview
                    </button>
                  </form>
                </>
              )}
            </>
          )}
        </div>
      )}
      <LoadingOverlay
        title="Please wait, Campaing is Uploading..."
        show={loading}
      />
      <ToastContainer />
    </div>
  );
};

export default CampaignForm;
