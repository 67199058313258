import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const cafeSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setCafeItems: (state, action) => {
      state.items = action.payload;
    },
    addItemInState: (state, action) => {
      if (action.payload.recommended) {
        state.items["Recommended"]
          ? state.items["Recommended"].push(action.payload)
          : (state.items["Recommended"] = [action.payload]);
      }
      state.items[action.payload.category]
        ? state.items[action.payload.category].push(action.payload)
        : (state.items[action.payload.category] = [action.payload]);
    },
    updateItemInState: (state, action) => {
      const index = state.items[action.payload.category].findIndex(
        (item) => item._id === action.payload._id
      );
      state.items[action.payload.category][index] = action.payload;
    },
  },
});

export const { setCafeItems, addItemInState, updateStatus } =
  cafeSlice.actions;
export default cafeSlice.reducer;
