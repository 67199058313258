import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import BottomMenu from "../../components/BottomMenu";
import { Helmet } from "react-helmet";
import { getAnalyticsEvents } from "../../api/cafe";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays, subMonths, subYears, startOfDay } from "date-fns";
import { ArrowUpOutlined } from "@ant-design/icons";
import LoadingOverlay from "../../components/LoadingOverlay";
import AnalyticsDashboard from "../../components/analytics/AnalyticsDashboard";
import { format, endOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const Analytics = () => {
  const [analyticsEvents, setAnalyticsEvents] = useState([]);
  const [newUsersReached, setNewUsersReached] = useState(0);
  const [googleReviewRedirects, setGoogleReviewRedirects] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [range, setRange] = useState("Last 7 Days");
  useEffect(() => {
    if (range !== "Custom Date") {
      setDatesForRange(range);
    }
  }, [range]);

  useEffect(() => {
    fetchData(startOfDay(startDate), endDate);
  }, [startDate, endDate]);

  const setDatesForRange = (range) => {
    const today = new Date();
    switch (range) {
      case "Today":
        setStartDate(startOfDay(today));
        setEndDate(today);
        break;
      case "Last Week":
        setStartDate(subDays(today, 7));
        setEndDate(today);
        break;
      case "Last Month":
        setStartDate(subMonths(today, 1));
        setEndDate(today);
        break;
      case "Last Year":
        setStartDate(subYears(today, 1));
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  async function fetchData(startDate, endDate) {
    setIsLoading(true);
    setError(null);
    try {
      // Convert dates to Indian Standard Time (IST)
      const istStartDate = toZonedTime(startOfDay(startDate), 'Asia/Kolkata');
      const istEndDate = toZonedTime(endOfDay(endDate), 'Asia/Kolkata');

      // Format dates in ISO format
      const formattedStartDate = format(istStartDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      const formattedEndDate = format(istEndDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

      const response = await getAnalyticsEvents(
        formattedStartDate,
        formattedEndDate
      );
      console.log("Fetched data for analytics:", response);

      if (response && response.data && Array.isArray(response.data)) {
        setAnalyticsEvents(response.data);
        const newUsers = new Set(response.data.map(item => item.user_id)).size;
        setNewUsersReached(newUsers);
        const googleRedirects = response.data.filter(item => item.event_type === 'google_review_redirect').length;
        setGoogleReviewRedirects(googleRedirects);
      } else {
        setError("Received data is not in the expected format");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch analytics data");
    } finally {
      setIsLoading(false);
    }
  }

  const handleRangeChange = (e) => {
    setRange(e.target.value);
    if (e.target.value !== "Custom Date") {
      setDatesForRange(e.target.value);
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center gap-3 pb-20 px-4">
      <Header />
      <Helmet
        title="Analytics"
        meta={[
          {
            name: "description",
            content: "Analytics for your cafe",
          },
          {
            property: "og:title",
            content: "Analytics",
          },
        ]}
      />
      <div className="font-bold text-left w-full text-[25px] flex">
        Your Business at Glance
      </div>

      {isLoading && <LoadingOverlay show={isLoading} />}
      {error && <div className="text-red-500">{error}</div>}

      <div className="flex flex-row items-center gap-12">
        <div className="w-full text-left font-lg font-bold ">
          Overall Insights
        </div>
        <div className="flex flex-row items-center justify-center gap-1">
          <select
            value={range}
            onChange={handleRangeChange}
            className="bg-gray-200 p-2 rounded"
          >
            <option value="Today">Today</option>
            <option value="Last Week">Last Seven Days</option>
            <option value="Last Month">Last 30 Days</option>
            <option value="Last Year">Last Year</option>
            <option value="Custom Date">Custom Date</option>
          </select>
        </div>
      </div>
      {range === "Custom Date" && (
        <div className="flex flex-row items-center justify-center gap-1 mt-2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="bg-gray-200 p-2 rounded mr-4 w-[130px]"
            dateFormat="dd/MM/yyyy" 
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="bg-gray-200 p-2 rounded mr-4 w-[130px]"
            dateFormat="dd/MM/yyyy" 
          />
        </div>
      )}

      {!isLoading && !error && (
        <AnalyticsDashboard 
          analyticsData={analyticsEvents}
          newUsersReached={newUsersReached}
          googleReviewRedirects={googleReviewRedirects}
        />
      )}

      <BottomMenu page="home" />
    </div>
  );
};

export default Analytics;