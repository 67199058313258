import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, updateQuantity, clearCart } from '../../redux/slices/cartSlice';
import { createKOTFromBagItemsDiner, createUserBagDiner } from '../../api/pos';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { wsService } from '../../components/pos/services/websocket';

const Checkout = () => {
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [note, setNote] = useState('');
  const [orderType, setOrderType] = useState('DINEIN');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cafeData = sessionStorage.getItem("OG_cafe");
    if (cafeData) {
      const cafe = JSON.parse(cafeData);
      wsService.connect(cafe._id);
    }

    return () => {
      wsService.disconnect();
    };
  }, []);

  const handleUpdateQuantity = (id, newQuantity) => {
    if (newQuantity > 0) {
      dispatch(updateQuantity({ id, quantity: newQuantity }));
    } else {
      dispatch(removeFromCart(id));
    }
  };

  const handlePlaceOrder = async () => {
    try {
      setLoading(true);
      
      const customerPhone = localStorage.getItem("OG_phone");
      const customerName = localStorage.getItem("OG_name") || 'Guest';

      const cafeData = sessionStorage.getItem("OG_cafe");

      if (!cafeData) {
        throw new Error('Cafe information not found');
      }
      const cafe = JSON.parse(cafeData);
      const tableNumber = cafe.table || null;
      
      if (!customerPhone) {
        message.error('Please provide your phone number before placing the order');
        return;
      }

      if (!cafe._id) {
        message.error('Invalid cafe information');
        return;
      }

      const userBagData = {
        userName: customerName,
        userPhone: customerPhone,
        orderType: orderType,
        cafeId: cafe._id,
        tableNumber: tableNumber
      };

      console.log('Sending user bag data:', userBagData);

      const bagResponse = await createUserBagDiner(userBagData);
      console.log('API Response:', bagResponse);

      // Check if response has bagId directly
      if (!bagResponse || !bagResponse.bagId) {
        console.error('Invalid response structure:', bagResponse);
        throw new Error('Invalid response from server');
      }

      const bagId = bagResponse.bagId;
      console.log('Using bag ID:', bagId);

      const kotItems = cart.items.map(item => ({
        itemId: item.id,
        quantity: item.quantity,
        notes: note
      }));

      console.log('Creating KOT with items:', kotItems);

      const kotResponse = await createKOTFromBagItemsDiner(bagId, kotItems);
      console.log('KOT Response:', kotResponse);

      wsService.sendMessage('NEW_ORDER', {
        bagId,
        tableNumber,
        customerName,
        items: kotItems,
        kotId: kotResponse.kotId
      });

      dispatch(clearCart());
      message.success('Order placed successfully!');
      
      sessionStorage.setItem('lastOrderBagId', bagId);
      navigate('/order-confirmation');

    } catch (error) {
      console.error('Error placing order:', error);
      if (error.response) {
        console.error('Error response details:', error.response.data);
        message.error(error.response.data.message || 'Failed to place order. Please try again.');
      } else {
        message.error(error.message || 'Failed to place order. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Checkout</h1>
      
      {cart.items.map(item => (
        <div key={item.id} className="flex justify-between items-center mb-2 p-2 border-b">
          <span>{item.name}</span>
          <div>
            <button 
              onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
              className="px-2 py-1 bg-gray-200 rounded"
            >
              -
            </button>
            <span className="mx-2">{item.quantity}</span>
            <button 
              onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
              className="px-2 py-1 bg-gray-200 rounded"
            >
              +
            </button>
          </div>
          <span>₹{(item.price * item.quantity).toFixed(2)}</span>
        </div>
      ))}

      <div className="mt-4 text-xl font-bold">
        Total: ₹{cart.total.toFixed(2)}
      </div>
      
      <div className="mt-4">
        <h2 className="text-lg font-semibold mb-2">Order Type</h2>
        <div className="flex space-x-4">
          <label className="flex items-center">
            <input
              type="radio"
              value="DINEIN"
              checked={orderType === 'DINEIN'}
              onChange={() => setOrderType('DINEIN')}
              className="mr-2"
            />
            Dine-in
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              value="TAKEAWAY"
              checked={orderType === 'TAKEAWAY'}
              onChange={() => setOrderType('TAKEAWAY')}
              className="mr-2"
            />
            Takeaway
          </label>
        </div>
      </div>

      <textarea
        className="w-full mt-4 p-2 border rounded"
        rows="3"
        placeholder="Add a note to your order"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      ></textarea>
      
      <button 
        onClick={handlePlaceOrder}
        disabled={loading || cart.items.length === 0}
        className={`mt-4 w-full py-2 rounded ${
          loading || cart.items.length === 0
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-green-500 hover:bg-green-600'
        } text-white transition-colors`}
      >
        {loading ? 'Placing Order...' : 'Place Order'}
      </button>
    </div>
  );
};

export default Checkout;