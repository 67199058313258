import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaClock, FaThumbsUp } from "react-icons/fa";
import Offercard from "../../components/user/offerCard";

import {
  getActiveOffers,
  getGalleryImages,
  getCafeDetails,
} from "../../api/user";
import banner from "../assets/banner.png";
import insta from "../assets/lets-icons_insta.svg";
import facebook from "../assets/mingcute_facebook-line.svg";
import globe from "../assets/ph_globe.svg";

import RatingCard from "../../components/frontpage/review";
import Contact from "../../components/frontpage/contact";
import BottomMenu from "../../components/user/BottomMenu";
import StarRating from "../../components/StarRating";
import { Link as NavLink } from "react-router-dom";
import Edit from "../assets/edit.png";
import swiggy from "../assets/Group 18291.svg";
import zomato from "../assets/image 52.svg";
import { getPublicCafeReviewStats } from "../../api/cafe";
import vector from "../assets/Vector.svg";
import leftarrow from "../assets/leftarrow.svg";

import { recordAnalyticsEvent } from "../../api/analytics";
import OfferPopup from "../../components/user/OfferPopup";
import GalleryPopup from "../../components/user/GalleryPopup";
import ShareButton from "../../components/user/ShareButton";

const image = banner;

const CafeProfile = () => {
  const [cafeId, setCafeId] = useState(null);
  const [offers, setOffers] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [cafeDetails, setCafeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviewStats, setReviewStats] = useState(null);
  const [cafeopenstatus, setCafeOpenstatus] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const selectedCafeId = sessionStorage.getItem("selected_cafe_id");
    if (selectedCafeId) {
      loadCafeProfile(selectedCafeId);
    } else {
      const cafeData = sessionStorage.getItem("OG_cafe");
      if (!cafeData) {
        toast.error("Cafe data not found. Please try again.");
        navigate("/");
        return;
      }

      try {
        const cafeObj = JSON.parse(cafeData);
        setCafeId(cafeObj._id);
        setCafeOpenstatus(cafeObj.openstatus);
        if (!cafeObj._id) {
          throw new Error("Invalid cafe data");
        }
        loadCafeProfile(cafeObj._id);
      } catch (error) {
        console.error("Error parsing cafe data:", error);
        toast.error("Invalid cafe data. Please try again.");
        navigate("/");
      }
    }
  }, [navigate]);

  const loadCafeProfile = async (cafeId) => {
    try {
      const [offersResult, galleryResult, detailsResult, reviewStats] =
        await Promise.all([
          getActiveOffers(cafeId),
          getGalleryImages(cafeId),
          getCafeDetails(cafeId),
          getPublicCafeReviewStats(cafeId),
        ]);

      setOffers(
        offersResult.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ) || []
      );
      setGalleryImages(galleryResult.data || []);
      setCafeDetails(detailsResult.data || null);
      setReviewStats(reviewStats.data || null);
    } catch (error) {
      console.error("Error fetching cafe profile information:", error);
      toast.error("Failed to fetch cafe profile information");
    } finally {
      setLoading(false);
    }
  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    const offerLink = `${window.location.origin}/cafe/offer/${offer.id}`;
    recordAnalyticsEvent("offer_click", "CafeProfile", {
      offer_id: offer.id,
      offer_title: offer.title,
      offer_type: offer.type,
      link: offerLink,
    });
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index); // Update to set the image index
    const imageLink = `${window.location.origin}/cafe/gallery/${index}`;
    recordAnalyticsEvent("gallery_image_click", "CafeProfile", {
      cafe_id: cafeDetails?._id || "unknown",
      image_index: index,
      componentName: "CafeProfile",
      link: imageLink,
    });
  };

  useEffect(() => {}, [selectedOffer]);

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }
  console.log("reviewstats:", cafeDetails);
  return (
    <div className="container mx-auto">
      <div className="relative w-full h-[233px]">
        <img
          src={`data:image/webp;base64,${cafeDetails.image}`}
          alt="Banner"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-30 h-full w-full"></div>
        <div className="absolute font-[SF Pro Display] font-medium text-2xl md:text-3xl lg:text-4xl text-white absolute top-[166px] left-5 w-4/5">
          {cafeDetails.name}
        </div>
        <div className="flex flex-row justify-between absolute top-5 bottom-0 left-0 right-0 px-4">
          <NavLink to={`/cafe/all-offers`}>
            <div className="bg-[#FFFFFF] bg-opacity-50 h-10 w-10 flex items-center justify-center rounded-lg">
              <img
                src={leftarrow}
                alt="Back"
              />
            </div>
          </NavLink>
          <ShareButton cafeId={cafeId} />
        </div>
      </div>
      <div className="sticky top-0 h-[40px] bg-white flex items-center z-50 px-5">
        <nav className="overflow-x-auto w-full z-1000 ">
          <ul className="flex flex-row gap-2 items-center text-black w-full">
            {[
              "About",
              "Menu",
              "Social",
              "OrderOnline",
              "Offer",
              "Gallery",
              "Reviews",
              "Contact",
            ].map((item) => (
              <li key={item}>
                <Link
                  to={item}
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-40}
                  activeClass="text-purple-500"
                  className="cursor-pointer transition-colors duration-200 text-black text-xs font-bold"
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <section
        id="About"
        className=" "
      >
        <div className="px-4 max-w-lg mx-auto">
          <section className="flex justify-between items-center mb-5">
            <div className="flex items-center">
              <FaClock size={30} />
              <div className="ml-3">
                <span className="block text-xs text-gray-500">Currently</span>
                <span className="block text-xl font-bold">
                  {cafeopenstatus === true ? "Open" : "Closed"}
                </span>
              </div>
            </div>
            <div className="flex items-center mr-8">
              <FaThumbsUp size={30} />
              <div className="ml-3">
                {reviewStats.totalReviews > 0 && (
                  <StarRating
                    rating={reviewStats.averageRating}
                    count={reviewStats.totalReviews}
                  />
                )}
                {
                  <div className="text-2xl  font-bold mb-2">
                    {reviewStats.totalReviews > 0
                      ? `${reviewStats.averageRating} (${
                          reviewStats.totalReviews
                        })`
                      : "No Reviews"}
                  </div>
                }
              </div>
            </div>
          </section>
          <section className="mb-5">
            <p>{cafeDetails.description}</p>
          </section>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Menu"
        className=" menu px-4 mb-4"
      >
        <div className="font-bold text-lg mb-8">Menu</div>
        <NavLink
          to={`/cafe/menu`}
          className="w-full mt-4 flex justify-center items-center h-12 bg-purple-500 text-white rounded-lg font-bold"
          style={{ boxShadow: "2px 3px 0px 0px #000000" }}
        >
          View Menu
        </NavLink>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Social"
        className="social px-5 mb-4"
      >
        {(cafeDetails.website ||
          cafeDetails.instagram_link ||
          cafeDetails.facebook_link) && (
          <>
            <div className="font-bold text-lg mb-8">Social</div>
            <div className="flex flex-col mt-4 space-y-4">
              {cafeDetails.website && (
                <div className="flex items-center justify-between">
                  <NavLink
                    to={cafeDetails.website}
                    className="flex flex-row gap-2 cursor-pointer"
                  >
                    <img
                      src={globe}
                      alt="Globe"
                    />
                    <div className="w-full text-black hover:underline">
                      {cafeDetails.website}
                    </div>
                  </NavLink>
                </div>
              )}

              {cafeDetails.instagram_link && (
                <NavLink
                  to={cafeDetails.instagram_link}
                  className="flex flex-row gap-2 cursor-pointer"
                >
                  <img
                    src={insta}
                    alt="Instagram"
                  />
                  <div className="w-full text-black hover:underline">
                    {cafeDetails.instagram_link}
                  </div>
                </NavLink>
              )}

              {cafeDetails.facebook_link && (
                <NavLink
                  to={cafeDetails.facebook_link}
                  className="flex flex-row gap-2"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                  />
                  <div className="w-full text-black hover:underline">
                    {cafeDetails.facebook_link}
                  </div>
                </NavLink>
              )}
            </div>
          </>
        )}

        {Object.keys(cafeDetails.other_social_links).length > 0 && (
          <>
            <div className="font-bold text-lg mt-8">Other Social links</div>
            <div className="flex flex-col mt-4 space-y-4">
              {Object.entries(cafeDetails.other_social_links).map(
                ([key, value]) => (
                  <NavLink
                    key={key}
                    to={value}
                    className="w-full flex flex-row gap-2 cursor-pointer"
                  >
                    <div className="text-black capitalize font-bold">{key}</div>
                    <div className="text-black hover:underline">{value}</div>
                  </NavLink>
                )
              )}
            </div>
          </>
        )}
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="OrderOnline"
        className=" social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Order Online</div>
        <div className="flex items-center justify-between  mt-4 gap-x-4">
          <NavLink
            to={cafeDetails.swiggy_link}
            className="flex-grow"
          >
            <button className="w-full h-10 flex items-center justify-center rounded-lg  shadow-md">
              <img
                src={swiggy}
                className="w-20 h-20 object-contain"
                alt="Swiggy"
              />
            </button>
          </NavLink>

          <NavLink
            className="flex-grow"
            to={`tel:${cafeDetails.phone}`}
          >
            <button
              className="w-full h-10 flex items-center justify-center bg-purple-500 rounded-lg text-white shadow-md"
              style={{ boxShadow: "2px 3px 0px 0px #000000" }}
            >
              Call Us
            </button>
          </NavLink>

          <NavLink
            to={cafeDetails.zomato_link}
            className="flex-grow"
          >
            <button className="w-full h-10 flex items-center justify-center rounded-lg  shadow-md">
              <img
                src={zomato}
                className="w-20 h-20 object-contain"
                alt="Zomato"
              />
            </button>
          </NavLink>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />
      <section
        id="Offer"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Offer</div>
        <div className="mb-6">
          {offers.length > 0 ? (
            <div className="flex overflow-x-auto gap-4 pb-4">
              <div className="flex gap-4 flex-nowrap">
                {offers.map((offer, index) => (
                  <div
                    key={index}
                    className="flex-shrink-0 object-contain"
                    onClick={() => handleOfferClick(offer)}
                  >
                    {offer.type === "card" ? (
                      <div
                        key={offer.id}
                        className="w-full"
                      >
                        <Offercard
                          offer={offer}
                          cafeName={cafeDetails.name}
                        />
                      </div>
                    ) : (
                      <div className="border-2 py-2 w-[320px] h-auto border-white px-2 pt-1 rounded-lg shadow-lg">
                        <div className="w-full rounded-lg shadow-md overflow-hidden">
                          <img
                            src={
                              offer.posterImage
                                ? `data:image/webp;base64,${offer.posterImage}`
                                : `${offer.imageUri}`
                            }
                            alt={`Offer ${index + 1}`}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="flex items-center justify-center gap-x-6">
                          {offer.addToSwiggy === "true" && (
                            <img
                              src={swiggy}
                              alt="Swiggy"
                              className="w-10 h-24"
                            />
                          )}
                          {offer.addToZomato === "true" && (
                            <img
                              src={zomato}
                              alt="Zomato"
                              className="w-24 h-24"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>No active offers at the moment.</p>
          )}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Gallery"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Gallery</div>
        <div className="mb-6">
          {galleryImages.length > 0 ? (
            <div className="flex overflow-x-auto rounded-lg gap-4 pb-4">
              <div className="flex gap-4 flex-nowrap">
                {galleryImages.map((image, index) => (
                  <img
                    key={index}
                    src={`data:image/webp;base64,${image}`}
                    alt={`Gallery image ${index + 1}`}
                    className="w-20 h-20 object-cover rounded flex-shrink-0 cursor-pointer"
                    onClick={() => handleImageClick(index)} // Image click sets index
                  />
                ))}
              </div>
            </div>
          ) : (
            <p>No gallery images available.</p>
          )}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Reviews"
        className=" social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Reviews</div>
        {/* <RatingCard
          rating={reviewStats.averageRating}
          count={reviewStats.totalReviews}
        /> */}
        <div className="overflow-x-auto whitespace-nowrap">
          {reviewStats &&
            reviewStats?.questionStats?.map((question) => (
              <div
                key={question.question_id}
                className="rounded-lg p-2 inline-block w-[80%]"
              >
                <RatingCard
                  rating={question.average_rating}
                  count={question.number_of_ratings}
                  question={question.question_text}
                />
              </div>
            ))}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Contact"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Contact</div>
        <Contact cafeDetails={cafeDetails} />
      </section>

      {selectedOffer && (
        <OfferPopup
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
          }}
          cafeName={cafeDetails.name}
        />
      )}

      {selectedImageIndex !== null && (
        <GalleryPopup
          images={galleryImages} // Array of images in base64 format
          currentIndex={selectedImageIndex} // Index of the clicked image
          onClose={() => setSelectedImageIndex(null)} // Close popup
        />
      )}

      <ToastContainer />
      <div className="mt-[100px]">
        {" "}
        <BottomMenu />
      </div>
    </div>
  );
};

export default CafeProfile;
