import React, { useState, useEffect } from 'react'
import { StarFilled, StarOutlined } from '@ant-design/icons'

const StarRating = ({ rating = 5, count = 1, size = "small", details }) => {

  const [star, setStar] = useState(rating)
  const [showHalf, setShowHalf] = useState(false)

  function setRating() {
    
    if (rating === 1 || rating === 2 || rating === 3 || rating === 4 || rating === 5) {
      setStar(rating)
      console.log("first")
    }
    else if (rating > 1 && rating < 2) {
      setStar(1)
      setShowHalf(true)
    }
    else if (rating > 2 && rating < 3) {
      setStar(2)
      setShowHalf(true)
    }
    else if (rating > 3 && rating < 4) {
      setStar(3)
      setShowHalf(true)
    }
    else if (rating > 4 && rating < 5) {
      setStar(4)
      setShowHalf(true)
    }
  }

  useEffect(() => {
    setRating()
  }, [rating])

  if (size === 'small') {
    return (
      <div className='flex my-[5px] items-center'>
       
        <div className="flex items-center">
          {
            Array.from({ length: star }).map((_, index) => {
              return <StarFilled key={index} className='pl-1 text-xs text-yellow-400' />
            })
          }
          {
            showHalf && <img height={16} width={16} className='pl-1' alt='.5' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4ElEQVR4nL2WS2sUQRSF29dGXCX3zhhcqKioiJoguJGQhVvBB2QjCcYI/gIXgWwEf4TgQlSMi2AiLsWFooJm6lYC5oExcWFMYpJJxjCZR9/q7iqpjjKTx4Tp1syBhoGqOV+drnury3FiypCzxz5OrcUCbrGEzppCTa+zSxHOKoKf5o2zu2ZglniTBWbV+LUCE3bUMu2MIjR6ZcDULDUTdoRpCY2VGr9qU9+oRdppC/0L1tkP9vfstqbmFLazgJVy8GrqKwWW2L59aUUpbTlYZ98bJXBmW1KzTLQxldKWg8PUXy4XWNa3xQYYatjLg3iWCVuVhG5F8FwJGGOBXA5dD9bZdyacI2As/I+EbuthvaznxiSEHYrqHzLBABMuMoHHBMuKMOMSqPWwSuBQXtrolY8mSPcYf/pe4E12ut5YS94dPOCzwIwSOOgS9IS9zylocgXkWKDeClIVuKICo3nK+N/vGLasFDSFqV1Zd9ImjAqPoiDzwijZsMwycWbNK3ep/oQiXHIFBv8bHCz2aUUNv5gSpzctLDdVf5wJl7hKeDXy0z1aUTLDA8lTW1Z1cSh5SBHMs0D/X8H+whOtZCLtDsKxqlqqIPcftMegrfC4YH/hkVaUWCgSHqkKWp6cBczFAQdL/ban56yHE0eKsDcWOP3UjvfGglopgV/jgHVO2PFxJ46McXYwoRtrj/2sfdWu9YgMLn5KHmaBubjF5X1uzMfaY4/gEhMuV4LadvPm7msTuJuDJ67nrEdksCLsclO44SPx51jNs4DXTPDKG27M+fMPtNG8Fjx1154DXdHBAvo3QAnyimBIETSX5tWddwW+9YbP5YP0Y2O0v1rZi8/s/L7IYCb4VkoJOUUw4snExcoLxQtKovRGm3NB5qXROWlvoJORoMY4Ozn8FocJJ6PslZ2rBEyo0ZaC9bBeVYOLEo+ygB9Kwm1714q06lIrttpFRzoyzQju+x8XN+thvTYb/A3QNyuYS3aqaQAAAABJRU5ErkJggg==" />
          }
          {
            Array.from({ length: showHalf ? 4 - star : 5 - star }).map((_, index) => {
              return <StarOutlined key={index} className='pl-1 text-xs text-yellow-400' />
            })
          }
          {
            // count && <p className="text-xs ml-2">({count} Reviews)</p>
          }
        </div>
      </div>
    )
  }
  else if (size === 'big') {
    return (
      <div className='flex flex-col my-1 items-center'>
        <div className='flex items-center'>
          <img src="../../Icon.svg" alt="OG" className='mr-1' />
          <div className="flex items-center">
            {
              Array.from({ length: star }).map((_, index) => {
                return <StarFilled className='pl-1 text-base text-yellow-400' />
              })
            }
            {
              showHalf && <img height={20} width={20} className='pl-1' alt='.5' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4ElEQVR4nL2WS2sUQRSF29dGXCX3zhhcqKioiJoguJGQhVvBB2QjCcYI/gIXgWwEf4TgQlSMi2AiLsWFooJm6lYC5oExcWFMYpJJxjCZR9/q7iqpjjKTx4Tp1syBhoGqOV+drnury3FiypCzxz5OrcUCbrGEzppCTa+zSxHOKoKf5o2zu2ZglniTBWbV+LUCE3bUMu2MIjR6ZcDULDUTdoRpCY2VGr9qU9+oRdppC/0L1tkP9vfstqbmFLazgJVy8GrqKwWW2L59aUUpbTlYZ98bJXBmW1KzTLQxldKWg8PUXy4XWNa3xQYYatjLg3iWCVuVhG5F8FwJGGOBXA5dD9bZdyacI2As/I+EbuthvaznxiSEHYrqHzLBABMuMoHHBMuKMOMSqPWwSuBQXtrolY8mSPcYf/pe4E12ut5YS94dPOCzwIwSOOgS9IS9zylocgXkWKDeClIVuKICo3nK+N/vGLasFDSFqV1Zd9ImjAqPoiDzwijZsMwycWbNK3ep/oQiXHIFBv8bHCz2aUUNv5gSpzctLDdVf5wJl7hKeDXy0z1aUTLDA8lTW1Z1cSh5SBHMs0D/X8H+whOtZCLtDsKxqlqqIPcftMegrfC4YH/hkVaUWCgSHqkKWp6cBczFAQdL/ban56yHE0eKsDcWOP3UjvfGglopgV/jgHVO2PFxJ46McXYwoRtrj/2sfdWu9YgMLn5KHmaBubjF5X1uzMfaY4/gEhMuV4LadvPm7msTuJuDJ67nrEdksCLsclO44SPx51jNs4DXTPDKG27M+fMPtNG8Fjx1154DXdHBAvo3QAnyimBIETSX5tWddwW+9YbP5YP0Y2O0v1rZi8/s/L7IYCb4VkoJOUUw4snExcoLxQtKovRGm3NB5qXROWlvoJORoMY4Ozn8FocJJ6PslZ2rBEyo0ZaC9bBeVYOLEo+ygB9Kwm1714q06lIrttpFRzoyzQju+x8XN+thvTYb/A3QNyuYS3aqaQAAAABJRU5ErkJggg==" />
            }
            {
              Array.from({ length: showHalf ? 4 - star : 5 - star }).map((_, index) => {
                return <StarOutlined className='pl-1 text-xs text-yellow-400' />
              })
            }
          </div>
          {
          count && <p className="text-xs ml-2 mt-1 text-white  ">({count} Reviews)</p>
        }
        </div>
        
      </div>
    )
  }
}

export default StarRating