import React, { useEffect } from "react";
import Offercard from "./offerCard";
import swiggy from "../../pages/assets/Group 18291.svg";
import zomato from "../../pages/assets/image 52.svg";
import { GiCancel } from "react-icons/gi";

const OfferPopup = ({ offer, onClose, cafeName }) => {
  useEffect(() => {}, [offer]);

  if (!offer) {
    return null;
  }

  // Parse the offerData JSON string
  let parsedOfferData = {};
  try {
    parsedOfferData = JSON.parse(offer.offerData || offer);
  } catch (error) {
    console.error("Error parsing offerData:", error);
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold mb-4">
            {parsedOfferData?.name || "Offer"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <GiCancel size={20} />
          </button>
        </div>
        <p className="text-gray-700 mb-4">{parsedOfferData.description}</p>
        {offer.type === "poster" && offer.posterImage && (
          <img
            src={`data:image/webp;base64,${offer.posterImage}`}
            alt={parsedOfferData.name || "Offer Image"}
            className="w-full py-4 rounded-lg object-contain h-60"
          />
        )}
        {offer.type === "poster" && (
          <div className="flex items-center justify-center gap-x-6">
            {offer.addToSwiggy === "true" && (
              <img
                src={swiggy}
                alt="Swiggy"
                className="w-24 h-24"
              />
            )}
            {offer.addToZomato === "true" && (
              <img
                src={zomato}
                alt="Zomato"
                className="w-24 h-24"
              />
            )}
          </div>
        )}

        {offer.type === "card" && (
          <div className="mb-4">
            <Offercard
              offer={offer}
              cafeName={cafeName}
            />
          </div>
        )}
        {parsedOfferData.discountType && parsedOfferData.discountValue && (
          <p className="text-lg font-semibold mb-2">
            Discount: {parsedOfferData.discountValue}
            {parsedOfferData.discountType === "percentage" ? "%" : ""}
          </p>
        )}
        {parsedOfferData.expirationDate && (
          <p className="text-sm text-gray-600 mb-2">
            Expires on:{" "}
            {new Date(parsedOfferData.expirationDate).toLocaleDateString()}
          </p>
        )}
        {parsedOfferData.terms && (
          <p className="text-sm text-gray-600 mb-4">
            Terms: {parsedOfferData.terms}
          </p>
        )}
      </div>
    </div>
  );
};

export default OfferPopup;
