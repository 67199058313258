import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { sendAlert, fetchAlert } from "../../api/cafe";
import UniversalInput from "../../components/Universalnput"

const SendNotification = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [url, setUrl] = useState("");

  const [alerts, setAlerts] = useState([]);

  const getAllAlerts = async () => {
    const response = await fetchAlert();
    console.log("response :" ,response.data);
    response.data.map((alert) => {
      setAlerts((prevState) => [
        ...prevState,
        {
          title: alert.title,
          body: alert.body,
          url: alert?.url,
        },
      ]);
    });
  };

  useEffect(() => {
    getAllAlerts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const alertData = {
      title,
      body,
      url,
    };
    try {
      const response = await sendAlert(alertData);
      console.log("Send notification response:", response);
      setAlerts((prevState) => [...prevState, alertData]);
      if (response && !response.error) {
        toast.success("Notification sent successfully");
        // Clear form fields
        setTitle("");
        setBody("");
        setUrl("");
      } else {
        toast.error(`Failed to send notification: ${response.error}`);
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("An error occurred while sending the notification");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-5">Send Push Notification</h2>
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
      >
        <div>
          <label
            htmlFor="title"
            className="block mb-1"
          >
            Notification Title:
          </label>
          <UniversalInput
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label
            htmlFor="body"
            className="block mb-1"
          >
            Notification Body:
          </label>
          <textarea
            id="body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label
            htmlFor="url"
            className="block mb-1"
          >
            URL (optional):
          </label>
          <UniversalInput
            type="url"
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          Send Notification
        </button>
      </form>
      <div>
        <h3 className="text-xl font-bold mt-5">Sent Notifications</h3>
        <ul className="space-y-2 mt-2">
          {alerts.map((alert, index) => (
            <li
              key={index}
              className="border p-2 rounded"
            >
              <h4 className="font-bold">{alert.title}</h4>
              <p>{alert.body}</p>
              {alert.url && (
                <a
                  href={alert.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {alert.url}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SendNotification;
