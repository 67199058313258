import axios from "axios";

const posUrl = process.env.REACT_APP_BASE_URL + "/api/pos";
const cafeUrl = process.env.REACT_APP_BASE_URL + "/api/cafe";
const AdminUrl = process.env.REACT_APP_BASE_URL + "/api";

const getToken = () => localStorage.getItem("OG_token");

export const fetchPOSData = async () => {
  try {
    const token = getToken();
    const [posResponse, cafeItemsResponse] = await Promise.all([
      axios.get(`${posUrl}/data`, {
        headers: { "x-auth-token": token },
      }),
      axios.get(`${cafeUrl}/pos/menu`, {
        headers: { "x-auth-token": token },
      }),
    ]);

    return {
      ...posResponse.data,
      menuItems: cafeItemsResponse.data.data,
    };
  } catch (error) {
    console.error("Error fetching POS data:", error);
    throw error;
  }
};
export const addInventoryItem = async (itemData) => {
  try {
    const token = getToken();
    const response = await axios.post(`${posUrl}/inventory`, itemData, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error adding inventory item:", error);
    throw error;
  }
};

export const updateInventoryItem = async (id, itemData) => {
  try {
    const token = getToken();
    const response = await axios.put(`${posUrl}/inventory/${id}`, itemData, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating inventory item:", error);
    throw error;
  }
};

export const getInventoryItems = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${posUrl}/inventory`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching inventory items:", error);
    throw error;
  }
};

export const mapInventoryToMenuItem = async (itemId, inventoryMappings) => {
  try {
    const token = getToken();
    const response = await axios.post(`${posUrl}/menu-item/map-inventory`, {
      itemId,
      inventoryMappings
    }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error mapping inventory to menu item:", error);
    throw error;
  }
};

export const getMenuItemInventoryMapping = async (itemId) => {
  try {
    const token = getToken();
    const response = await axios.get(`${posUrl}/menu-item/${itemId}/inventory-mapping`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching menu item inventory mapping:", error);
    throw error;
  }
};

export const getKOTs = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${posUrl}/kot`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching KOTs:", error);
    throw error;
  }
};

export const generateBill = async (billData) => {
  try {
    const token = getToken();
    const response = await axios.post(`${posUrl}/bill`, billData, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error generating bill:", error);
    throw error;
  }
};

export const updateBillPaymentStatus = async (id, paymentStatus) => {
  try {
    const token = getToken();
    const response = await axios.put(`${posUrl}/bill/${id}/payment-status`, { paymentStatus }, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating bill payment status:", error);
    throw error;
  }
};

export const getBills = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${posUrl}/bills`, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching bills:", error);
    throw error;
  }
};


  export const fetchPOSMenuItems = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${cafeUrl}/pos/menu`, {
        headers: {
          "x-auth-token": token,
        },
      });
      return response.data.data; // Assuming the menu items are in the 'data' field of the response
    } catch (error) {
      console.error("Error fetching POS menu items:", error);
      throw error;
    }
  };

  // api/pos.js
export const createUserBag = async (userData) => {
  try {
    const token = getToken();
    const response = await axios.post(`${posUrl}/user-bag`, {
      userName: userData.userName,
      userPhone: userData.userPhone,
      orderType: userData.orderType,
      tableNumber: userData.tableNumber // Add table number
    }, {
      headers: { "x-auth-token": token },
    });
    
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to create user bag');
    }
  } catch (error) {
    console.error("Error creating user bag:", error);
    throw error;
  }
};
  export const createKOT = async (kotData) => {
    try {
      const token = getToken();
      const response = await axios.post(`${posUrl}/kot`, kotData, {
        headers: {
          "x-auth-token": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating KOT:", error);
      throw error;
    }
  };
  export const fetchKOTs = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${posUrl}/kot`, {
        headers: {
          "x-auth-token": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching KOTs:", error);
      throw error;
    }
  };
  export const updateKOTStatus = async ({ kotId, status }) => {
    try {
      console.log('Updating KOT with:', { kotId, status }); // Debug log
      
      if (!kotId || typeof kotId !== 'string') {
        throw new Error('Invalid KOT ID');
      }
  
      const token = getToken();
      const response = await axios.put(
        `${posUrl}/kot/${kotId}/status`,
        { status },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      
      return response.data;
    } catch (error) {
      console.error("Error updating KOT status:", error);
      throw error;
    }
  };


  // export const fetchOpenBags = async () => {
  //   try {
  //     const token = getToken();
  //     const response = await axios.get(`${posUrl}/user-bag/open`, {
  //       headers: {
  //         "x-auth-token": token,
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error fetching open bags:", error);
  //     throw error;
  //   }
  // };
  
  export const addItemToBag = async ({ bagId, itemId, quantity, notes }) => {
    try {
      const token = getToken();
      const response = await axios.post(`${posUrl}/user-bag/${bagId}/add-item`, {
        itemId,
        quantity,
        notes
      }, {
        headers: {
          "x-auth-token": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding item to bag:", error);
      throw error;
    }
  };
  
  export const fetchActiveBags = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${posUrl}/active-bags`, {
        headers: { "x-auth-token": token },
      });
      console.log('Raw API response:', response.data);
      return response.data; // Return the entire response object
    } catch (error) {
      console.error("Error fetching active bags:", error);
      throw error;
    }
  };
  export const fetchBag = async (bagId) => {
    try {
      const token = getToken();
      const response = await axios.get(`${posUrl}/bag/${bagId}`, {
        headers: { "x-auth-token": token },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching bag:", error);
      throw error;
    }
  };
  
  export const updateBag = async ({ bagId, ...bagData }) => {
    try {
      const token = getToken();
      const response = await axios.put(`${posUrl}/bag/${bagId}`, bagData, {
        headers: { "x-auth-token": token },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating bag:", error);
      throw error;
    }
  };
  
  export const closeBag = async (bagId) => {
    try {
      const token = getToken();
      const response = await axios.post(
        `${posUrl}/user-bag/${bagId}/close`,
        {},
        {
          headers: { "x-auth-token": token },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error closing bag:", error);
      throw error;
    }
  };

  export const getBagItems = async (bagId) => {
    try {
      const token = getToken();
      const response = await axios.get(`${posUrl}/user-bag/${bagId}`, {
        headers: { "x-auth-token": token },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching bag items:", error);
      throw error;
    }
  };
  
  export const addItemsToBag = async (bagId, items) => {
    try {
      const token = getToken();
      const response = await axios.put(
        `${posUrl}/user-bag/${bagId}/items`,
        { items },
        {
          headers: { "x-auth-token": token },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error adding items to bag:", error);
      throw error;
    }
  };
  
  export const createKOTFromBagItems = async (bagId, items) => {
    try {
      const token = getToken();
      const response = await axios.post(
        `${posUrl}/user-bag/${bagId}/kot`,
        { items },
        {
          headers: { "x-auth-token": token },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating KOT:", error);
      throw error;
    }
  };
  
  export const createUserBagDiner = async (userData) => {
    try {
      console.log('Sending user bag data:', userData);
      const response = await axios.post(`${posUrl}/public/user-bag`, {
        userName: userData.userName,
        userPhone: userData.userPhone,
        orderType: userData.orderType,
        cafeId: userData.cafeId,
        tableNumber: userData.tableNumber // Add table number
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data.success) {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Failed to create user bag');
      }
    } catch (error) {
      console.error("Error creating user bag:", error);
      throw error;
    }
  };

  export const createKOTFromBagItemsDiner = async (bagId, items) => {
    try {
      console.log('Creating KOT for bag:', bagId, 'with items:', items); // Debug log
      const response = await axios.post(
        `${posUrl}/public/user-bag/${bagId}/kot`,
        { 
          bagId,  // Explicitly include bagId in request body
          items 
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.success) {
        return response.data.data;
      } else {
        throw new Error(response.data.message || 'Failed to create KOT');
      }
    } catch (error) {
      console.error("Error creating KOT:", error);
      throw error;
    }
    
  };

  export const getCafeDetails = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${AdminUrl}/admin`, {
        headers: { "x-auth-token": token },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching cafe details:", error);
      throw error;
    }
  };
  