import React, { useEffect, useState } from "react";
import { FaInstagram, FaFacebook, FaGlobe, FaClipboard } from "react-icons/fa";
import UploadButton from "../../components/UploadButton";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BottomMenu from "../../components/BottomMenu";
import {
  getOperatingHours,
  updateOperatingHours,
  getCafe,
  updateCafeDetails,
  updateImageBanner,
  uploadGalleryImages,
  updateFssaiCertificate,
  updateAddress,
  getGalleryImages,
  deleteGalleryImage,
  getFssaiCertificate,
  getUsername,
  updateUsername,
  checkUsername
} from "../../api/cafe";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFacilities,
  addFacility,
  updateFacility,
  deleteFacility,
} from "../../api/cafe";

import LoadingOverlay from "../../components/LoadingOverlay";
import Header from "../../components/Header";

const SettingsNav = ({ activeSection, setActiveSection }) => {
  const sections = [
    { id: "basic", label: "Basic details" },
    { id: "gallery", label: "Gallery" },
    { id: "qr", label: "QR Link" },
    { id: "verification", label: "Verification documents" },
    // { id: "facilities", label: "Facilities" },
  ];

  return (
    <nav className="flex justify-between space-x-4 border-b border-gray-200 mb-6">
      {sections.map((section) => (
        <button
          key={section.id}
          onClick={() => setActiveSection(section.id)}
          className={`py-2 px-1 font-medium text-sm focus:outline-none ${activeSection === section.id
            ? "text-purple-600 border-b-2 border-purple-600"
            : "text-gray-500 hover:text-gray-700"
            }`}
        >
          {section.label}
        </button>
      ))}
    </nav>
  );
};

const Settings = () => {
  const cafeData = useSelector((state) => state.auth.cafe);
  const navigate = useNavigate();

  useEffect(() => {
    getCafe().catch(() => {
      localStorage.removeItem("OG_token");
      navigate("/cafe-admin/login");
    });
  }, [navigate]);

  useEffect(() => {
    if (cafeData && cafeData.image) {
      setProfileCoverPhoto(cafeData.image);
    }
  }, [cafeData]);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(() => {
    localStorage.removeItem("formData");
    const storedFormData = localStorage.getItem("formData");
    return storedFormData
      ? JSON.parse(storedFormData)
      : {
        description: cafeData.description || "",
        website: cafeData.website || "",
        instagram_link: cafeData.instagram_link || "",
        facebook_link: cafeData.facebook_link || "",
        zomato_link: cafeData.zomato_link || "",
        swiggy_link: cafeData.swiggy_link || "",
        other_social_links: cafeData.other_social_links || {},
        logo: cafeData.logo || null,
      };
  });

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const [operatingHours, setOperatingHours] = useState({
    monday: [{ open: "09:00", close: "21:00", is_open: true }],
    tuesday: [{ open: "09:00", close: "21:00", is_open: true }],
    wednesday: [{ open: "09:00", close: "21:00", is_open: true }],
    thursday: [{ open: "09:00", close: "21:00", is_open: true }],
    friday: [{ open: "09:00", close: "21:00", is_open: true }],
    saturday: [{ open: "09:00", close: "21:00", is_open: true }],
    sunday: [{ open: "09:00", close: "21:00", is_open: true }],
  });

  useEffect(() => {
    const fetchOperatingHours = async () => {
      try {
        const response = await getOperatingHours();
        if (response.success) {
          setOperatingHours(response.data);
        } else {
          console.error("Failed to fetch operating hours:", response.message);
        }
      } catch (error) {
        console.error("Error fetching operating hours:", error);
      }
    };

    fetchOperatingHours();
  }, []);
  const [activeSection, setActiveSection] = useState("basic");
  const [profileCoverPhoto, setProfileCoverPhoto] = useState(null);
  const [cafePictures, setCafePictures] = useState([]);
  const [newCafePictures, setNewCafePictures] = useState([]);
  const [image, setImage] = useState(null);
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    const fetchFacilities = async () => {
      const response = await getFacilities();
      if (response.success) {
        setFacilities(response.data);
      }
    };
    fetchFacilities();
  }, []);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      if (activeSection === "basic") {
        const { username, ...formDataWithoutUsername } = formData;
        console.log({ formDataWithoutUsername });
        const response = await updateCafeDetails(formDataWithoutUsername);
        if (response.success) {
          console.log("Cafe details updated successfully");
          toast.success("Changes updated successfully");
        } else {
          console.error("Failed to update cafe details:", response.message);
          toast.success("Failed to update changes");
        }
      }

      if (activeSection === "gallery") {
        if (profileCoverPhoto !== cafeData.image) {
          try {
            const response = await updateImageBanner(profileCoverPhoto);
            if (response.success) {
              toast.success("Profile cover photo updated");
            }
          } catch (error) {
            toast.error("Failed to update profile cover photo");
            console.error("Error during updating banner:", error);
          }
        }

        if (newCafePictures.length > 0) {
          try {
            const response = await uploadGalleryImages(newCafePictures);
            console.log("Response updating gallery images: ", response);
            if (response.success) {
              const galleryImages = await getGalleryImages();
              setCafePictures(() => galleryImages.data);
              setNewCafePictures([]);
              toast.success("New gallery images uploaded successfully");
            }
          } catch (error) {
            toast.error("Failed to upload new gallery images");
            console.error("Error during updating banner:", error);
          }
        }
      }

      if (activeSection === "verification" && image) {
        console.log({ image });
        const response = await updateFssaiCertificate(image);
        if (response.success) {
          console.log("Fssai certificate updated");
        } else {
          console.error(
            "Failed to update fssai certificate:",
            response.message
          );
        }
      }
    } catch (error) {
      console.error("Error during update:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case "basic":
        return (
          <BasicDetails
            formData={formData}
            setFormData={setFormData}
            operatingHours={operatingHours}
            setOperatingHours={setOperatingHours}
            cafeData={cafeData}
          />
        );
      case "gallery":
        return (
          <Gallery
            profileCoverPhoto={profileCoverPhoto}
            setProfileCoverPhoto={setProfileCoverPhoto}
            cafePictures={cafePictures}
            setCafePictures={setCafePictures}
            newCafePictures={newCafePictures}
            setNewCafePictures={setNewCafePictures}
          />
        );
      case "qr":
        return <QRLink />;
      case "verification":
        return (
          <VerificationDocuments
            document={image}
            setDocument={setImage}
          />
        );
      case "facilities":
        return (
          <Facilities
            facilities={facilities}
            setFacilities={setFacilities}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <Header />
        <div className="flex items-center justify-between mt-2 mb-4">
          <h1 className="text-3xl font-bold">Settings</h1>
          <button
            onClick={handleUpdate}
            className="text-2xl px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]"
          >
            Save Changes
          </button>
        </div>
        <SettingsNav
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
        {renderContent()}
        <BottomMenu page="settings" />
        <LoadingOverlay
          title="Updating details......"
          show={loading}
        />
      </div>
      <ToastContainer />
    </>
  );
};

const BasicDetails = ({ formData, setFormData, cafeData }) => {
  const [operatingHours, setOperatingHours] = useState({
    monday: [{ open: "09:00", close: "21:00", is_open: true }],
    tuesday: [{ open: "09:00", close: "21:00", is_open: true }],
    wednesday: [{ open: "09:00", close: "21:00", is_open: true }],
    thursday: [{ open: "09:00", close: "21:00", is_open: true }],
    friday: [{ open: "09:00", close: "21:00", is_open: true }],
    saturday: [{ open: "09:00", close: "21:00", is_open: true }],
    sunday: [{ open: "09:00", close: "21:00", is_open: true }],
  });
  const [username, setUsername] = useState('');
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await getUsername();
        if (response.success && response.data.username) {
          setUsername(response.data.username);
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };
    fetchUsername();
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setUsernameError('');
  };

  const handleUsernameSubmit = async () => {
    if (!username.trim()) {
      setUsernameError('Username cannot be empty');
      return;
    }

    try {
      const availabilityResponse = await checkUsername(username);
      if (!availabilityResponse.data.isAvailable) {
        setUsernameError('This username is already taken');
        return;
      }

      const response = await updateUsername(username);
      if (response.success) {
        setIsEditingUsername(false);
        toast.success('Username updated successfully');
      } else {
        setUsernameError('Failed to update username');
      }
    } catch (error) {
      console.error('Error updating username:', error);
      setUsernameError('An error occurred while updating username');
    }
  };
  useEffect(() => {
    const fetchOperatingHours = async () => {
      try {
        const response = await getOperatingHours(cafeData._id);
        if (response.success) {
          setOperatingHours(response.data);
        } else {
          console.error("Failed to fetch operating hours:", response.message);
        }
      } catch (error) {
        console.error("Error fetching operating hours:", error);
      }
    };

    fetchOperatingHours();
  }, [cafeData._id]);

  const handleOperatingHoursChange = async (day, index, field, value) => {
    const updatedHours = {
      ...operatingHours,
      [day]: operatingHours[day].map((time, i) =>
        i === index ? { ...time, [field]: value } : time
      ),
    };

    setOperatingHours(updatedHours);

    try {
      const response = await updateOperatingHours(updatedHours);
      if (response.success) {
        console.log(`Operating hours updated successfully for ${day}`);
      } else {
        console.error(
          `Failed to update operating hours for ${day}:`,
          response.message
        );
        // Optionally, revert the state if the update fails
        setOperatingHours(operatingHours);
      }
    } catch (error) {
      console.error(`Error updating operating hours for ${day}:`, error);
      // Optionally, revert the state if the update fails
      setOperatingHours(operatingHours);
    }
  };

  const [addressData, setAddressData] = useState({
    address: cafeData.address || "",
    city: cafeData.city || "",
    state: cafeData.state || "",
    pincode: cafeData.pincode || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddressUpdate = async () => {
    const response = await updateAddress(addressData);
    if (response.success) {
      toast.success("Address updated successfully");
    } else {
      toast.error("Failed to update address");
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormData((prevState) => ({
        ...prevState,
        logo: reader.result,
      }));
    };
  };
  const handleAddSocialLink = () => {
    setFormData((prevState) => ({
      ...prevState,
      other_social_links: {
        ...prevState.other_social_links,
        "": "", // Add an empty key-value pair
      },
    }));
  };

  const handleSocialLinkChange = (oldKey, newKey, value) => {
    setFormData((prevState) => {
      const newSocialLinks = { ...prevState.other_social_links };
      if (oldKey !== newKey) {
        delete newSocialLinks[oldKey];
      }
      newSocialLinks[newKey] = value;
      return { ...prevState, other_social_links: newSocialLinks };
    });
  };

  const handleRemoveSocialLink = (key) => {
    setFormData((prevState) => {
      const newSocialLinks = { ...prevState.other_social_links };
      delete newSocialLinks[key];
      return { ...prevState, other_social_links: newSocialLinks };
    });
  };

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  // console.log("Cafe Data", cafeData);
  return (
    <div className="min-h-screen p-4 mb-20">
      <div className="bg-white rounded-lg shadow-md p-6 max-w-2xl mx-auto">
        <div className="space-y-4">
          <input
            type="text"
            name="cafeName"
            placeholder="Cafe Name"
            value={cafeData.name}
            className="w-full p-2 border rounded"
            readOnly
          />
         <h2 className="font-semibold mt-6">Cafe Username</h2>
          {isEditingUsername ? (
            <div className="flex flex-col mt-2">
              <div className="flex items-center">
                <span className="bg-gray-100 p-2 rounded-l border border-r-0">app.orderg.in/</span>
                <input
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                  className="flex-grow p-2 border rounded-r"
                  placeholder="Enter username"
                />
              </div>
              {usernameError && <p className="text-red-500 text-sm mt-1">{usernameError}</p>}
              <div className="flex mt-2">
                <button
                  onClick={handleUsernameSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setIsEditingUsername(false);
                    setUsernameError('');
                  }}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col mt-2">
              <div className="flex items-center">
                <p className="flex-grow p-2 border rounded-l bg-gray-100">app.orderg.in/cafe/</p>
                <p className="flex-grow p-2 border rounded-r">{username || 'No username set'}</p>
              </div>
              <div className="flex mt-2">
                <button
                  onClick={() => setIsEditingUsername(true)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 mr-2"
                >
                  Edit
                </button>
                <CopyToClipboard
                  text={`app.orderg.in/cafe/${username}`}
                  onCopy={handleCopy}
                >
                  <button className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    <FaClipboard className="mr-2" />
                    Copy Profile URL
                  </button>
                </CopyToClipboard>
              </div>
              {copied && (
                <p className="text-green-500 text-sm mt-1">URL copied to clipboard!</p>
              )}
            </div>
          )}
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded h-24"
          />

          <div className="flex items-center">
            <FaGlobe className="mr-2" />
            <input
              type="text"
              name="website"
              placeholder="Website (if any)"
              value={formData.website}
              onChange={handleInputChange}
              className="flex-grow p-2 border rounded"
            />
          </div>

          <div className="flex items-center">
            <FaInstagram className="mr-2" />
            <input
              type="text"
              name="instagram_link"
              placeholder="Instagram"
              value={formData.instagram_link}
              onChange={handleInputChange}
              className="flex-grow p-2 border rounded"
            />
          </div>

          <div className="flex items-center">
            <FaFacebook className="mr-2" />
            <input
              type="text"
              name="facebook_link"
              placeholder="Facebook"
              value={formData.facebook_link}
              onChange={handleInputChange}
              className="flex-grow p-2 border rounded"
            />
          </div>

          <h2 className="font-semibold mt-6">Online ordering Links</h2>

          <input
            type="text"
            name="zomato_link"
            placeholder="Zomato"
            value={formData.zomato_link}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />

          <input
            type="text"
            name="swiggy_link"
            placeholder="Swiggy"
            value={formData.swiggy_link}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />

          <h2 className="font-semibold">Cafe Logo</h2>
          <div className="flex items-center space-x-4">
            <img
              src={
                formData.logo !== ""
                  ? formData?.logo
                  : cafeData?.logo
              }
              alt="Cafe Logo"
              className="w-20 h-20 object-cover rounded-full"
            />
            <UploadButton
              text="Upload Logo"
              onFileChange={handleLogoChange}
              accept="image/*"
            />
          </div>

          <h2 className="font-semibold mt-6">Other Social Links</h2>
          {Object.entries(formData.other_social_links).map(
            ([key, value], index) => (
              <div
                key={index}
                className="flex items-center space-x-2 mt-2"
              >
                <input
                  type="text"
                  value={key}
                  onChange={(e) =>
                    handleSocialLinkChange(key, e.target.value, value)
                  }
                  placeholder="Platform name"
                  className="w-1/3 p-2 border rounded"
                />
                <input
                  type="text"
                  value={value}
                  onChange={(e) =>
                    handleSocialLinkChange(key, key, e.target.value)
                  }
                  placeholder="Enter social link"
                  className="w-2/3 p-2 border rounded"
                />
                <button
                  onClick={() => handleRemoveSocialLink(key)}
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                >
                  Remove
                </button>
              </div>
            )
          )}
          <button
            onClick={handleAddSocialLink}
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Social Link
          </button>

          <h2 className="font-semibold mt-6">Contact</h2>

          <input
            type="text"
            name="address"
            placeholder="Address"
            value={addressData.address}
            onChange={handleAddressChange}
            className="w-full p-2 border rounded"
          />

          <input
            type="text"
            name="city"
            placeholder="City"
            value={addressData.city}
            onChange={handleAddressChange}
            className="w-full p-2 border rounded"
          />

          <input
            type="text"
            name="state"
            placeholder="State"
            value={addressData.state}
            onChange={handleAddressChange}
            className="w-full p-2 border rounded"
          />

          <input
            type="text"
            name="pincode"
            placeholder="Pincode"
            value={addressData.pincode}
            onChange={handleAddressChange}
            className="w-full p-2 border rounded"
          />

          <button
            onClick={handleAddressUpdate}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Update Address
          </button>

          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={cafeData.phone}
            className="w-full p-2 border rounded"
            readOnly
          />

          <h2 className="font-semibold mt-6">Timing</h2>

          {days.map((day) => (
            <div
              key={day}
              className="mb-2"
            >
              <h3 className="capitalize">{day}</h3>
              {operatingHours[day] &&
                operatingHours[day].map((time, index) => (
                  <div
                    key={index}
                    className="flex items-center space-x-2 mb-1"
                  >
                    <input
                      type="time"
                      value={time.open}
                      onChange={(e) =>
                        handleOperatingHoursChange(
                          day,
                          index,
                          "open",
                          e.target.value
                        )
                      }
                      className="p-2 border rounded"
                    />
                    <span>-</span>
                    <input
                      type="time"
                      value={time.close}
                      onChange={(e) =>
                        handleOperatingHoursChange(
                          day,
                          index,
                          "close",
                          e.target.value
                        )
                      }
                      className="p-2 border rounded"
                    />
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={time.is_open}
                        onChange={(e) =>
                          handleOperatingHoursChange(
                            day,
                            index,
                            "is_open",
                            e.target.checked
                          )
                        }
                        className="mr-2"
                      />
                      Open
                    </label>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Gallery = ({
  profileCoverPhoto,
  setProfileCoverPhoto,
  cafePictures,
  setCafePictures,
  newCafePictures,
  setNewCafePictures,
}) => {
  useEffect(() => {
    const fetchGalleryImages = async () => {
      const response = await getGalleryImages();
      if (response.success) {
        setCafePictures(response.data);
      }
    };
    fetchGalleryImages();
  }, []);

  const supportedFormats = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpg",
    "image/webp",
    "image/jfif",
  ];
  const MAX_FILE_SIZE = 1 * 1024 * 1024;

  const removeExistingImage = async (index) => {
    try {
      const imageToRemove = cafePictures[index];
      const response = await deleteGalleryImage(index);
      if (response.success) {
        setCafePictures(prevPictures => prevPictures.filter((_, i) => i !== index));
        toast.success("Image deleted successfully");
      } else {
        console.error("Failed to delete image", response.message);
        toast.error("Failed to delete image");
      }
    } catch (error) {
      console.error("Error removing image:", error);
      toast.error("An error occurred while removing the image");
    }
  };

  const removeNewImage = (index) => {
    setNewCafePictures(prevPictures => prevPictures.filter((_, i) => i !== index));
  };

  const handleProfileCoverPhotoChange = (event) => {
    const file = event.target.files[0];
    if (!supportedFormats.includes(file.type)) {
      toast.error(
        "Invalid file format. Please upload an image file of PNG, JPEG, JPG or WEBP format."
      );
      setProfileCoverPhoto(null);
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      toast.error(
        "File size exceeds the limit. Please upload a file smaller than 1MB."
      );
      return;
    }
    setProfileCoverPhoto(file);
  };

  const handleCafePicturesChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => {
      if (!supportedFormats.includes(file.type)) {
        toast.error(`Invalid file format for ${file.name}. Please upload an image file of PNG, JPEG, JPG or WEBP format.`);
        return false;
      }
      if (file.size > MAX_FILE_SIZE) {
        toast.error(`File size exceeds the limit for ${file.name}. Please upload a file smaller than 1MB.`);
        return false;
      }
      return true;
    });
    setNewCafePictures(prevState => [...prevState, ...validFiles]);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center min-h-screen bg-gray-100 mb-20 px-4">
      <UploadButton
        text="Upload profile cover photo"
        onFileChange={handleProfileCoverPhotoChange}
      />
      <div className="mt-4">
        {profileCoverPhoto && (
          <div className="flex items-center justify-between gap-x-8">
            <img
              src={
                profileCoverPhoto instanceof File
                  ? URL.createObjectURL(profileCoverPhoto)
                  : `data:image/webp;base64,${profileCoverPhoto}`
              }
              alt="cover"
              className="w-56 h-56 object-contain rounded-lg"
            />
            <button onClick={() => setProfileCoverPhoto("")}>X</button>
          </div>
        )}
      </div>
      <UploadButton
        text="Upload Cafe pictures"
        onFileChange={handleCafePicturesChange}
        multiple={true}
      />
      <div className="mt-4 grid grid-cols-3 gap-4">
        {cafePictures.map((file, index) => (
          <div
            key={`existing-${index}`}
            className="relative"
          >
            <img
              src={`data:image/webp;base64,${file}`}
              alt={`Gallery ${index + 1}`}
              className="w-56 h-56 object-contain rounded-lg"
            />
            <button
              onClick={() => removeExistingImage(index)}
              className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
            >
              Remove
            </button>
          </div>
        ))}
        {newCafePictures.map((file, index) => (
          <div
            key={`new-${index}`}
            className="relative"
          >
            <img
              src={URL.createObjectURL(file)}
              alt={`New gallery ${index + 1}`}
              className="w-56 h-56 object-contain rounded-lg"
            />
            <button
              onClick={() => removeNewImage(index)}
              className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
const QRLink = () => {
  const cafe = useSelector((state) => state.auth.cafe);
  const url = `https://app.orderg.in/cafe/${cafe._id}`;
  const [copied, setCopied] = useState(false);

  const downloadCode = () => {
    const canvas = document.getElementById("qrcode-canvas");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${cafe.name}-qr.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg shadow-lg mb-20">
      <div className="flex items-center justify-between w-full">
        <p className="text-sm font-semibold">{url}</p>
        <CopyToClipboard
          text={url}
          onCopy={handleCopy}
        >
          <button className="p-2 text-gray-700 bg-white border rounded shadow-md hover:bg-gray-100">
            <FaClipboard className="w-6 h-6" />
          </button>
        </CopyToClipboard>
      </div>
      <div className="mt-4 p-4 bg-gray-200 rounded-lg">
        <QRCode
          id="qrcode-canvas"
          value={url}
          size={200}
        />
      </div>
      <div className="my-5">
        <button
          onClick={() => downloadCode()}
          className="text-2xl px-2 py-1 bg-[#FFFFFF] text-black rounded-lg shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]"
        >
          Download QR Code
        </button>
      </div>
      {copied && (
        <p className="mt-2 text-sm text-green-500">URL copied to clipboard!</p>
      )}
    </div>
  );
};

const VerificationDocuments = () => {
  const [document, setDocument] = useState(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  useEffect(() => {
    const fetchFssaiCertificate = async () => {
      try {
        const response = await getFssaiCertificate();
        if (response.success) {
          setUploadedDocument(response.data.fssaiCertificate);
        }
      } catch (error) {
        console.error("Error fetching FSSAI certificate:", error);
        // Handle error (e.g., show error message to user)
      }
    };

    fetchFssaiCertificate();
  }, []);

  const handleDocumentChange = async (event) => {
    const file = event.target.files[0];
    setDocument(file);

    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await updateFssaiCertificate(formData);
      if (response.success) {
        setUploadedDocument(response.data.fssaiCertificate);
      }
    } catch (error) {
      console.error("Error uploading FSSAI certificate:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 mb-20">
      <UploadButton
        text="Upload FSSAI Certificate"
        onFileChange={handleDocumentChange}
      />
      <div className="mt-4">
        {document && <p>Selected file: {document.name}</p>}
      </div>
      {uploadedDocument && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">FSSAI Certificate:</h3>
          <img
            src={`data:image/webp;base64,${uploadedDocument}`}
            alt="FSSAI Certificate"
            className="max-w-md rounded-lg shadow-md"
          />
        </div>
      )}
    </div>
  );
};

const Facilities = ({ facilities, setFacilities }) => {
  const [newFacility, setNewFacility] = useState({ name: "" });
  const cafe = useSelector((state) => state.auth.cafe);

  useEffect(() => {
    handleGetFacility();
  }, []);

  const handleGetFacility = async () => {
    const response = await getFacilities(cafe._id);
    if (response.success) {
      setFacilities(response.data);
    }
  };

  const handleAddFacility = async () => {
    const response = await addFacility(newFacility);
    if (response.success) {
      setFacilities(response.data);
    }
    setNewFacility({ name: "" });
  };

  const handleUpdateFacility = async (index, updatedFacility) => {
    const response = await updateFacility(index, updatedFacility);
    if (response.success) {
      setFacilities(response.data);
    }
  };

  const handleDeleteFacility = async (index) => {
    const response = await deleteFacility(index);
    if (response.success) {
      setFacilities(response.data);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Facilities</h2>
      {facilities.map((facility, index) => (
        <div
          key={index}
          className="flex items-center space-x-2"
        >
          <input
            type="text"
            value={facility.name}
            onChange={(e) =>
              handleUpdateFacility(index, { ...facility, name: e.target.value })
            }
            className="border rounded px-2 py-1"
            placeholder="Facility name"
          />
          <button
            onClick={() => handleDeleteFacility(index)}
            className="bg-red-500 text-white px-2 py-1 rounded"
          >
            Delete
          </button>
        </div>
      ))}
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={newFacility.name}
          onChange={(e) =>
            setNewFacility({ ...newFacility, name: e.target.value })
          }
          placeholder="New facility name"
          className="border rounded px-2 py-1"
        />
        <button
          onClick={handleAddFacility}
          className="bg-green-500 text-white px-2 py-1 rounded"
        >
          Add Facility
        </button>
      </div>
    </div>
  );
};
export default Settings;
