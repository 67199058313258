import React from 'react';
import { FaSmile, FaFrown, FaMeh } from 'react-icons/fa';
import image29 from "../../pages/assets/image 29.svg"
import image30 from "../../pages/assets/image 30.svg"
import image32 from "../../pages/assets/image 32.svg"
const FeedbackSummary = () => {
  return (
    <div className="bg-[#FFF2AF] rounded-lg p-4 max-w-sm mx-auto " style={{ boxShadow: "1px 2px 2px 2px #000000" }}>
      <div className="flex justify-between mb-4">
        <div className="text-center">
          <div className="text-3xl font-bold">423</div>
          <div className="text-sm font-semibold">Total Feedback</div>
        </div>
        <div className="text-center">
          <div className="text-3xl font-bold">4.3</div>
          <div className="text-sm font-semibold">Avg. Rating</div>
        </div>
      </div>
      
      <div className="border-t border-yellow-600 pt-4">
        <div className="text-xs font-semibold mb-2">Detailed</div>
        <div className="space-y-2">
          <div className='flex flex-row justify-between'><FeedbackRow2  label="Positive" count={400} percentage={91} />
          <FeedbackRow2  label="Negative" count={20} percentage={8} />
          <FeedbackRow2 label="Neutral" count={20} percentage={1} /></div>
          <div className='flex flex-row justify-between'><FeedbackRow icon={<img src={image29}/>} label="Positive" count={400} percentage={91} />
          <FeedbackRow icon={<img src={image30}/>} label="Negative" count={20} percentage={8} />
          <FeedbackRow icon={<img src={image32}/>} label="Neutral" count={20} percentage={1} />
          </div>
          
        </div>
      </div>
    </div>
  );
};

const FeedbackRow = ({ icon, label, count, percentage }) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex  flex-row items-center">
        {icon}
        <span className="font-bold px-2 py-1 rounded-full text-3xl">
          {percentage}%
        </span>
      </div>
      {/* <div className="flex  items-center">
        <span className="mr-2">{count}</span>
        <span className="ml-2">{label}</span>
        
      </div> */}
    </div>
  );
};
const FeedbackRow2 = ({ icon, label, count, percentage }) => {
    return (
      <div className="flex flex-row items-center justify-between">
        {/* <div className="flex  items-center">
          {icon}
          <span className="bg-yellow-200 px-2 py-1 rounded-full text-xs">
            {percentage}%
          </span>
        </div> */}
        <div className="flex flex-row items-center gap-2 justify-between">
          <span className="font-bold">{count}</span>
          <span className="text-xs font-medium">{label}</span>
          
        </div>
      </div>
    );
  };

export default FeedbackSummary;


