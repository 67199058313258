import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import BottomMenu from "../../components/BottomMenu";
import { fetchOrders } from "../../api/cafe";
import OrdersItem from "../../components/OrdersItem";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../redux/slices/admin";
import { Link } from "react-router-dom";
import WebSocketConnection from "../../components/WebSocketConnection";

const Orders = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [showOrdered, setShowOrdered] = useState(true);
  const [showPreparing, setShowPreparing] = useState(true);
  const [showServed, setShowServed] = useState(true);
  const [showCompleted, setShowCompleted] = useState(true);
  const [showAll, setShowAll] = useState(true);

  const ordersFromState = useSelector((state) => state.admin.orders) || {};

  useEffect(() => {
    fetchOrders()
      .then((res) => {
        if (res.success) {
          dispatch(setOrders(res.data));
        } else {
          console.error("Error fetching orders:", res.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, [dispatch]);

  const renderOrders = (status) => {
    if (!ordersFromState[date] || !ordersFromState[date][status]) return null;
    return ordersFromState[date][status].map((order) => (
      <OrdersItem key={order._id} order={order} />
    ));
  };

  return (
    <div className="w-full flex flex-col items-center">
      <Header />
      <Helmet
        title="Orders"
        meta={[
          {
            name: "description",
            content: "Orders for your cafe",
          },
          {
            property: "og:title",
            content: "Orders",
          },
        ]}
      />
      <WebSocketConnection />
      <div className="w-full h-full flex flex-col justify-center items-center">
        <h1 className="text-3xl font-semibold my-4">Orders</h1>
        
        {/* Filter checkboxes */}
        <div className="flex flex-wrap justify-center mb-4">
          <label className="mx-2">
            <input
              type="checkbox"
              checked={showAll}
              onChange={() => setShowAll(!showAll)}
            />
            All
          </label>
          <label className="mx-2">
            <input
              type="checkbox"
              checked={showOrdered || showAll}
              onChange={() => setShowOrdered(!showOrdered)}
            />
            Ordered
          </label>
          <label className="mx-2">
            <input
              type="checkbox"
              checked={showPreparing || showAll}
              onChange={() => setShowPreparing(!showPreparing)}
            />
            Preparing
          </label>
          <label className="mx-2">
            <input
              type="checkbox"
              checked={showServed || showAll}
              onChange={() => setShowServed(!showServed)}
            />
            Served
          </label>
          <label className="mx-2">
            <input
              type="checkbox"
              checked={showCompleted || showAll}
              onChange={() => setShowCompleted(!showCompleted)}
            />
            Completed
          </label>
        </div>
        
        {/* Orders list */}
        <div className="w-full px-4">
          {Object.keys(ordersFromState).length === 0 ? (
            <p>No orders found</p>
          ) : (
            <>
              {(showAll || showOrdered) && renderOrders("ORDERED")}
              {(showAll || showPreparing) && renderOrders("PREPARING")}
              {(showAll || showServed) && renderOrders("SERVED")}
              {(showAll || showCompleted) && renderOrders("COMPLETED")}
            </>
          )}
        </div>
      </div>
      <Link
        to={"/cafe-admin/orders/all"}
        className="w-32 lg:w-40 text-sm lg:text-base bg-sky-500 text-white h-8 flex items-center justify-center rounded ml-2 cursor-pointer shadow"
      >
        See All Orders
      </Link>
      <div className="w-full h-20"></div>
      <BottomMenu page="orders" />
    </div>
  );
};

export default Orders;