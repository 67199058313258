

import React, { useState, useEffect } from "react";
import { getOperatingHours, getFacilities, getCafeDetails } from "../../api/cafe";
import { FaMapMarkerAlt, FaPhone, FaClock } from "react-icons/fa";
import { Link } from "react-router-dom";

const ContactInfo = ({ cafeDetails}) => {
  console.log("Cafe details from contact page: ", cafeDetails)
  const [cafeData, setCafeData] = useState(cafeDetails || {});
  const [operatingHours, setOperatingHours] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let cafeId;

        if (cafeDetails && cafeDetails._id) {
          cafeId = cafeDetails._id;
          setCafeData(cafeDetails);
        } else {
          const selectedCafeId = sessionStorage.getItem("selected_cafe_id");
          const storedCafeData = sessionStorage.getItem("OG_cafe");

          if (selectedCafeId) {
            cafeId = selectedCafeId;
          } else if (storedCafeData) {
            const parsedCafeData = JSON.parse(storedCafeData);
            cafeId = parsedCafeData._id;
            setCafeData(parsedCafeData);
          } else {
            throw new Error("No cafe data found");
          }

          if (cafeId && !cafeDetails) {
            const cafeDetailsResponse = await getCafeDetails(cafeId);
            if (cafeDetailsResponse.success) {
              setCafeData(cafeDetailsResponse.data);
            } else {
              throw new Error("Failed to fetch cafe details");
            }
          }
        }

        setCafeData(cafeDetails)

        const [hoursResponse, facilitiesResponse] = await Promise.all([
          getOperatingHours(cafeId),
          getFacilities(cafeId),
        ]);

        if (hoursResponse.success) {
          const orderedDays = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ];
          const orderedHours = orderedDays.map((day) => [
            day,
            hoursResponse.data[day] || [],
          ]);
          setOperatingHours(orderedHours);
        } else {
          console.warn("Failed to fetch operating hours");
        }

        if (facilitiesResponse.success) {
          setFacilities(facilitiesResponse.data);
        } else {
          console.warn("Failed to fetch facilities");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message || "An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cafeDetails]);
  console.log("first:",facilities)

  if (loading) {
    return <div>Loading data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="font-sans max-w-md mx-auto py-4">
      <div className="mb-4">
        <h3 className="font-semibold mb-1">Address</h3>
        <p className="text-sm text-gray-600 mb-1">
          {console.log("My Cafe Address: ", cafeData.address)}
          {cafeData.address}, {cafeData.city}, {cafeData.state},{" "}
          {cafeData.pincode}
        </p>
        {cafeData.google_maps_link && (
          <Link
            to={cafeData.google_maps_link}
            className="text-purple-500 text-sm flex items-center"
          >
            <FaMapMarkerAlt className="mr-1 " /> Get Directions
          </Link>
        )}
      </div>

      <div className="mb-4">
        <h3 className="font-semibold mb-1">Mobile</h3>
        <p className="text-sm text-gray-600 mb-1">{cafeData.phone}</p>
        <a
          href={`tel:${cafeData.phone}`}
          className="text-purple-500 text-sm flex items-center"
        >
          <FaPhone className="mr-1 rotate-90" /> Call Us
        </a>
      </div>

      {operatingHours.length > 0 && (
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Timing</h3>
          {operatingHours.map(([day, times]) => (
            <div
              key={day}
              className="flex items-center mb-1"
            >
              <span className="w-28 text-center text-gray-700 border border-black text-xs font-semibold px-2 py-1 rounded mr-2">
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </span>
              {times[0] && times[0].is_open ? (
                <span className="ml-8 text-sm text-gray-600">
                  {new Date(
                    `1970-01-01T${times[0].open}:00`
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}{" "}
                  -{" "}
                  {new Date(
                    `1970-01-01T${times[0].close}:00`
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
              ) : (
                <span className="ml-8 text-sm text-gray-600">Closed</span>
              )}
            </div>
          ))}
        </div>
      )}

      {facilities.length > 0 && (
        <div className="hidden">
          <h3 className="font-semibold mb-2">Facilities</h3>
          <div className="flex flex-wrap gap-4 flex-col">
            {facilities.map((facility, index) => (
              <div
                key={index}
                className="bg-gray-100 rounded-lg p-2"
              >
                <p className="font-semibold">{facility.name}</p>
                {facility.description && (
                  <p className="text-sm text-gray-600">
                    {facility.description}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactInfo;