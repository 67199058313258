import React, { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import Switch from "../../components/Switch";
import UniversalInput from "../../components/Universalnput";

const AddItem = () => {
  const categories = ["Beverages", "Burgers", "Pizzas", "Desserts", "Salads"];
  const subCategories = ["Hot", "Cold", "Veg", "Non-Veg", "Spicy", "Sweet"];

  const [screen, setScreen] = useState("category");

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [recommended, setRecommended] = useState(false);
  const [inStock, setInStock] = useState(false);
  const [variant, setVariant] = useState([]);
  const [servingInfo, setServingInfo] = useState("");
  const [portionSize, setPortionSize] = useState("");
  const [image, setImage] = useState("");
  const [tags, setTags] = useState("");

  function setCategoryHandler(e) {
    setCategory(e.target.value);
    setScreen("subcategory");
  }
  function setSubCategoryHandler(e) {
    setSubCategory(e.target.value);
    setScreen("item");
  }
  function setItemHandler() {
    setScreen("itemdetails");
  }

  function preCheck() {
    if (name === "" || price === "" || description === "") {
      setScreen("item");
      return false;
    }
    return true;
  }

  function formSumbit(e) {
    e.preventDefault();
    if (preCheck()) {
      const item = {
        category,
        subCategory,
        name,
        price,
        description,
        recommended,
        inStock,
        variant,
        servingInfo,
        portionSize,
        image,
        tags,
      };
      console.log(item);
    }
  }

  const SetCategory = () => (
    <div className="h-screen flex flex-col mx-3">
      <h1 className="my-4 text-2xl">Add Item</h1>
      <hr />
      <h2 className="my-2">Choose a Category</h2>
      <hr />
      <div className="flex flex-col items-start w-3/4 mt-2">
        {categories.map((cat) => (
          <div
            onClick={setCategoryHandler}
            key={cat}
            className="flex justify-center items-center my-2"
          >
            <p>{cat} </p>
            <CaretRightOutlined />
          </div>
        ))}
      </div>
    </div>
  );

  const SetSubCategory = () => (
    <div className="h-screen flex flex-col mx-3">
      <h1 className="my-4 text-2xl">Add Item</h1>
      <hr />
      <h2 className="my-2">Choose a Sub-Category</h2>
      <hr />
      <div className="flex flex-col items-start w-3/4 mt-2">
        {subCategories.map((cat) => (
          <div
            onClick={setSubCategoryHandler}
            key={cat}
            className="flex justify-center items-center my-2"
          >
            <p>{cat} </p>
            <CaretRightOutlined />
          </div>
        ))}
      </div>
    </div>
  );

  const Item = () => (
    <div className="h-screen flex flex-col mx-3">
      <h1 className="my-4 text-2xl">Add Item</h1>
      <hr />
      <h2 className="my-2">Add Item Details</h2>
      <hr />
      <div className="mt-4">
        <div className="my-2">
          <label
            htmlFor="name"
            className="text-xs text-teal-500"
          >
            Name *
          </label>
          <UniversalInput
            type="text"
            placeholder="Farmhouse Pizza"
            value={name}
            autoFocus
            onChange={(e) => setName(e.target.value)}
            className="w-full border rounded-md p-2 text-base shadow-md border-sky-400 mt-1"
          />
        </div>
        <div className="my-2">
          <label
            htmlFor="price"
            className="text-xs text-teal-500"
          >
            Price *
          </label>
          <UniversalInput
            type="number"
            placeholder="456.67"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full border rounded-md p-2 text-base shadow-md border-sky-400 mt-1"
          />
        </div>
        <div className="my-2">
          <label
            htmlFor="desc"
            className="text-xs text-teal-500"
          >
            Description *
          </label>
          <textarea
            rows={5}
            placeholder="This pizza is made with love and..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full border rounded-md p-2 text-base shadow-md border-sky-400 mt-1"
          />
          <div className="my-2">
            <label
              htmlFor="image"
              className="text-xs text-teal-500"
            >
              Image Upload *
            </label>
            <UniversalInput
              type="file"
              placeholder="Image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              className="w-full border rounded-md p-2 text-sm"
            />
          </div>
        </div>
        <div className="my-2">
          <label
            htmlFor="recommended"
            className="text-xs text-teal-500"
          >
            Is it Recommended?
          </label>
          {console.log(recommended)}
          <Switch
            toggle={recommended}
            clickAction={() => setRecommended(!recommended)}
          />
        </div>
        <div className="my-2">
          <label
            htmlFor="inStock"
            className="text-xs text-teal-500"
          >
            Is it in stock?
          </label>
          {console.log(inStock)}
          <Switch
            toggle={inStock}
            clickAction={() => setInStock(!inStock)}
          />
        </div>
        <button
          onClick={setItemHandler}
          className="bg-teal-500 text-white px-8 py-2 block mt-5 rounded-md shadow-md"
        >
          Continue
        </button>
      </div>
    </div>
  );

  const ItemDetails = () => (
    <div className="h-screen flex flex-col mx-3">
      <h1 className="my-4 text-2xl">Add Item</h1>
      <hr />
      <h2 className="my-2">Add Item Details</h2>
      <hr />
      <div className="mt-4">
        <div className="my-2">
          <label
            htmlFor="variant"
            className="text-xs text-teal-500"
          >
            Variant
          </label>
          <UniversalInput
            type="text"
            placeholder="Large, Medium, Small"
            value={variant}
            onChange={(e) => setVariant(e.target.value)}
            className="w-full border rounded-md p-2 text-base shadow-md border-sky-400 mt-1"
          />
        </div>
        <div className="my-2">
          <label
            htmlFor="servingInfo"
            className="text-xs text-teal-500"
          >
            Serving Info
          </label>
          <UniversalInput
            type="text"
            id="servingInfo"
            placeholder="1 Pizza, 1 Mayo Dip, 1 Tomato Ketchup"
            value={servingInfo}
            onChange={(e) => setServingInfo(e.target.value)}
            className="w-full border rounded-md p-2 text-base shadow-md border-sky-400 mt-1"
          />
        </div>
        <div className="my-2">
          <label
            htmlFor="portionSize"
            className="text-xs text-teal-500"
          >
            Portion Size
          </label>
          <UniversalInput
            type="text"
            id="portionSize"
            placeholder="1 Person, 2 Person, 3 Person"
            value={portionSize}
            onChange={(e) => setPortionSize(e.target.value)}
            className="w-full border rounded-md p-2 text-base shadow-md border-sky-400 mt-1"
          />
        </div>
        <div className="my-2">
          <label
            htmlFor="tags"
            className="text-xs text-teal-500"
          >
            Tags
          </label>
          <UniversalInput
            type="text"
            id="tags"
            placeholder="Spicy, Sweet, Healthy"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="w-full border rounded-md p-2 text-base shadow-md border-sky-400 mt-1"
          />
        </div>
        <button
          onClick={formSumbit}
          className="bg-teal-500 text-white px-8 py-2 block mt-5 rounded-md shadow-md"
        >
          Add Item
        </button>
      </div>
    </div>
  );

  if (screen === "category") return <SetCategory />;
  if (screen === "subcategory") return <SetSubCategory />;
  if (screen === "item") return <Item />;
  if (screen === "itemdetails") return <ItemDetails />;
};

export default AddItem;
