import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchOrder } from "../../api/cafe";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReviewItem from "../../components/reviewItem";
import {
  addOrderRating,
  addOrderReview,
  fetchOrderToRate,
} from "../../api/user";

const Review = () => {
  const { order } = useParams();
  const [orderDetails, setOrderDetails] = useState();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewAdded, setReviewAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animate, setAnimation] = useState("");
  const [display, setDisplay] = useState("block");

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the order details
    fetchOrderToRate(order)
      .then((res) => {
        setOrderDetails(res.data);
      })
      .catch((err) => {
        navigate("/404");
        console.log(err);
      });
  }, [order]);

  function handleReview() {
    setLoading(true);
    addOrderReview(order, review).then((res) => {
      if (res.success) {
        setReviewAdded(true);
        setAnimation("transition duration-1000 opacity-0 scale-0");
        setTimeout(() => {
          setDisplay("hidden");
        }, 500);
        if (rating) navigate("/404");
      }
    });
  }

  function handleOrderRating(val) {
    setRating(val);
    addOrderRating(order, val);
  }

  if (!orderDetails)
    return (
      <div className="flex flex-col h-screen w-full items-center justify-center">
        <img src="../loading.gif" alt="Loading" />
      </div>
    );

  if (orderDetails.rating)
    return (
      <div className="flex flex-col h-screen w-full items-center justify-center">
        Already reviewed
      </div>
    );

  return (
    <div className="flex flex-col h-screen w-full items-center">
      <Helmet
        title="Review"
        meta={[
          {
            name: "description",
            content: "Review your order",
          },
          {
            property: "og:title",
            content: "Review",
          },
        ]}
      />
      <div className="w-full border-b flex justify-center items-center">
        <img src="../icon.svg" alt="" className="h-6 mr-2" />
        <h1 className="text-xl font-semibold py-4">Rating</h1>
      </div>
      <div className="flex flex-col w-full my-8">
        <p className="text-center text-sm mb-2">
          Rate your overall experience!
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => handleOrderRating(1)}
            className={`border text-xs py-2 px-4 m-1 rounded-full bg-red-500 ${
              rating === 1 && "border-2 border-black"
            }`}
          >
            1
          </button>
          <button
            onClick={() => handleOrderRating(2)}
            className={`border text-xs py-2 px-4 m-1 rounded-full bg-orange-400 ${
              rating === 2 && "border-2 border-black"
            }`}
          >
            2
          </button>
          <button
            onClick={() => handleOrderRating(3)}
            className={`border text-xs py-2 px-4 m-1 rounded-full bg-yellow-200 ${
              rating === 3 && "border-2 border-black"
            }`}
          >
            3
          </button>
          <button
            onClick={() => handleOrderRating(4)}
            className={`border text-xs py-2 px-4 m-1 rounded-full bg-green-300 ${
              rating === 4 && "border-2 border-black"
            }`}
          >
            4
          </button>
          <button
            onClick={() => handleOrderRating(5)}
            className={`border text-xs py-2 px-4 m-1 rounded-full bg-green-400 ${
              rating === 5 && "border-2 border-black"
            }`}
          >
            5
          </button>
        </div>
      </div>
      {!reviewAdded && (
        <div className="flex flex-col w-full items-center">
          {orderDetails.itemsToReview.map((item) => (
            <ReviewItem item={item} />
          ))}
          <div
            className={
              "flex border border-gray-300 shadow rounded my-4 flex-col justify-between w-11/12" +
              animate +
              " " +
              display
            }
          >
            <div className="w-full justify-center items-center py-4 border-b">
              <p className="text-center text-sm">
                Please rate your overall experience
              </p>
            </div>
            <div className="flex flex-col p-4">
              <h2 className="text-sm">Add detailed review</h2>
              <textarea
                name="review"
                id="review"
                cols="30"
                rows="5"
                className="bg-gray-100 border border-gray-300 rounded-3xl mt-2 text-sm p-4 w-full focus:outline-none"
                placeholder="Write your review here..."
                value={review}
                onChange={(e) => setReview(e.target.value)}
              ></textarea>
            </div>
            <button
              onClick={handleReview}
              disabled={loading}
              className="bg-[#FD7347] shadow-2xl disabled:bg-[#FD7347] text-white py-2 mx-8 mb-4 rounded-xl"
            >
              Submit Review
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Review;
