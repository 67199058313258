import React, { useEffect } from 'react';
import "./App.css";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";
import { clarity } from 'react-microsoft-clarity';

function App({ children }) {
  useEffect(() => {
    
    if (process.env.REACT_APP_CLARITY_KEY) {
      console.log('Attempting to initialize Clarity');
      try {
        clarity.init(process.env.REACT_APP_CLARITY_KEY);
        console.log('Clarity initialized successfully');
      } catch (error) {
        console.error('Error initializing Clarity:', error);
      }
    } else {
      console.warn('Clarity key is not set in environment variables');
    }
  }, []);

  return (
    <div className="App">
      {children}
    </div>
  );
}

export default App;