import { useState, useEffect } from "react";
import { loginUserWithPassword, loginUserWithPin } from "../../api/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slices/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster.jsx";
import { Helmet } from "react-helmet";
import Universalinput from "../../components/Universalnput";
import LoadingOverlay from "../../components/LoadingOverlay";
export default function Login() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const phoneFromParam = searchParams.get("phone");

  const [phone, setPhone] = useState(phoneFromParam || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("OG_token");
    if (token) {
      navigate("/cafe-admin/analytics");
    }

    if (phoneFromParam) {
      setPhone(phoneFromParam);
      toast("Registration successful");
    }
  }, []);

  async function formSumbit(e) {
    setLoading(true);
    e.preventDefault();
    const user = await loginUserWithPassword({ phone, password });
    if (user.success) {
      dispatch(setUser(user.data));
      localStorage.setItem("OG_token", user.data);
      localStorage.setItem("OG_phone", phone);
      if (user.message) navigate("/cafe-admin/analytics");
      else navigate("/cafe-admin/register");
    } else {
      toast.error(user.message);
    }
    setLoading(false);
  }

  if (loading) return <LoadingOverlay />;

  return (
    <form
      onSubmit={formSumbit}
      className="w-full h-screen flex flex-col justify-center items-center"
    >
      <Helmet
        title="Login | Cafe Admin"
        meta={[
          {
            name: "description",
            content: "Login to your cafe admin account",
          },
        ]}
      />
      <Toaster />
      <img
        src="../Cafe-admin.png"
        alt="Hero"
      />
      <h1 className="text-xl mb-5">Enter manager&apos;s credentials</h1>
      <Universalinput
        type="number"
        placeholder="Enter phone"
        className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm placeholder-black"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <Universalinput
        type="password"
        placeholder="Enter password"
        className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm placeholder-black"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button
        type="submit"
        className="bg-[#FD7347] text-white px-8 py-2 block mt-5 rounded-md shadow-md"
      >
        Login
      </button>
    </form>
  );
}
