import React from "react";

const LoadingOverlay = ({ title = "Loading...", show = true }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="text-center text-white">
        <div className="w-10 h-10 border-4 border-gray-300 border-t-white rounded-full animate-spin mb-4"></div>
        <h2 className="text-xl">{title}</h2>
      </div>
    </div>
  );
};

export default LoadingOverlay;
