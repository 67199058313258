import React, { useState } from "react";
import { updateOfferStatus } from "../../api/cafe";
import DatePicker from "react-datepicker";
import OfferPopup from "../user/OfferPopup";
import { useSelector } from "react-redux";

const AllOffersAnalyticsCard = ({ title, offer, onStatusChange }) => {
  const cafeData = useSelector((state) => state.auth.cafe);
  const [isUpdating, setIsUpdating] = useState(false);
  const [newExpirationDate, setNewExpirationDate] = useState(new Date());
  const [selectedOffer, setSelectedOffer] = useState(null);
  const offerData = offer.offerDetails;

  const handleStatusToggle = async () => {
    if (offerData.isActive) {
      try {
        await updateOfferStatus(offerData.id, false);
        onStatusChange();
      } catch (error) {
        console.error("Error updating offer status:", error);
      }
    } else {
      setIsUpdating(true);
    }
  };

  const handleActivateWithNewDate = async () => {
    try {
      await updateOfferStatus(
        offerData.id,
        true,
        newExpirationDate.toISOString()
      );
      setIsUpdating(false);
      onStatusChange();
    } catch (error) {
      console.error("Error updating offer status:", error);
    }
  };

  const getEngagementRate = () => {
    if (offer.totalViews === 0) return 0;
    return ((offer.uniqueUsers / offer.totalViews) * 100).toFixed(1);
  };

  const getEngagementMessage = () => {
    const rate = getEngagementRate();
    if (rate > 50) return "Great engagement! Many viewers are unique.";

  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
  };

  return (
    <div className="w-full max-w-md bg-white rounded-2xl p-4 border-2 border-black shadow-[2px_3px_0px_0px_rgba(0,0,0,1)] my-6">
      <div className="flex gap-x-4">
        <div
          className="w-1/4"
          onClick={() => handleOfferClick(offerData)}
        >
          {offerData.type === "card" ? (
            <div
              className="rounded-md w-32 h-full object-cover text-center flex flex-col justify-center text-white p-1"
              style={{
                backgroundColor: offerData.backgroundColor || "#4A90E2",
              }}
            >
              <p className="text-sm w-full">{offerData.campaignTitle}</p>
              <p className="text-xs text-wrap">
                {String(offerData.campaignDescription).slice(0, 35)}...
              </p>
              <p className="text-xs w-full my-1">
                Discount: {offerData.discount || "0"}
                {offerData.rewardType && offerData.rewardType.includes("%")
                  ? "%"
                  : " Rs"}
              </p>
            </div>
          ) : (
            <img
              src={`data:image/jpeg;base64,${offerData.posterImage}`}
              alt=""
              className="pb-2 rounded-md h-full w-32 object-cover"
            />
          )}
        </div>
        <div className="w-full md:w-2/3 pl-0 md:pl-4">
          <div className="flex flex-col items-start md:items-center md:justify-center gap-x-2">
            <div className="flex items-start md:flex-row md:items-center md:justify-center gap-x-4 mb-2">
              <div>
                <p className="text-xs text-gray-600">Started</p>
                <p className="text-xs font-semibold">
                  {new Date(offerData.createdAt).toLocaleDateString()}
                </p>
              </div>
              <div>
                <p className="text-xs text-gray-600">Ends</p>
                <p className="text-xs font-semibold">
                  {new Date(offerData.expirationDate).toLocaleDateString()}
                </p>
              </div>
              <div>
                {offerData.isActive ? (
                  <div className="bg-green-500 text-white text-xs font-bold py-1 px-2 rounded">
                    Active
                  </div>
                ) : (
                  <div className="bg-red-500 text-white text-xs font-bold py-1 px-2 rounded">
                    Inactive
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center md:flex-row md:items-center md:justify-center gap-x-8 mb-4">
              <div>
                <p className="text-3xl font-bold">{offer.totalViews}</p>
                <p className="text-xs text-gray-600">Total Views</p>
              </div>
              <div>
                <p className="text-3xl font-bold">{offer.uniqueUsers}</p>
                <p className="text-xs text-gray-600">Unique Users</p>
              </div>
            </div>
          </div>

          <p className="text-sm mb-2">
            Engagement Rate:{" "}
            <span className="font-semibold">{getEngagementRate()}%</span>
          </p>
          <p className="text-sm mb-4">{getEngagementMessage()}</p>

          {offerData.addToSwiggy === "true" && (
            <p className="text-xs text-gray-600">✓ Added to Swiggy</p>
          )}
          {offerData.addToZomato === "true" && (
            <p className="text-xs text-gray-600">✓ Added to Zomato</p>
          )}
        </div>
      </div>
      <div className="flex justify-between mt-4 w-full">
        {offerData.isActive && (
          <button
            onClick={handleStatusToggle}
            className="w-full bg-white border border-red-500 text-red-500 font-bold py-2 px-4 rounded-full"
          >
            Deactivate Offer
          </button>
        )}
      </div>
      {selectedOffer && (
        <OfferPopup
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
          }}
          cafeName={cafeData.name}
        />
      )}
    </div>
  );
};

export default AllOffersAnalyticsCard;