import React from "react";

const Page404 = () => {
  return (
    <div className="flex justify-center flex-col items-center w-full h-screen text-center px-16">
      <img src="../Icon.svg" className="h-20" alt="" />
      <p className="mt-10">
        To restart your session, please scan the{" "}
        <span className="text-orange-500">OrderJi</span> QR code at your
        favourite cafe again.
      </p>
    </div>
  );
};

export default Page404;
