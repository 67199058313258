import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchActiveBags,
  fetchPOSMenuItems,
  selectActiveBags,
  selectActiveBagsLoading,
  selectActiveBagsError,
  addItemsToBag,
  createKOTFromBagItems,
  selectCurrentBagKOTs,
  selectBagKOTsLoading,
  fetchBagKOTs,
  closeBag

} from '../../redux/slices/posSlice';
import { Card, Button, List, Spin, message, Modal, Select, InputNumber, Table, Space, Row, Col, Divider, Typography } from 'antd';
import { PlusOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';
import { FilePdfOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const { Option } = Select;
const { Text, Title } = Typography;

const ActiveOrders = () => {
  const dispatch = useDispatch();
  const activeBags = useSelector(selectActiveBags);
  const loading = useSelector(selectActiveBagsLoading);
  const error = useSelector(selectActiveBagsError);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedBag, setSelectedBag] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [kotModalVisible, setKotModalVisible] = useState(false);
  const [menuItems, setMenuItems] = useState({});
  const [menuLoading, setMenuLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const bagKOTs = useSelector(selectCurrentBagKOTs);
  const bagKOTsLoading = useSelector(selectBagKOTsLoading);
  const [searchQuery, setSearchQuery] = useState('');



  const [billModalVisible, setBillModalVisible] = useState(false);

  // Fetch active bags and menu items
  useEffect(() => {
    dispatch(fetchActiveBags());

    const loadMenuItems = async () => {
      try {
        setMenuLoading(true);
        const data = await dispatch(fetchPOSMenuItems()).unwrap();
        setMenuItems(data);
      } catch (err) {
        console.error("Failed to load menu items:", err);
        message.error("Failed to load menu items. Please try again.");
      } finally {
        setMenuLoading(false);
      }
    };

    loadMenuItems();
  }, [dispatch]);

  const showEditModal = (bag) => {
    setSelectedBag(bag);
    setSelectedItems(bag.items || []);
    setEditModalVisible(true);
  };

  const categories = ['All', ...(Object.keys(menuItems) || [])];

  const getMenuItemsToShow = () => {
    if (selectedCategory === 'All') {
      return Object.values(menuItems).flat().filter(Boolean);
    }
    return (menuItems[selectedCategory] || []).filter(Boolean);
  };

  const getFilteredMenuItems = () => {
    const itemsToFilter = getMenuItemsToShow();
    if (!searchQuery.trim()) return itemsToFilter;

    return itemsToFilter.filter(item =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  const handleAddItem = (menuItem) => {
    const existingItem = selectedItems.find(item => item.itemId === menuItem._id);

    if (existingItem) {
      setSelectedItems(selectedItems.map(item =>
        item.itemId === menuItem._id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      ));
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          key: Date.now(),
          itemId: menuItem._id,
          name: menuItem.name,
          quantity: 1,
          price: menuItem.price,
          notes: ''
        }
      ]);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter(item => item.itemId !== itemId));
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    setSelectedItems(selectedItems.map(item =>
      item.itemId === itemId ? { ...item, quantity: newQuantity } : item
    ));
  };

  const handleSaveAndCreateKOT = async () => {
    try {
      if (selectedItems.length === 0) {
        message.warning('Please add at least one item');
        return;
      }

      await dispatch(createKOTFromBagItems({
        bagId: selectedBag.id,
        items: selectedItems.map(item => ({
          itemId: item.itemId,
          quantity: item.quantity,
          notes: item.notes || ''
        }))
      })).unwrap();

      message.success('KOT created successfully');
      setEditModalVisible(false);
      dispatch(fetchActiveBags()); // Refresh the list
    } catch (error) {
      message.error('Failed to create KOT');
    }
  };

  const renderMenuItem = (item) => (
    <Col xs={12} sm={8} md={6} key={item._id}>
      <Card
        hoverable
        size="small"
        title={item.name}
        extra={`₹${item.price?.toFixed(2)}`}
      >
        <Button
          type="primary"
          block
          onClick={() => handleAddItem(item)}
        >
          Add
        </Button>
      </Card>
    </Col>
  );

  if (loading || menuLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
        Error: {error}
      </div>
    );
  }
  const handleViewBill = async (bag) => {
    setSelectedBag(bag);
    try {
      await dispatch(fetchBagKOTs(bag.id)).unwrap();
      setBillModalVisible(true);
    } catch (error) {
      message.error('Failed to load bill details');
    }
  };

  const calculateTotals = () => {
    let subtotal = 0;
    let itemCount = 0;

    bagKOTs.forEach(kot => {
      kot.items.forEach(item => {
        subtotal += item.price * item.quantity;
        itemCount += item.quantity;
      });
    });

    const tax = subtotal * 0.18; // 18% GST
    const total = subtotal + tax;

    return { subtotal, tax, total, itemCount };
  };
  const handleCloseOrder = (bag) => {
    Modal.confirm({
      title: 'Close Order',
      content: 'Are you sure you want to close this order? This will generate the final bill and can\'t be undone.',
      okText: 'Yes, Close Order',
      okType: 'danger',
      cancelText: 'No, Keep Open',
      onOk: async () => {
        try {
          await dispatch(closeBag(bag.id)).unwrap();
          message.success('Order closed successfully');
          dispatch(fetchActiveBags()); // Refresh the list
        } catch (error) {
          message.error('Failed to close order: ' + error.message);
        }
      }
    });
  };


  const BillModal = () => {
    const totals = calculateTotals();

    return (
      <Modal
        title={`Bill Details - Order #${selectedBag?.id?.slice(-4)}`}
        visible={billModalVisible}
        onCancel={() => setBillModalVisible(false)}
        width={800}
        footer={[
          <Button key="close" onClick={() => setBillModalVisible(false)}>
            Close
          </Button>,
          <Button
            key="print"
            type="primary"
            icon={<FilePdfOutlined />}
            onClick={() => message.info('Print functionality to be implemented')}
          >
            Print Bill
          </Button>
        ]}
      >
        {bagKOTsLoading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin />
          </div>
        ) : (
          <div>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Text>Customer: {selectedBag?.userName}</Text>
              <br />
              <Text>Phone: {selectedBag?.userPhone}</Text>
              <br />
              {selectedBag?.table_number && (
                <>
                  <Text>Table: {selectedBag?.table_number}</Text>
                  <br />
                </>
              )}
              <Text>Date: {new Date(selectedBag?.created_at).toLocaleString()}</Text>
            </div>

            {bagKOTs.map((kot, index) => (
              <div key={kot.id} style={{ marginBottom: '20px' }}>
                <Title level={5}>KOT #{index + 1}</Title>
                <Table
                  size="small"
                  pagination={false}
                  dataSource={kot.items}
                  columns={[
                    {
                      title: 'Item',
                      dataIndex: 'name',
                    },
                    {
                      title: 'Qty',
                      dataIndex: 'quantity',
                      width: 80,
                    },
                    {
                      title: 'Price',
                      dataIndex: 'price',
                      width: 100,
                      render: (price) => `₹${price.toFixed(2)}`,
                    },
                    {
                      title: 'Total',
                      width: 120,
                      render: (_, record) => `₹${(record.price * record.quantity).toFixed(2)}`,
                    }
                  ]}
                />
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  Status: {kot.status} | Created: {new Date(kot.created_at).toLocaleString()}
                </Text>
                <Divider />
              </div>
            ))}

            <Row justify="end">
              <Col span={12}>
                <div style={{ textAlign: 'right' }}>
                  <Text>Total Items: {totals.itemCount}</Text>
                  <br />
                  <Text>Subtotal: ₹{totals.subtotal.toFixed(2)}</Text>
                  <br />
                  <Text>GST (18%): ₹{totals.tax.toFixed(2)}</Text>
                  <br />
                  <Title level={4}>Total: ₹{totals.total.toFixed(2)}</Title>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    );
  };

  return (
    <div>
      <h2 style={{ margin: '20px 0' }}>Active Orders ({activeBags.length})</h2>
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        dataSource={activeBags}
        loading={loading}
        renderItem={bag => (
          <List.Item key={bag.id}>
            <Card
              title={`Order #${bag.id?.slice(-4)}`}
              extra={<span style={{ fontSize: '12px' }}>
                {new Date(bag.created_at).toLocaleString()}
              </span>}
            >
              <div style={{ marginBottom: '16px' }}>
                <p><strong>Customer:</strong> {bag.user_name || bag.userName || 'N/A'}</p>
                <p><strong>Phone:</strong> {bag.user_phone || bag.userPhone || 'N/A'}</p>
                <p><strong>Status:</strong> {bag.status}</p>
                {bag.table_number && (
                  <p><strong>Table:</strong> {bag.table_number}</p>
                )}
              </div>

              <div style={{
                display: 'flex',
                gap: '8px',
                flexWrap: 'wrap',
                justifyContent: 'flex-end'
              }}>
                <Button onClick={() => handleViewBill(bag)}>
                  View Bill
                </Button>
                <Button type="primary" onClick={() => showEditModal(bag)}>
                  Add Items
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => handleCloseOrder(bag)}
                >
                  Close Order
                </Button>
              </div>
            </Card>
          </List.Item>
        )}
      />

      <BillModal />

      {/* Edit Items Modal */}
      <Modal
        title={`Add Items to Order #${selectedBag?.id?.slice(-4)}`}
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        width={1200}
        footer={[
          <Button key="cancel" onClick={() => setEditModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSaveAndCreateKOT}
            disabled={selectedItems.length === 0}
          >
            Create KOT
          </Button>
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Input.Search
              placeholder="Search menu items..."
              style={{ marginBottom: 16 }}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
            />
            {/* Menu Categories */}
            <div style={{ marginBottom: 16, display: 'flex', gap: 8, overflowX: 'auto' }}>
              {categories.map(category => (
                <Button
                  key={category}
                  type={selectedCategory === category ? 'primary' : 'default'}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </Button>
              ))}
            </div>

            {/* Menu Items */}
            <Row gutter={[8, 8]}>
              {getFilteredMenuItems().map(renderMenuItem)}
            </Row>
          </Col>

          <Col span={8}>
            <Card title="Selected Items">
              <List
                dataSource={selectedItems}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <InputNumber
                        min={1}
                        value={item.quantity}
                        onChange={(value) => handleQuantityChange(item.itemId, value)}
                      />,
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveItem(item.itemId)}
                      />
                    ]}
                  >
                    <List.Item.Meta
                      title={item.name}
                      description={`₹${(item.price * item.quantity).toFixed(2)}`}
                    />
                  </List.Item>
                )}
              />
              <div style={{ marginTop: 16, textAlign: 'right' }}>
                <strong>Total: ₹
                  {selectedItems.reduce((sum, item) =>
                    sum + (item.price * item.quantity), 0
                  ).toFixed(2)}
                </strong>
              </div>
            </Card>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ActiveOrders;