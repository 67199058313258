import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPermissions } from '../api/cafe'; // Adjust this import path as needed

const PermissionRoute = ({ component: Component, permission }) => {
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const response = await fetchPermissions();
        console.log(response)
        setPermissions(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching permissions:', error);
        navigate('/cafe-admin/login');
      }
    };

    getPermissions();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!permissions || !permissions[permission]) {
    return navigate('/cafe-admin/dashboard');
  }

  return <Component />;
};

export default PermissionRoute;