import { CloseOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import React, { lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getPublicCafeReviewStats } from "../../api/cafe";
import { getCafeMenu } from "../../api/user";
import { useTrackedNavigation } from "../../components/analytics/useTrackedNavigation";
import { setMenu } from "../../redux/slices/user";
import BannerFiller from "../assets/Banner-Filler.webp";
import ViewCartBubble from '../../components/user/ViewCartBubble'

const ItemCard = lazy(() => import("../../components/ItemCard"));
const StarRating = lazy(() => import("../../components/StarRating"));
const BottomMenu = lazy(() => import("../../components/user/BottomMenu"));
const Universalinput = lazy(() => import("../../components/Universalnput"));

const UserMenu = () => {
  const { cafe } = useParams();
  const dispatch = useDispatch();
  const navigate = useTrackedNavigation();

  const cafeData = useSelector((state) => state.user.cafe);
  const menuData = useSelector((state) => state.user.menu);
  const cartData = useSelector((state) => state.user.cart);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState({});
  const [isVeg, setIsVeg] = useState(false);
  const [isNonVeg, setIsNonVeg] = useState(true);
  const [subcategories, setSubcategories] = useState({});
  const [openSubcategories, setOpenSubcategories] = useState({});
  const [search, setSearch] = useState("");
  const [reviewStats, setReviewStats] = useState(null);
  const [hasRecommended, setHasRecommended] = useState(false);
  const [sortedCategories, setSortedCategories] = useState([]);


  const categoryRefs = useRef({});

  useEffect(() => {
    let selectedCafeId;
    if (cafeData) {
      selectedCafeId = cafeData._id;
    }

    if (selectedCafeId) {
      loadCafeProfile(selectedCafeId);
    }
  }, [cafeData]);

  const loadCafeProfile = async (cafeId) => { 
    try {
      const [reviewStats] = await getPublicCafeReviewStats(cafeId);

      setReviewStats(reviewStats.data || null);
    } catch (error) {
      console.error("Error fetching cafe profile information:", error);
      toast.error("Failed to fetch cafe profile information");
    }
  };

  const loadItems = () => {
    getCafeMenu(cafe)
      .then((res) => {
        const menuData = res.data;
        dispatch(setMenu(menuData));
        organizeItemsBySubcategory(menuData);
        setCategories(Object.keys(menuData).filter((cat) => cat !== "All"));
        setItems(menuData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.message === "Cafe is closed")
          navigate("/cafe/closed");
      });
  };

  const organizeItemsBySubcategory = (menuData) => {
    const subcats = {};
    let recommendedItems = [];

    // Sort categories by category_order
    const sortedCategories = Object.entries(menuData)
      .filter(([category]) => category !== "All")
      .sort(([, a], [, b]) => {
        const orderA = a[0]?.category_order ?? Number.MAX_SAFE_INTEGER;
        const orderB = b[0]?.category_order ?? Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });

    sortedCategories.forEach(([category, categoryItems]) => {
      if (category === "Recommended") {
        recommendedItems = [...categoryItems].sort((a, b) => (a.item_order || 0) - (b.item_order || 0));
      } else {
        // Group items by subcategory
        const groupedItems = categoryItems.reduce((acc, item) => {
          if (!acc[item.subcategory]) {
            acc[item.subcategory] = [];
          }
          acc[item.subcategory].push(item);
          return acc;
        }, {});

        // Sort subcategories and items within each subcategory
        subcats[category] = Object.entries(groupedItems)
          .sort(([, a], [, b]) => {
            const orderA = a[0]?.subcategory_order ?? Number.MAX_SAFE_INTEGER;
            const orderB = b[0]?.subcategory_order ?? Number.MAX_SAFE_INTEGER;
            return orderA - orderB;
          })
          .reduce((acc, [subcategory, items]) => {
            acc[subcategory] = items.sort((a, b) => (a.item_order || 0) - (b.item_order || 0));
            return acc;
          }, {});
      }
    });

    setSubcategories(subcats);
    setHasRecommended(recommendedItems.length > 0);
    setSortedCategories(sortedCategories.map(([category]) => category));

    const openSubcats = {};
    Object.keys(subcats).forEach((category) => {
      openSubcats[category] = {};
      Object.keys(subcats[category]).forEach((subcat) => {
        openSubcats[category][subcat] = true;
      });
    });
    setOpenSubcategories(openSubcats);

    // Update items state with sorted data
    const sortedItems = {
      ...menuData,
      Recommended: recommendedItems,
    };
    setItems(sortedItems);
  };


  useEffect(() => {
    loadItems();
  },[]);

  useEffect(() => {
    if (!cafeData._id) navigate("/cafe/all-offers");
  }, [cafeData]);

  const searchFilter = useMemo(
    () =>
      debounce(() => {
        if (search === "") {
          setItems(menuData);
          return;
        }
        const filteredItems = {};
        Object.keys(menuData).forEach((category) => {
          if (category !== "All") {
            filteredItems[category] = menuData[category].filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            );
          }
        });
        setItems(filteredItems);
      }, 300),
    [search, menuData]
  );

  useEffect(() => {
    searchFilter();
  }, [search, menuData]);

  const toggleSubcategory = (cat, subcat) => {
    setOpenSubcategories((prev) => ({
      ...prev,
      [cat]: {
        ...prev[cat],
        [subcat]: !prev[cat][subcat],
      },
    }));
  };

  const scrollToCategory = (category) => {
    const offset = 170; // Adjust this value based on your header height
    const categoryElement = categoryRefs.current[category];

    if (categoryElement) {
      const topPosition =
        categoryElement.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  if (loading)
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center bg-[#FAFAFA]">
        <Helmet
          title="Menu"
          meta={[
            {
              name: "description",
              content: "Your cafe menu",
            },
            {
              property: "og:title",
              content: "Menu",
            },
          ]}
        />
        <img src="../loading.gif" alt="Loading..." />
      </div>
    );
  return (
    <div className="w-full min-h-screen flex flex-col items-center pb-16 bg-[#FAFAFA]">
      <Helmet
        title={`${cafeData.name} Menu`}
        meta={[
          {
            name: "description",
            content: "Your cafe menu",
          },
          {
            property: "og:title",
            content: "Menu",
          },
        ]}
      />
      <Suspense fallback={<div>Loading...</div>}>
        {cafeData.image ? (
          <div className="relative w-full h-80">
            <img
              src={`data:image/png;base64,${cafeData?.image}`}
              alt="banner"
              className="w-full h-80 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 h-full w-full"></div>
            <div className="absolute bottom-4 left-4 z-20">
              <h1 className="text-3xl font-bold text-white">{cafeData.name}</h1>
              <StarRating
                rating={reviewStats?.averageRating}
                count={reviewStats?.totalReviews}
                size={"big"}
                className="mt-2"
              />
            </div>
          </div>
        ) : (
          <div className="relative w-full h-80">
            <img
              src={BannerFiller}
              alt="banner"
              className="w-full h-80 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 h-full w-full"></div>
            <div className="absolute bottom-4 left-4 z-20">
              <h1 className="text-3xl font-bold text-white">{cafeData.name}</h1>
              <StarRating
                rating={reviewStats?.averageRating}
                count={reviewStats?.totalReviews}
                size={"big"}
                className="mt-2"
              />
            </div>
          </div>
        )}

        <div className="w-full px-4 pt-4 flex flex-col items-start justify-center">
          <div className="w-full sticky top-0 z-30 bg-[#FAFAFA] pb-4">
            <div className="py-2 relative">
              <Universalinput
                type="text"
                placeholder="What to eat today?"
                value={search}
                className="w-full h-[40px]"
                onChange={(e) => setSearch(e.target.value)}
                icon={SearchOutlined}
              />
              {search.length > 0 && (
                <CloseOutlined
                  onClick={() => setSearch("")}
                  className="absolute right-6 top-1/2 transform -translate-y-1/2"
                />
              )}
            </div>
            <div className="flex flex-row gap-4 mt-2 mb-4 w-full justify-start">
              <button
                onClick={() => setIsVeg(!isVeg)}
                className={`flex items-center px-3 py-1 rounded-[10px] border text-xs border-[#000000] ${
                  isVeg ? "bg-[#9277FF] text-white" : "bg-white text-gray-700"
                }`}
                style={{ boxShadow: "2px 2px 0px 0px #000000" }}
              >
                <div
                  className={`w-4 h-4 rounded-full mr-2 border border-green-400`}
                >
                  <div
                    className={`w-2 h-2 rounded-[10px] mt-[3px] ml-[3px] ${"bg-green-400"}`}
                  ></div>
                </div>
                Veg
              </button>
              <button
                onClick={() => setIsNonVeg(!isNonVeg)}
                className={`flex items-center px-3 py-1 rounded-[10px] border text-xs border-[#000000] ${
                  isNonVeg ? "bg-white text-gray-700" : "bg-[#9277FF] text-white"
                }`}
                style={{ boxShadow: "2px 2px 0px 0px #000000" }}
              >
                <div
                  className={`w-4 h-4 rounded-full mr-2 border border-[#b00000]`}
                >
                  <div
                    className={`w-2 h-2 rounded-[10px] mt-[3px] ml-[3px] ${"bg-[#b00000]"}`}
                  ></div>
                </div>
                Non-Veg
              </button>
            </div>
            <div className="w-full mb-1 flex flex-col gap-4">
              <div className="w-full flex gap-2 flex-nowrap overflow-x-auto">
                {categories
                  .filter((cat) => cat !== "Recommended" || hasRecommended)
                  .map((cat) => (
                    <button
                      key={cat}
                      onClick={() => scrollToCategory(cat)}
                      className="whitespace-nowrap px-4 py-2 rounded-full border text-xs border-gray-400 text-gray-700"
                    >
                      {cat === "Recommended" ? "Chef's Special" : cat}
                    </button>
                  ))}
              </div>
            </div>
          </div>

          {categories
            .filter((category) => category !== "Recommended" || hasRecommended)
            .map((category) =>
              search.length > 0 ? (
                <div className="pl-4">
                  {items[category]?.map((item) => (
                    <ItemCard
                      key={item._id}
                      item={item}
                      isVeg={isVeg}
                      isNonVeg={isNonVeg}
                    />
                  ))}
                </div>
              ) : (
                <div
                  key={category}
                  className="w-full mb-4"
                  ref={(el) => (categoryRefs.current[category] = el)}
                >
                  <h2 className="text-lg font-bold mt-4 mb-2">
                    {category === "Recommended" ? "Chef's Special" : category}
                  </h2>

                  {category === "Recommended" ? (
                    <div className="pl-4">
                      {items[category].map((item) => (
                        <ItemCard
                          key={item._id}
                          item={item}
                          isVeg={isVeg}
                          isNonVeg={isNonVeg}
                        />
                      ))}
                    </div>
                  ) : // Check if subcategories exist and are not null
                  subcategories[category] &&
                    Object.keys(subcategories[category]).length > 0 ? (
                    Object.keys(subcategories[category]).map((subcat) => (
                      <div key={subcat} className="w-full">
                        {subcat != "null" && (
                          <button
                            className="w-full text-left px-1 py-2 text-[#3E4462] text-sm font-semibold flex justify-between items-center"
                            onClick={() => toggleSubcategory(category, subcat)}
                          >
                            {subcat[0].toUpperCase() + subcat.slice(1)}
                            <DownOutlined
                              className={`transform transition-transform ${
                                openSubcategories[category]?.[subcat]
                                  ? "rotate-180"
                                  : "rotate-0"
                              }`}
                            />
                          </button>
                        )}

                        {openSubcategories[category]?.[subcat] && (
                          <div className="pl-4">
                            {subcategories[category][subcat].map((item) => (
                              <ItemCard
                                key={item._id}
                                item={item}
                                isVeg={isVeg}
                                isNonVeg={isNonVeg}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    // If no subcategories, render items directly under the category
                    <div className="pl-4">
                      {items[category].map((item) => (
                        <ItemCard
                          key={item._id}
                          item={item}
                          isVeg={isVeg}
                          isNonVeg={isNonVeg}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )
            )}
        </div>

        {cartData.length > 0 && (
          <div className="flex flex-col fixed bottom-16 left-0 right-0 py-4 bg-white w-full items-center">
            <Link
              to={"/cafe/bag"}
              className="flex items-center justify-center mx-4 w-80 h-16 bg-[#FD7347] text-white py-2 px-4 rounded-2xl"
            >
              Go to Bag
            </Link>
          </div>
        )}
        <ViewCartBubble/>
        <BottomMenu />
      </Suspense>
    </div>
  );
};

export default UserMenu;