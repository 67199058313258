import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

export const sendSuperadminAlert = async (alertData) => {
  try {
    const response = await axios.post(`${API_URL}/api/superadmin/alerts`, alertData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('superadminToken')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error sending superadmin alert:', error);
    throw error;
  }
};

export const fetchCafeStructure = async (cafeId) => {
  try {
    const response = await axios.get(`${API_URL}/api/superadmin/cafe-structure/${cafeId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('superadminToken')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching cafe structure:', error);
    throw error;
  }
};

export const updateStructure = async (cafeId, structure) => {
  try {
    const response = await axios.post(`${API_URL}/api/superadmin/update-structure`, 
      { cafeId, structure },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('superadminToken')}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating structure:', error);
    throw error;
  }
};

export const createFoodcourt = async (foodcourtData) => {
  try {
    const response = await axios.post(`${API_URL}/api/superadmin/foodcourt`, foodcourtData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('superadminToken')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating foodcourt:', error);
    throw error;
  }
};

export const associateCafeWithFoodcourt = async (foodcourtId, cafeId) => {
  try {
    const response = await axios.post(`${API_URL}/api/superadmin/foodcourt/associate-cafe`, 
      { foodcourtId, cafeId },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('superadminToken')}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error associating cafe with foodcourt:', error);
    throw error;
  }
};

export const getFoodcourts = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/superadmin/foodcourts`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('superadminToken')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching foodcourts:', error);
    throw error;
  }
};
