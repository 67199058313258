import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import menu from "../../pages/assets/fluent_food-32-regular.svg";
import star from "../../pages/assets/ic_round-star-rate.svg";
import offer from "../../pages/assets/bxs_offer.svg";
import menu1 from "../../pages/assets/fluent_food-32-regular2.svg";
import star1 from "../../pages/assets/ic_round-star-rate2.svg";
import offer1 from "../../pages/assets/bxs_offer2.svg";
import profile from "../../pages/assets/fluent_apps-list-detail-20-regular (1).svg";
import profile2 from "../../pages/assets/fluent_apps-list-detail-20-regular.svg";

const BottomMenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [cafeId, setCafeId] = useState("");

  useEffect(() => {
    const storedCafe = sessionStorage.getItem("OG_cafe");
    if (storedCafe) {
      const parsedCafe = JSON.parse(storedCafe);
      setCafeId(parsedCafe._id);
    }
  }, []);

  const menuItems = [
    { path: "/cafe/menu", label: "Menu", icon: menu1, activeIcon: menu },
    { path: "/cafe/review", label: "Rating", icon: star, activeIcon: star1 },
    {
      path: `/cafe/${cafeId}/offers`,
      label: "Offers",
      icon: offer,
      activeIcon: offer1,
    },
    {
      path: "/cafe/profile",
      label: "Profile",
      icon: profile2,
      activeIcon: profile,
    },
  ];

  return (
    <div className="fixed  bottom-0 left-0 right-0 z-50 flex items-center px-8 justify-between py-3 w-full border-t shadow-xl bg-white ">
      {menuItems.map((item) => (
        <div
          key={item.path}
          className={`flex flex-col justify-center w-[60px] items-center ${
            currentPath === item.path
              ? "text-white rounded-lg bg-purple-500"
              : ""
          }`}
          style={{
            boxShadow: `${
              currentPath === item.path && "4px 5px 0px 0px #000000"
            }`,
          }}
        >
          <Link
            to={item.path}
            className="flex flex-col items-center justify-center"
          >
            <img
              src={currentPath === item.path ? item.activeIcon : item.icon}
              className={`mb-1 mt-1`}
              style={{ width: "40px", height: "40px" }} // Adjust the size here
              alt={item.label}
            />
            <p
              className={`text-xs ${
                currentPath === item.path ? "font-bold" : ""
              }`}
            >
              {item.label}
            </p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default BottomMenu;
