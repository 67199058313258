import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const FoodcourtInit = () => {
  const navigate = useNavigate();
  const { foodcourtId } = useParams();

  useEffect(() => {
    const name = localStorage.getItem("OG_name");
    const phone = localStorage.getItem("OG_phone");

    if (name && phone) {
      // User is registered, navigate to cafe list
      navigate(`/foodcourt/${foodcourtId}/cafes`);
    } else {
      // User is not registered, navigate to register page
      navigate(`/cafe/register`, { state: { isFoodcourt: true, foodcourtId } });
    }
  }, [foodcourtId, navigate]);

  return <div>Loading...</div>;
};

export default FoodcourtInit;