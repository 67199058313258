import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPOSMenuItems, addToCart, removeFromCart, updateCartItemQuantity, createKOT, clearCart } from '../../redux/slices/posSlice';
import { Row, Col, Card, Button, List, InputNumber, message, Input } from 'antd';

const POSMenu = () => {
  const [menuItems, setMenuItems] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  
  const cartItems = useSelector(state => state.pos.cart);
  const currentBag = useSelector(state => state.pos.currentBag);

  useEffect(() => {
    const loadMenuItems = async () => {
      try {
        setLoading(true);
        const data = await dispatch(fetchPOSMenuItems()).unwrap();
        setMenuItems(data);
      } catch (err) {
        console.error("Failed to load menu items:", err);
        message.error("Failed to load menu items. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    loadMenuItems();
  }, [dispatch]);

  const categories = ['All', ...(Object.keys(menuItems) || [])];

  const renderItems = () => {
    if (selectedCategory === 'All') {
      return Object.values(menuItems).flat().filter(Boolean).map(renderMenuItem);
    }
    return (menuItems[selectedCategory] || []).filter(Boolean).map(renderMenuItem);
  };

  const renderMenuItem = (item) => {
    if (!item) return null;
    return (
      <Col xs={12} sm={8} md={6} lg={4} xl={3} key={item._id} style={{ marginBottom: '16px' }}>
        <Card
          hoverable
        >
          <Card.Meta title={item.name} description={`₹${item.price?.toFixed(2) || 'N/A'}`} />
          <Button 
            type="primary" 
            style={{ marginTop: '10px' }}
            onClick={() => dispatch(addToCart(item))}
          >
            Add to Cart
          </Button>
        </Card>fixed multiple orders in single kot
      </Col>
    );
  };

  const handleQuantityChange = (item, newQuantity) => {
    dispatch(updateCartItemQuantity({ itemId: item._id, quantity: newQuantity }));
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCart(itemId));
  };
  const getFilteredItems = () => {
    let items = [];
    if (selectedCategory === 'All') {
      items = Object.values(menuItems).flat().filter(Boolean);
    } else {
      items = (menuItems[selectedCategory] || []).filter(Boolean);
    }
    
    if (!searchQuery.trim()) return items;
    
    return items.filter(item => 
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleSendKOT = () => {
    if (currentBag && cartItems.length > 0) {
      dispatch(createKOT({
        bagId: currentBag.id,
        items: cartItems.map(item => ({
          itemId: item._id,
          quantity: item.quantity,
          notes: item.notes || ''
        }))
      })).then(() => {
        message.success('KOT sent to kitchen');
        dispatch(clearCart());
      }).catch((error) => {
        message.error('Failed to send KOT: ' + error.message);
      });
    } else {
      message.error('No items in the cart or no active order');
    }
  };

  const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

  if (loading) return <div style={{ textAlign: 'center', padding: '16px' }}>Loading menu items...</div>;

  if (Object.keys(menuItems).length === 0) {
    return <div style={{ textAlign: 'center', padding: '16px' }}>No menu items available.</div>;
  }

  return (
    <Row gutter={16}>
      <Col span={18}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Input.Search
          placeholder="Search menu items..."
          style={{ marginBottom: 16 }}
          onChange={(e) => setSearchQuery(e.target.value)}
          allowClear
        />
          <div style={{ 
            display: 'flex', 
            overflowX: 'auto', 
            marginBottom: '16px', 
            paddingBottom: '8px', 
            position: 'sticky', 
            top: 0, 
            background: '#fff', 
            zIndex: 10 
          }}>
            {categories.map(category => (
              <Button
                key={category}
                type={selectedCategory === category ? 'primary' : 'default'}
                style={{ marginRight: '8px', whiteSpace: 'nowrap' }}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </Button>
            ))}
          </div>
          <div style={{ flexGrow: 1, overflowY: 'auto' }}>
            <Row gutter={[16, 16]}>
            {getFilteredItems().map(renderMenuItem)}
            </Row>
          </div>
        </div>
      </Col>
      <Col span={6}>
        <Card title="Cart" extra={<Button type="primary" onClick={handleSendKOT}>Send KOT</Button>}>
          <List
            itemLayout="horizontal"
            dataSource={cartItems}
            renderItem={item => (
              <List.Item
                actions={[
                  <InputNumber
                    min={1}
                    value={item.quantity}
                    onChange={(value) => handleQuantityChange(item, value)}
                  />,
                  <Button onClick={() => handleRemoveItem(item._id)}>Remove</Button>
                ]}
              >
                <List.Item.Meta
                  title={item.name}
                  description={`₹${(item.price * item.quantity).toFixed(2)}`}
                />
              </List.Item>
            )}
          />
          <div style={{ marginTop: '16px', fontWeight: 'bold' }}>
            Total: ₹{total.toFixed(2)}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default POSMenu;