import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addNewOrder, updateOrderStatus } from '../redux/slices/admin';

const WebSocketConnection = () => {
  const dispatch = useDispatch();

  const handleNewOrder = useCallback((order) => {
    dispatch(addNewOrder(order));
  }, [dispatch]);

  const handleOrderUpdate = useCallback((updatedOrder) => {
    dispatch(updateOrderStatus(updatedOrder));
  }, [dispatch]);

  useEffect(() => {
    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'NEW_ORDER') {
        handleNewOrder(data.order);
      } else if (data.type === 'ORDER_UPDATE') {
        handleOrderUpdate(data.order);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.close();
    };
  }, [handleNewOrder, handleOrderUpdate]);

  return null;
};

export default WebSocketConnection;