import React, { useState, useEffect } from "react";
import { getAllOfferAnalytics } from "../../api/cafe";
import Header from "../../components/Header";
import BottomMenu from "../../components/BottomMenu";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PlusOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay";
import { subDays, subMonths, subYears } from "date-fns";
import AllOffersAnalyticsCard from "./AllOffersAnalyticsCard";

const OfferSection = ({ title, offers, showMore, onShowMore, onStatusChange }) => {
  return (
    <div className="w-full">
      <h2 className="font-bold text-xl mt-6 mb-4">{title}</h2>
      {offers.map((offer) => (
        <AllOffersAnalyticsCard
          key={offer.offerDetails.id}
          offer={offer}
          onStatusChange={onStatusChange}
        />
      ))}
      {showMore && (
        <button
          onClick={onShowMore}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Load More
        </button>
      )}
    </div>
  );
};

const AllOfferAnalytics = () => {
  const [analytics, setAnalytics] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [activeOffers, setActiveOffers] = useState([]);
  const [inactiveOffers, setInactiveOffers] = useState([]);
  const [activeOffersToShow, setActiveOffersToShow] = useState(3);
  const [inactiveOffersToShow, setInactiveOffersToShow] = useState(3);
  const [range, setRange] = useState("Last 7 Days");
  const [sortMethod, setSortMethod] = useState("dateDesc");

  console.log("Analyticss: ", activeOffers);

  useEffect(() => {
    if (range !== "Custom Date") {
      setDatesForRange(range);
    }
  }, [range]);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (analytics.length > 0) {
      const sortedAnalytics = sortOffers(analytics, sortMethod);
      const active = sortedAnalytics.filter((offer) => offer.offerDetails.isActive);
      const inactive = sortedAnalytics.filter((offer) => !offer.offerDetails.isActive);
      setActiveOffers(active);
      setInactiveOffers(inactive);
    }
  }, [analytics, sortMethod]);

  const setDatesForRange = (range) => {
    const today = new Date();
    switch (range) {
      case "Today":
        setStartDate(today);
        setEndDate(today);
        break;
      case "Last Week":
        setStartDate(subDays(today, 7));
        setEndDate(today);
        break;
      case "Last Month":
        setStartDate(subMonths(today, 1));
        setEndDate(today);
        break;
      case "Last Year":
        setStartDate(subYears(today, 1));
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getAllOfferAnalytics(
        startDate.toISOString(),
        endDate.toISOString()
      );
      if (response.success) {
        setAnalytics(response.data);
      } else {
        setError(response.message || "Failed to fetch offer analytics");
      }
    } catch (err) {
      setError("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const getEngagementRate = (offer) => {
    if (offer.totalViews === 0) return 0;
    return (offer.uniqueUsers / offer.totalViews) * 100;
  };

  const sortOffers = (offers, method) => {
    switch (method) {
      case "engagement":
        return [...offers].sort((a, b) => getEngagementRate(b) - getEngagementRate(a));
      case "dateAsc":
        return [...offers].sort((a, b) => new Date(a.offerDetails.createdAt) - new Date(b.offerDetails.createdAt));
      case "dateDesc":
        return [...offers].sort((a, b) => new Date(b.offerDetails.createdAt) - new Date(a.offerDetails.createdAt));
      default:
        return offers;
    }
  };

  const handleSortChange = (method) => {
    setSortMethod(method);
  };

  if (isLoading) return <LoadingOverlay show={isLoading} />;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="w-full min-h-screen flex flex-col items-center gap-3 pb-20 mb-5">
      <Header />
      <Helmet>
        <title>Campaign Analytics</title>
      </Helmet>
      <div className="w-full text-2xl font-bold text-left flex items-start mt-4">
        Campaign Analytics
      </div>
      <div className="flex items-center gap-x-4 justify-center w-full max-w-xs px-6 py-4 mt-4 font-semibold text-black bg-white border border-black rounded-md shadow-[2px_3px_0px_0px_rgba(0,0,0,1)] cursor-pointer">
        <PlusOutlined className="w-5 h-5 mr-2" />
        <button onClick={() => navigate("/cafe-admin/edit-profile")}>
          Create New Campaign
        </button>
      </div>

      <p className="w-full text-left my-4 text-lg">
        Monitor the performance of your campaigns and make data-driven decisions.
      </p>

      <div className="flex items-center justify-between gap-x-4 w-full">
        <div className="flex items-center justify-center gap-4 mb-4">
          <select
            value={range}
            onChange={(e) => setRange(e.target.value)}
            className="bg-gray-200 p-2 rounded"
          >
            <option value="Today">Today</option>
            <option value="Last Week">Last 7 Days</option>
            <option value="Last Month">Last 30 Days</option>
            <option value="Last Year">Last Year</option>
            <option value="Custom Date">Custom Date</option>
          </select>
        </div>
        <div className="flex items-center justify-center gap-4 mb-4">
          <select
            value={sortMethod}
            onChange={(e) => handleSortChange(e.target.value)}
            className="bg-gray-200 p-2 rounded"
          >
            <option value="engagement">Sort by Engagement</option>
            <option value="dateAsc">Sort by Date (Oldest First)</option>
            <option value="dateDesc">Sort by Date (Newest First)</option>
          </select>
        </div>
      </div>
      {range === "Custom Date" && (
        <div className="flex flex-row items-center justify-center gap-4 mt-2 w-full">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="bg-gray-200 p-2 rounded w-[130px]"
            dateFormat="dd/MM/yyyy"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="bg-gray-200 p-2 rounded w-[130px]"
            dateFormat="dd/MM/yyyy"
          />
        </div>
      )}
      <div className="flex flex-col items-start justify-center w-full">
        <OfferSection
          title="Active Campaigns"
          offers={activeOffers.slice(0, activeOffersToShow)}
          showMore={activeOffersToShow < activeOffers.length}
          onShowMore={() => setActiveOffersToShow((prev) => prev + 3)}
          onStatusChange={fetchData}
        />

        <OfferSection
          title="Inactive Campaigns"
          offers={inactiveOffers.slice(0, inactiveOffersToShow)}
          showMore={inactiveOffersToShow < inactiveOffers.length}
          onShowMore={() => setInactiveOffersToShow((prev) => prev + 3)}
          onStatusChange={fetchData}
        />

        {analytics.length === 0 && (
          <div className="w-full max-w-4xl bg-white rounded-lg shadow-md p-4 m-2">
            <h2 className="font-bold text-xl mb-3">No Campaigns</h2>
            <p>
              You haven't created any campaigns yet, or there's no analytics data for the selected date range.
            </p>
            <p className="mt-2">
              Create a new campaign to start attracting customers and tracking your success!
            </p>
          </div>
        )}
      </div>

      <BottomMenu page="campaigns" />
    </div>
  );
};

export default AllOfferAnalytics;