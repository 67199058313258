// src/services/websocketService.js

class WebSocketService {
    constructor() {
      this.ws = null;
      this.subscribers = new Map();
      this.connectionAttempts = 0;
      this.maxAttempts = 5;
    }
  
    connect(cafeId) {
      // Use secure WebSocket for production, regular for localhost
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const host = window.location.host;
      
      // Use the same host as your API
      const wsUrl = `${protocol}//${host}/ws/cafe/${cafeId}`;
      
      console.log('Connecting to WebSocket:', wsUrl);
  
      this.ws = new WebSocket(wsUrl);
  
      this.ws.onopen = () => {
        console.log('WebSocket connected successfully');
        this.connectionAttempts = 0;
      };
  
      this.ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data);
          this.handleMessage(data);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
  
      this.ws.onclose = () => {
        console.log('WebSocket connection closed');
        this.attemptReconnect(cafeId);
      };
  
      this.ws.onerror = (error) => {
        console.error('WebSocket connection error:', error);
        if (error.target) {
          console.log('WebSocket state:', error.target.readyState);
          console.log('WebSocket URL:', error.target.url);
        }
      };
    }
  
    attemptReconnect(cafeId) {
      if (this.connectionAttempts < this.maxAttempts) {
        this.connectionAttempts++;
        const timeout = Math.min(1000 * Math.pow(2, this.connectionAttempts), 30000);
        console.log(`Attempting to reconnect in ${timeout}ms... Attempt ${this.connectionAttempts}`);
        setTimeout(() => {
          console.log(`Reconnecting to WebSocket... Attempt ${this.connectionAttempts}`);
          this.connect(cafeId);
        }, timeout);
      } else {
        console.log('Max reconnection attempts reached');
      }
    }
  
    subscribe(type, callback) {
      if (!this.subscribers.has(type)) {
        this.subscribers.set(type, new Set());
      }
      this.subscribers.get(type).add(callback);
      console.log(`Subscribed to ${type} events`);
  
      // Return unsubscribe function
      return () => {
        const callbacks = this.subscribers.get(type);
        if (callbacks) {
          callbacks.delete(callback);
          console.log(`Unsubscribed from ${type} events`);
        }
      };
    }
  
    handleMessage(message) {
      const { type, payload } = message;
      console.log(`Handling message of type: ${type}`, payload);
      const callbacks = this.subscribers.get(type);
      if (callbacks) {
        callbacks.forEach(callback => callback(payload));
      }
    }
  
    sendMessage(type, payload) {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        const message = JSON.stringify({ type, payload });
        console.log('Sending WebSocket message:', message);
        this.ws.send(message);
      } else {
        console.error('WebSocket is not connected. ReadyState:', this.ws?.readyState);
        if (this.ws) {
          console.log('Current WebSocket state:', {
            readyState: this.ws.readyState,
            bufferedAmount: this.ws.bufferedAmount,
            url: this.ws.url
          });
        }
      }
    }
  
    disconnect() {
      console.log('Disconnecting WebSocket');
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }
      this.subscribers.clear();
      this.connectionAttempts = 0;
    }
  }
  
  export const wsService = new WebSocketService();