import React, { useState, useEffect } from 'react'
import { CloseOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";

const ItemInCart = ({ cartItem, removeFromBag, updateQuantity }) => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (cartItem.quantity > 0) setCount(cartItem.quantity)
  }, [cartItem.quantity])
  return (
    <div className="flex justify-between w-full py-6 mx-4 border-b border-dashed border-gray-400">
      <div className='flex items-center'>
        {cartItem.image && <img
          height={80}
          width={80}
          src={`data:image/png;base64,${cartItem.image}`}
          alt={cartItem.name}
          className="object-cover rounded-md h-20 w-20"
        />}
        <div className="flex flex-col pl-4 items-start">
          <h1 className="font-semibold text-lg text-left">{cartItem.name}</h1>
          <p className="my-1 text-sm">Variant: {cartItem.variant}</p>
          <h1 className="font-semibold text-lg">₹{cartItem.price}</h1>
        </div>
      </div>
      <div className="flex flex-col w-1/3 justify-between items-end">
        <CloseOutlined onClick={() => removeFromBag(cartItem.id)} className="text-xs" />
        <div className="flex mb-2 ">
          <button
            onClick={() => { setCount(count - 1); updateQuantity(cartItem.id, count - 1) }}
            disabled={count === 1}
            className="disabled:bg-[#ed6e42] bg-[#FD7347] text-white h-10 w-8 flex items-center justify-center font-bold rounded-l-md"
          >
            <MinusOutlined />
          </button>
          <p
            className={`w-16 h-10 bg-[#FD7347] text-white flex items-center justify-center text-md font-semibold`}
          >
            {count}
          </p>
          <button
            onClick={() => { setCount(count + 1); updateQuantity(cartItem.id, count + 1) }}
            className="disabled:bg-sky-200 bg-[#FD7347] text-white h-10 w-8 flex items-center justify-center font-bold rounded-r-md"
          >
            <PlusOutlined />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ItemInCart