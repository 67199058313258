import React, { useState, useEffect } from "react";
import Toaster from "../../components/Toaster";
import { toast } from "react-toastify";
import { getPincode, registerCafe } from "../../api/cafe";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../api/auth";
import { setUser } from "../../redux/slices/auth";
import { Helmet } from "react-helmet";
import Universalinput from "../../components/Universalnput"

const RegisterCafe = () => {
  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("OG_token");
    if (!token) {
      navigate("/cafe-admin/signup");
    }
  }, []);

  function fetchCity(val) {
    setState("Fetching...");
    setCity("Fetching...");
    getPincode(pincode).then((data) => {
      if (data.Status === "Success") {
        setCity(data.PostOffice[0].District);
        setState(data.PostOffice[0].State);
      } else {
        setCity("");
        setState("");
        toast.error("Invalid pincode");
        setPincode(val);
      }
    });
  }

  function pincodeChange(e) {
    if (pincode.length === 6) {
      fetchCity(e.target.value);
    } else {
      setPincode(e.target.value);
    }
  }

  async function submitDetails(e) {
    e.preventDefault();
    const resp = await registerCafe({
      name,
      address: addressLine1 + ", " + addressLine2,
      pincode,
      city,
      state,
      phone,
    });
    if (resp.success) {
      toast.success("Cafe registered successfully");

      const cafe = await fetchUser();
      dispatch(setUser(cafe.data));
      navigate("/cafe-admin/profile");
    }
  }
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <form
        onSubmit={submitDetails}
        className="w-full h-screen flex flex-col justify-center items-center"
      >
        <Helmet title="Register Cafe" />
        <Toaster />
        <img src="../Cafe-admin.png" alt="Hero" />
        <h1 className="text-xl">Congratulations!</h1>
        <p className="text-blue-400 mt-2 mb-5">
          Here you can setup your cafe on Orderji
        </p>
        <Universalinput
          type="text"
          placeholder="Enter Cafe's name"
          className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
          autoComplete="off"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Universalinput
          type="number"
          placeholder="Enter cafe's phone"
          className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
          autoComplete="off"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
       <Universalinput
          type="text"
          placeholder="Enter cafe's address line 1"
          className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
          autoComplete="off"
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
          required
        />
       <Universalinput
          type="text"
          placeholder="Enter cafe's address line 2"
          className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
          autoComplete="off"
          value={addressLine2}
          onChange={(e) => setAddressLine2(e.target.value)}
          required
        />
      <Universalinput
          type="number"
          placeholder="Enter your pincode"
          className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
          autoComplete="off"
          value={pincode}
          onBlur={fetchCity}
          onChange={pincodeChange}
          required
        />
       <Universalinput
          type="text"
          placeholder="Enter your city"
          className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
          autoComplete="off"
          disabled={city === "Fetching..."}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          required
        />
      <Universalinput
          type="text"
          placeholder="Enter state"
          className="border w-3/4 mx-3 text-md my-1 py-2 text-center rounded-md outline-none shadow-sm"
          autoComplete="off"
          disabled={state === "Fetching..."}
          value={state}
          onChange={(e) => setState(e.target.value)}
          required
        />
        <button
          type="submit"
          className="bg-[#FD7347] text-white px-8 py-2 block mt-5 rounded-md shadow-md"
        >
          Continue
        </button>
      </form>
    </div>
  );
};

export default RegisterCafe;
