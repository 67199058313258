import React, { useState, useEffect } from "react";
import { registerUserforoffer } from "../../api/user";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useTrackedNavigation } from "../../components/analytics/useTrackedNavigation";
import Universalinput from "../../components/Universalnput";

const Register = () => {
  const [name, setName] = useState(localStorage.getItem("OG_name") || "");
  const [phone, setPhone] = useState(localStorage.getItem("OG_phone") || "");
  // const navigate = useNavigate();
  const navigate = useTrackedNavigation(); // Use the new hook instead of useNavigate

  function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();
    let platform = navigator?.userAgentData?.platform || navigator?.platform;

    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(platform)
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    const name = localStorage.getItem("OG_name");
    const phone = localStorage.getItem("OG_phone");
    if (name && phone) {
      try {
        registerUserforoffer({
          name,
          phone,
        });
      } catch (error) {
        navigate("/cafe/closed");
      }
      navigate("/cafe/Offers");
    }
  }, []);

  async function requestNotificationPermission() {
    if (isIOS()) return; // iOS doesn't support web push notifications

    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          try {
            const registration = await navigator.serviceWorker.register(
              "../service-worker.js",
              { scope: "/" }
            );
            console.log("Service Worker Registered", registration);

            // Subscribe the user to push notifications
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
            });

            // Send the subscription to your server
            await fetch(
              `${process.env.REACT_APP_BASE_URL}/api/user/subscribe`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ subscription, phone }),
              }
            );

            console.log("Push Notification Subscription: ", subscription);
          } catch (err) {
            console.error("Service Worker registration failed: ", err);
          }
        }
      }
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (phone.length !== 10 || isNaN(JSON.parse(phone))) {
      toast.error("Please enter valid phone");
      return;
    }
    localStorage.setItem("OG_name", name);
    localStorage.setItem("OG_phone", phone);
    try {
      await registerUserforoffer({
        name,
        phone,
      });
      await requestNotificationPermission();
      navigate("/cafe/Offers");
    } catch (error) {
      navigate("/cafe/closed");
    }
  }

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <img
        src="../Cafe-admin.png"
        alt="Hero"
      />
      <Toaster />
      <h1 className="text-xl mb-1">Register yourself</h1>
      <h2 className="text-sm">welcome to the order G</h2>
      <form
        onSubmit={onSubmit}
        className="my-8 w-full flex flex-col items-center"
      >
        <Universalinput
          type="text"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
          className="bg-gray-100 rounded-full w-80 p-4 my-1"
          placeholder="Your Name"
        />
        <div className="bg-gray-100 rounded-full w-80 p-4 my-1 flex">
          <p className="border-r-2 w-fit pr-2 border-gray-400">+91</p>
          <Universalinput
            type="number"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
            className="bg-gray-100 pl-2 outline-none focus:outline-none"
            placeholder="Your Phone"
          />
        </div>
        <button className="bg-[#FD7347] text-white rounded-full w-80 p-4 mt-4">
          Continue
        </button>
      </form>
    </div>
  );
};

export default Register;
