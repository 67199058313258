// TableGrid.jsx - Component for displaying and managing restaurant tables and their orders
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Modal, Form, Input, Button, message, Spin, Divider, Typography, Table, Space, List, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { createUserBag, getCafeDetails } from '../../api/pos';
import { 
  setCurrentBag, 
  fetchActiveBags,
  createKOTFromBagItems,
  fetchBagKOTs,
  closeBag,
  fetchPOSMenuItems
} from '../../redux/slices/posSlice';
import { FilePdfOutlined, DeleteOutlined } from '@ant-design/icons';
import { PrinterOutlined } from '@ant-design/icons';
import { wsService } from './services/websocket';


const { Text, Title } = Typography;
const { Search } = Input;


const TableGrid = ({ onOrderCreated }) => {
  // State management
  const [tables, setTables] = useState([]); // Array of table numbers
  const [selectedTable, setSelectedTable] = useState(null); // Currently selected table
  const [isModalVisible, setIsModalVisible] = useState(false); // New order modal visibility
  const [billModalVisible, setBillModalVisible] = useState(false); // Bill modal visibility
  const [editModalVisible, setEditModalVisible] = useState(false); // Edit order modal visibility
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedItems, setSelectedItems] = useState([]); // Items in current order
  const [selectedBag, setSelectedBag] = useState(null); // Currently selected order/bag
  const [form] = Form.useForm(); // Form instance for new orders
  const [menuItems, setMenuItems] = useState({});
  const [menuLoading, setMenuLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [unviewedKOTs, setUnviewedKOTs] = useState(new Set());
  const [kotsModalVisible, setKotsModalVisible] = useState(false);


  const TABLE_COLORS = {
    FREE: '#52c41a',
    OCCUPIED: '#ff4d4f',
    NEW_ORDER: '#faad14'
  };

  // Redux
  const dispatch = useDispatch();
  const activeBags = useSelector(state => state.pos.activeBags);
  const bagKOTs = useSelector(state => state.pos.currentBagKOTs);
  const bagKOTsLoading = useSelector(state => state.pos.bagKOTsLoading);


  const loadMenuItems = async () => {
    try {
      setMenuLoading(true);
      const data = await dispatch(fetchPOSMenuItems()).unwrap();
      setMenuItems(data);
    } catch (err) {
      console.error("Failed to load menu items:", err);
      message.error("Failed to load menu items");
    } finally {
      setMenuLoading(false);
    }
  };

  // Initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        // Fetch both cafe details and active orders simultaneously
        const [cafeDetails] = await Promise.all([
          getCafeDetails(),
          dispatch(fetchActiveBags()).unwrap(),
          loadMenuItems()

        ]);

        const totalTables = cafeDetails.cafe.total_tables || 0;
        if (totalTables === 0) {
          message.warning('No tables configured for this cafe');
        }

        // Generate array of table numbers
        const tableArray = Array.from({ length: totalTables }, (_, i) => i + 1);
        setTables(tableArray);
        wsService.connect(cafeDetails.cafe._id);

      } catch (error) {
        console.error('Error loading initial data:', error);
        message.error('Failed to load tables configuration');
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();

    const unsubscribeNewOrder = wsService.subscribe('NEW_ORDER', handleNewOrder);

    return () => {
      unsubscribeNewOrder();
      wsService.disconnect();
    };
  }, [dispatch]);

  const handleNewOrder = (orderData) => {
    // Add to unviewed KOTs
    setUnviewedKOTs(prev => new Set(prev).add(orderData.bagId));
    
    // Play notification sound if available
    // const audio = new Audio('/notification-sound.mp3');
    // audio.play().catch(e => console.log('Error playing sound:', e));
    
    // Show notification
    console.log(`New order for Table ${orderData.tableNumber}!`, 3);
    
    // Refresh active bags
    dispatch(fetchActiveBags());
  };

  const categories = ['All', ...(Object.keys(menuItems) || [])];

  const getMenuItemsToShow = () => {
    if (selectedCategory === 'All') {
      return Object.values(menuItems).flat().filter(Boolean);
    }
    return (menuItems[selectedCategory] || []).filter(Boolean);
  };

  const getFilteredMenuItems = () => {
    const itemsToFilter = getMenuItemsToShow();
    if (!searchQuery.trim()) return itemsToFilter;
    return itemsToFilter.filter(item =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleAddItem = (menuItem) => {
    const existingItem = selectedItems.find(item => item.itemId === menuItem._id);
    if (existingItem) {
      setSelectedItems(selectedItems.map(item =>
        item.itemId === menuItem._id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      ));
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          key: Date.now(),
          itemId: menuItem._id,
          name: menuItem.name,
          quantity: 1,
          price: menuItem.price,
          notes: ''
        }
      ]);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter(item => item.itemId !== itemId));
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    setSelectedItems(selectedItems.map(item =>
      item.itemId === itemId ? { ...item, quantity: newQuantity } : item
    ));
  };

  
  // Helper function to determine table color based on occupancy
  const getTableColor = (tableNumber) => {
    const existingOrder = activeBags?.find(bag => 
      Number(bag.table_number) === Number(tableNumber)
    );
    
    if (!existingOrder) return TABLE_COLORS.FREE;
    if (unviewedKOTs.has(existingOrder.id)) return TABLE_COLORS.NEW_ORDER;
    return TABLE_COLORS.OCCUPIED;
  };

  const handlePrintKOT = async (bagId, kotId) => {
    try {
      // Remove the bag from unviewed KOTs
      setUnviewedKOTs(prev => {
        const newSet = new Set(prev);
        newSet.delete(bagId);
        return newSet;
      });
      
      // Show success message
      message.success('KOT printed successfully');
      
      // Close the KOTs modal
      setKotsModalVisible(false);
      
      // Refresh active bags to update table status
      dispatch(fetchActiveBags());
      
    } catch (error) {
      console.error('Failed to print KOT:', error);
      message.error('Failed to print KOT');
    }
  };

  // Handle table click - shows different modals for occupied vs free tables
  const handleTableClick = (tableNumber) => {
    const existingOrder = activeBags?.find(bag => 
      Number(bag.table_number) === Number(tableNumber)
    );
    
    if (existingOrder) {
      setSelectedBag(existingOrder);
      
      // If this table has unviewed KOTs, show the KOT modal directly
      if (unviewedKOTs.has(existingOrder.id)) {
        handleViewKOTs(existingOrder);
      } else {
        Modal.confirm({
          title: `Table ${tableNumber} - Actions`,
          content: (
            <div style={{ marginTop: '20px' }}>
              <p><strong>Customer:</strong> {existingOrder.user_name || existingOrder.userName || '-'}</p>
              <p><strong>Phone:</strong> {existingOrder.user_phone || existingOrder.userPhone || '-'}</p>
              <p><strong>Status:</strong> {existingOrder.status || '-'}</p>
            </div>
          ),
          width: 500,
          okText: null,
          cancelText: null,
          icon: null,
          centered: true,
          footer: (
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
              <Button onClick={() => {
                Modal.destroyAll();
                handleViewBill(existingOrder);
              }}>
                View Bill
              </Button>
              <Button type="primary" onClick={() => {
                Modal.destroyAll();
                showEditModal(existingOrder);
              }}>
                Add Items
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  Modal.destroyAll();
                  handleCloseOrder(existingOrder);
                }}
              >
                Close Order
              </Button>
              <Button 
                onClick={() => {
                  Modal.destroyAll();
                }}
              >
                Cancel
              </Button>
            </div>
          ),
        });
      }
    } else {
      setSelectedTable(tableNumber);
      setIsModalVisible(true);
    }
  };

  // Show actions modal for occupied tables
  const showOrderActions = () => {
    Modal.confirm({
      title: `Table ${selectedBag?.table_number || selectedTable} - Actions`,
      content: (
        <div style={{ marginTop: '20px' }}>
          <p><strong>Customer:</strong> {selectedBag?.user_name || selectedBag?.userName || '-'}</p>
          <p><strong>Phone:</strong> {selectedBag?.user_phone || selectedBag?.userPhone || '-'}</p>
          <p><strong>Status:</strong> {selectedBag?.status || '-'}</p>
        </div>
      ),
      width: 500,
      okText: null, // Remove default OK button
      cancelText: null, // Remove default Cancel button
      icon: null, // Remove default icon
      centered: true,
      footer: (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          <Button onClick={() => {
            Modal.destroyAll(); // Close current modal
            handleViewBill(selectedBag);
          }}>
            View Bill
          </Button>
          <Button type="primary" onClick={() => {
            Modal.destroyAll(); // Close current modal
            showEditModal(selectedBag);
          }}>
            Add Items
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => {
              Modal.destroyAll(); // Close current modal
              handleCloseOrder(selectedBag);
            }}
          >
            Close Order
          </Button>
        </div>
      ),
    });
  };

  // Handle new order modal cancel
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedTable(null);
    form.resetFields();
  };

  // Create new order
  const onFinish = async (values) => {
    try {
      const bagData = await createUserBag({
        userName: values.name,
        userPhone: values.phone || '',
        orderType: 'dine-in',
        tableNumber: selectedTable
      });

      dispatch(setCurrentBag({
        id: bagData.bagId,
        userName: bagData.userName,
        userPhone: bagData.userPhone,
        orderType: bagData.orderType,
        tableNumber: bagData.tableNumber
      }));

      // Refresh table statuses
      dispatch(fetchActiveBags());

      message.success('Order created successfully');
      setIsModalVisible(false);
      form.resetFields();
      onOrderCreated();
    } catch (error) {
      console.error('Error creating order:', error);
      message.error(error.message || 'Failed to create order. Please try again.');
    }
  };

  // View bill for existing order
  const handleViewBill = async (bag) => {
    setSelectedBag(bag);
    try {
      await dispatch(fetchBagKOTs(bag.id)).unwrap();
      setBillModalVisible(true);
    } catch (error) {
      message.error('Failed to load bill details');
    }
  };

  // Close existing order
  const handleCloseOrder = (bag) => {
    Modal.confirm({
      title: 'Close Order',
      content: 'Are you sure you want to close this order? This will generate the final bill and can\'t be undone.',
      okText: 'Yes, Close Order',
      okType: 'danger',
      cancelText: 'No, Keep Open',
      onOk: async () => {
        try {
          await dispatch(closeBag(bag.id)).unwrap();
          message.success('Order closed successfully');
          dispatch(fetchActiveBags());
        } catch (error) {
          message.error('Failed to close order: ' + error.message);
        }
      }
    });
  };

  // Add items to existing order
  const showEditModal = (bag) => {
    setSelectedBag(bag);
    setSelectedItems(bag.items || []);
    setEditModalVisible(true);
    Modal.destroyAll(); // Close the action modal
  };

  const handleSaveAndCreateKOT = async () => {
    try {
      if (selectedItems.length === 0) {
        message.warning('Please add at least one item');
        return;
      }
  
      const result = await dispatch(createKOTFromBagItems({
        bagId: selectedBag.id,
        items: selectedItems.map(item => ({
          itemId: item.itemId,
          quantity: item.quantity,
          notes: item.notes || ''
        }))
      })).unwrap();
  
      // Add the bag ID to unviewed KOTs
      setUnviewedKOTs(prev => new Set(prev).add(selectedBag.id));
  
      message.success('KOT created successfully');
      setEditModalVisible(false);
      dispatch(fetchActiveBags());
    } catch (error) {
      message.error('Failed to create KOT');
    }
  };

  const KOTDetails = ({ kot, onPrintKOT }) => {
    const creationTime = new Date(kot.created_at).toLocaleTimeString();
    
    return (
      <div key={kot.id} style={{ marginBottom: '20px' }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginBottom: '16px',
          backgroundColor: '#f5f5f5',
          padding: '12px',
          borderRadius: '6px'
        }}>
          <div>
            <Title level={5} style={{ margin: 0 }}>KOT #{kot.id?.slice(-4)}</Title>
            <Text type="secondary">Created at: {creationTime}</Text>
          </div>
        </div>
  
        <Table
          size="middle"
          pagination={false}
          dataSource={kot.items || []}
          columns={[
            { 
              title: 'Item', 
              dataIndex: 'name',
              render: (text, record) => (
                <div>
                  <div>{text}</div>
                  {record.notes && (
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      Note: {record.notes}
                    </Text>
                  )}
                </div>
              )
            },
            { 
              title: 'Qty', 
              dataIndex: 'quantity', 
              width: 80,
              align: 'center'
            },
            { 
              title: 'Price',
              dataIndex: 'price',
              width: 100,
              align: 'right',
              render: price => `₹${(price || 0).toFixed(2)}` 
            },
            {
              title: 'Total',
              width: 120,
              align: 'right',
              render: (_, record) => `₹${((record.price || 0) * (record.quantity || 0)).toFixed(2)}`
            }
          ]}
          summary={data => {
            const total = data.reduce((sum, item) => sum + (item.price * item.quantity), 0);
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3} align="right">
                  <strong>Total:</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>₹{total.toFixed(2)}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    );
  };

  const handleViewKOTs = async (bag) => {
    setSelectedBag(bag);
    try {
      await dispatch(fetchBagKOTs(bag.id)).unwrap();
      setKotsModalVisible(true);
    } catch (error) {
      console.error('Failed to load KOTs:', error);
      message.error('Failed to load KOTs');
    }
  };

  const getLatestKOT = (kots) => {
  if (!kots || !Array.isArray(kots) || kots.length === 0) return null;
  
  return kots.reduce((latest, current) => {
    const latestDate = new Date(latest.created_at);
    const currentDate = new Date(current.created_at);
    return currentDate > latestDate ? current : latest;
  }, kots[0]);
};
const KOTsModal = () => {
    if (!selectedBag) return null;
  
    // Get only the latest KOT
    const latestKOT = getLatestKOT(bagKOTs);
  
    return (
      <Modal
        title={`New Order - Table ${selectedBag.table_number}`}
        visible={kotsModalVisible}
        onCancel={() => {
          setKotsModalVisible(false);
          setUnviewedKOTs(prev => {
            const newSet = new Set(prev);
            newSet.delete(selectedBag.id);
            return newSet;
          });
        }}
        width={800}
        footer={null}
      >
        {bagKOTsLoading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin />
          </div>
        ) : !latestKOT ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Text type="secondary">No orders found</Text>
          </div>
        ) : (
          <div>
            <KOTDetails
              kot={latestKOT}
              onPrintKOT={() => handlePrintKOT(selectedBag.id, latestKOT.id)}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button
                type="primary"
                size="large"
                icon={<PrinterOutlined />}
                onClick={() => handlePrintKOT(selectedBag.id, latestKOT.id)}
              >
                Print KOT
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  };
  
  
  
  // Calculate bill totals
  // Replace the existing calculateTotals function with this:
const calculateTotals = () => {
    let subtotal = 0;
    let itemCount = 0;
  
    // Add null check for bagKOTs
    if (bagKOTs && Array.isArray(bagKOTs)) {
      bagKOTs.forEach(kot => {
        if (kot.items && Array.isArray(kot.items)) {
          kot.items.forEach(item => {
            subtotal += item.price * item.quantity;
            itemCount += item.quantity;
          });
        }
      });
    }
  
    const tax = subtotal * 0.18; // 18% GST
    const total = subtotal + tax;
  
    return { subtotal, tax, total, itemCount };
  };
  
  // And update the BillModal component to handle loading and empty states better:
  const BillModal = () => {
    const totals = calculateTotals();
  
    return (
      <Modal
        title={`Bill Details - Table ${selectedBag?.table_number}`}
        visible={billModalVisible}
        onCancel={() => setBillModalVisible(false)}
        width={800}
        footer={[
          <Button key="close" onClick={() => setBillModalVisible(false)}>
            Close
          </Button>,
          <Button
            key="print"
            type="primary"
            icon={<FilePdfOutlined />}
            onClick={() => message.info('Print functionality to be implemented')}
          >
            Print Bill
          </Button>
        ]}
      >
        {bagKOTsLoading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin />
          </div>
        ) : (
          <div>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Text>Customer: {selectedBag?.userName}</Text>
              <br />
              <Text>Phone: {selectedBag?.userPhone}</Text>
              <br />
              <Text>Date: {new Date(selectedBag?.created_at).toLocaleString()}</Text>
            </div>
  
            {(!bagKOTs || bagKOTs.length === 0) ? (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <Text type="secondary">No orders found for this table</Text>
              </div>
            ) : (
              <>
                {bagKOTs.map((kot, index) => (
                  <div key={kot.id} style={{ marginBottom: '20px' }}>
                    <Title level={5}>KOT #{index + 1}</Title>
                    <Table
                      size="small"
                      pagination={false}
                      dataSource={kot.items || []}
                      columns={[
                        { title: 'Item', dataIndex: 'name' },
                        { title: 'Qty', dataIndex: 'quantity', width: 80 },
                        { 
                          title: 'Price',
                          dataIndex: 'price',
                          width: 100,
                          render: price => `₹${(price || 0).toFixed(2)}` 
                        },
                        {
                          title: 'Total',
                          width: 120,
                          render: (_, record) => `₹${((record.price || 0) * (record.quantity || 0)).toFixed(2)}`
                        }
                      ]}
                    />
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      Status: {kot.status} | Created: {new Date(kot.created_at).toLocaleString()}
                    </Text>
                    <Divider />
                  </div>
                ))}
  
                <Row justify="end">
                  <Col span={12}>
                    <div style={{ textAlign: 'right' }}>
                      <Text>Total Items: {totals.itemCount}</Text>
                      <br />
                      <Text>Subtotal: ₹{totals.subtotal.toFixed(2)}</Text>
                      <br />
                      <Text>GST (18%): ₹{totals.tax.toFixed(2)}</Text>
                      <br />
                      <Title level={4}>Total: ₹{totals.total.toFixed(2)}</Title>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}
      </Modal>
    );
  };

  // Loading state
  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  // No tables configured
  if (tables.length === 0) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <h2>No tables configured</h2>
        <p>Please configure tables for this cafe first.</p>
      </div>
    );
  }

  // Main render
  return (
    <div>
      <h2 style={{ marginBottom: '24px' }}>Select Table</h2>
      <Modal
        title={`Add Items to Table ${selectedBag?.table_number}`}
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        width={1200}
        footer={[
          <Button key="cancel" onClick={() => setEditModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSaveAndCreateKOT}
            disabled={selectedItems.length === 0}
          >
            Create KOT
          </Button>
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Search
              placeholder="Search menu items..."
              style={{ marginBottom: 16 }}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
            />
            
            <div style={{ marginBottom: 16, display: 'flex', gap: 8, overflowX: 'auto' }}>
              {categories.map(category => (
                <Button
                  key={category}
                  type={selectedCategory === category ? 'primary' : 'default'}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </Button>
              ))}
            </div>

            <Row gutter={[8, 8]}>
              {getFilteredMenuItems().map(item => (
                <Col xs={12} sm={8} md={6} key={item._id}>
                  <Card
                    hoverable
                    size="small"
                    title={item.name}
                    extra={`₹${item.price?.toFixed(2)}`}
                  >
                    <Button
                      type="primary"
                      block
                      onClick={() => handleAddItem(item)}
                    >
                      Add
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>

          <Col span={8}>
            <Card title="Selected Items">
              <List
                dataSource={selectedItems}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <InputNumber
                        min={1}
                        value={item.quantity}
                        onChange={(value) => handleQuantityChange(item.itemId, value)}
                      />,
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveItem(item.itemId)}
                      />
                    ]}
                  >
                    <List.Item.Meta
                      title={item.name}
                      description={`₹${(item.price * item.quantity).toFixed(2)}`}
                    />
                  </List.Item>
                )}
              />
              <div style={{ marginTop: 16, textAlign: 'right' }}>
                <strong>Total: ₹
                  {selectedItems.reduce((sum, item) =>
                    sum + (item.price * item.quantity), 0
                  ).toFixed(2)}
                </strong>
              </div>
            </Card>
          </Col>
        </Row>
      </Modal>

      {/* Tables Grid */}
      <Row gutter={[16, 16]}>
      {tables.map(tableNumber => (
        <Col xs={8} sm={6} md={4} key={tableNumber}>
          <Card
            hoverable
            style={{
              backgroundColor: getTableColor(tableNumber),
              textAlign: 'center',
              cursor: 'pointer',
              color: 'white'
            }}
            bodyStyle={{ padding: '12px' }}
            onClick={() => handleTableClick(tableNumber)}
          >
            <h3 style={{ margin: 0, color: 'white' }}>Table {tableNumber}</h3>
            {activeBags?.some(bag => bag.table_number === tableNumber) && (
              <small style={{ color: 'rgba(255, 255, 255, 0.85)' }}>
                {activeBags.find(bag => bag.table_number === tableNumber)?.user_name}
              </small>
            )}
          </Card>
        </Col>
      ))}
    </Row>

      {/* New Order Modal */}
      <Modal
        title={`Create Order - Table ${selectedTable}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input the name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Order
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <KOTsModal />

      {/* Bill Modal */}
      <BillModal />
    </div>
  );
};

export default TableGrid;