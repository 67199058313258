import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import AddItem from "../../components/AddItem";
import { useDispatch, useSelector } from "react-redux";
import { getCafe } from "../../api/cafe";
import { setCafeItems } from "../../redux/slices/cafe";
import ItemCard from "../../components/ItemCard";
import BottomMenu from "../../components/BottomMenu";
import { CloseOutlined, PlusOutlined, DownOutlined } from "@ant-design/icons";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";
import Universalinput from "../../components/Universalnput";
import { useTrackedNavigation } from "../../components/analytics/useTrackedNavigation";
import { setNoteSaved } from "../../redux/slices/toggleslice";

const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useTrackedNavigation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("All");
  const [subcategories, setSubcategories] = useState({});
  const [openSubcategories, setOpenSubcategories] = useState({});
  const { items: itemsFromDB } = useSelector((state) => state.cafe);
  const [filteredItems, setFilteredItems] = useState({});

  const isNoteSaved = useSelector((state) => state.bool.isNoteSaved);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const getCafeDetails = async () => {
    try {
      const cafe = await getCafe();
      dispatch(setCafeItems(cafe.data));
      organizeItemsBySubcategory(cafe.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response?.data?.message === "Cafe is closed") {
        navigate("/cafe/closed");
      }
    }
  };

  const renderItems = () => {
    return Object.entries(filteredItems).map(([subcat, items]) => {
      if (subcat === "null" || subcat === "") {
        return (
          <div key="no-subcat" className="w-full py-4">
            {items.map((item) => (
              <ItemCard key={item._id} item={item} onDelete={handleItemDelete} />
            ))}
          </div>
        );
      } else {
        return (
          <div key={subcat} className="w-full py-4">
            <button
              className="w-full text-left px-1 py-2 text-[#3E4462] text-sm font-semibold flex justify-between items-center capitalize"
              onClick={() => toggleSubcategory(category, subcat)}
            >
              {subcat}
              <DownOutlined
                className={`transform transition-transform ${
                  openSubcategories[category]?.[subcat]
                    ? "rotate-180"
                    : "rotate-0"
                }`}
              />
            </button>
            {openSubcategories[category]?.[subcat] && (
              <div className="pl-4">
                {items.map((item) => (
                  <ItemCard key={item._id} item={item} onDelete={handleItemDelete} />
                ))}
              </div>
            )}
          </div>
        );
      }
    });
  };



  const organizeItemsBySubcategory = (menuData) => {
    const subcats = {};
    const allSubcats = {};
    const allItems = new Set();

    Object.keys(menuData).forEach((category) => {
      subcats[category] = menuData[category].reduce((acc, item) => {
        if (!acc[item.subcategory]) {
          acc[item.subcategory] = [];
        }
        acc[item.subcategory].push(item);

        // Add to "All" category only if it's not already there
        if (!allItems.has(item._id)) {
          if (!allSubcats[item.subcategory]) {
            allSubcats[item.subcategory] = [];
          }
          allSubcats[item.subcategory].push(item);
          allItems.add(item._id);
        }

        return acc;
      }, {});
    });

    subcats["All"] = allSubcats;
    setSubcategories(subcats);

    const openSubcats = {};
    Object.keys(subcats).forEach((category) => {
      openSubcats[category] = {};
      Object.keys(subcats[category]).forEach((subcat) => {
        openSubcats[category][subcat] = true;
      });
    });
    setOpenSubcategories(openSubcats);
  };

  useEffect(() => {
    getCafeDetails();
  }, [isNoteSaved]);

  useEffect(() => {
    searchFilter();
  }, [search, category, itemsFromDB, isNoteSaved]);

  useEffect(() => {
    organizeItemsBySubcategory(itemsFromDB);
  }, [itemsFromDB, isNoteSaved]);

  const searchFilter = () => {
    let itemsToFilter = [];
    if (category === "All") {
      // Flatten all items and remove duplicates
      const allItemsSet = new Set();
      Object.values(itemsFromDB)
        .flat()
        .forEach((item) => {
          if (!allItemsSet.has(item._id)) {
            allItemsSet.add(item._id);
            itemsToFilter.push(item);
          }
        });
    } else {
      itemsToFilter = itemsFromDB[category] || [];
    }

    const filtered = itemsToFilter.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );

    const groupedFiltered = filtered.reduce((acc, item) => {
      if (!acc[item.subcategory]) {
        acc[item.subcategory] = [];
      }
      acc[item.subcategory].push(item);
      return acc;
    }, {});

    setFilteredItems(groupedFiltered);
  };

  const toggleSubcategory = (cat, subcat) => {
    setOpenSubcategories((prev) => ({
      ...prev,
      [cat]: {
        ...prev[cat],
        [subcat]: !prev[cat]?.[subcat],
      },
    }));
  };

  const handleItemAddition = (newItem) => {
    const updatedItems = { ...itemsFromDB };
    if (!updatedItems[newItem.category]) {
      updatedItems[newItem.category] = [];
    }
    updatedItems[newItem.category].push(newItem);
    dispatch(setCafeItems(updatedItems));
    dispatch(setNoteSaved());
    organizeItemsBySubcategory(updatedItems);
    searchFilter();
    onCloseModal();
  };

  const handleItemDelete = (deletedItemId) => {
    // Remove the deleted item from the state
    const updatedItems = { ...itemsFromDB };
    Object.keys(updatedItems).forEach((category) => {
      updatedItems[category] = updatedItems[category].filter(
        (item) => item._id !== deletedItemId
      );
    });
    dispatch(setCafeItems(updatedItems));
    dispatch(setNoteSaved());
    searchFilter();
  };


  return (
    <div className="w-full h-screen flex flex-col justify-between items-center relative">
      <Header />
      <Helmet
        title="Menu"
        meta={[
          {
            name: "description",
            content: "Your cafe menu",
          },
          {
            property: "og:title",
            content: "Menu",
          },
        ]}
      />
      <button
        onClick={onOpenModal}
        className="bg-[#FD7347] text-white p-4 flex justify-center items-center z-50 h-10 rounded-full shadow-md fixed right-4"
        style={{ bottom: "90px" }}
      >
        Add Item <PlusOutlined className="ml-2" />
      </button>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
        styles={{
          modal: {
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          }
        }}
      >
        <AddItem
          closeModal={onCloseModal}
          onAddItem={handleItemAddition}
        />
      </Modal>

      <div className="w-full h-full flex flex-col items-center">
        {loading ? (
          <img
            src="../loading.gif"
            alt="Loading"
          />
        ) : (
          <>
            <div className="sticky top-0 flex flex-col gap-1 w-full z-10 bg-white shadow-md bg-opacity-50">
              <div className="w-full flex flex-col bg-white bg-opacity-50">
                <Universalinput
                  type="text"
                  placeholder="Search"
                  value={search}
                  className="w-11/12 mx-auto h-10 border bg-gray-100 m-2 rounded-lg px-4"
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search.length > 0 && (
                  <CloseOutlined
                    onClick={() => setSearch("")}
                    className="absolute right-8 top-5"
                  />
                )}
              </div>
              <div className="w-full flex overflow-x-auto overflow-y-hidden bg-white rounded-lg">
                {Object.keys(itemsFromDB).map((cat) => (
                  <button
                    key={cat}
                    onClick={() => setCategory(cat)}
                    className={`px-4 py-2 text-sm capitalize ${
                      category === cat
                        ? "text-[#FD7347] border-b-4 border-[#FD7347] bg-white"
                        : "text-gray-600 border-b border-gray-600 bg-white"
                    }`}
                  >
                    {cat}
                  </button>
                ))}
              </div>
            </div>
            <div className="w-full px-4 mt-2">
            {renderItems()}
              <div className="bg-white h-28 w-full"></div>
            </div>
          </>
        )}
      </div>
      <BottomMenu page="menu" />
    </div>
  );
};

export default Menu;
