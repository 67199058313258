import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchKOTs, updateKOTStatus, selectKOTs } from '../../redux/slices/posSlice';
import { Card, List, Button, message } from 'antd';

const POSKitchenDisplay = () => {
  const dispatch = useDispatch();
  const kots = useSelector(selectKOTs);

  useEffect(() => {
    dispatch(fetchKOTs());
  }, [dispatch]);

  const handleStatusChange = async (kotId, newStatus) => {
    try {
      // Log the values to verify what's being sent
      console.log('Updating KOT:', { kotId, newStatus });
      
      await dispatch(updateKOTStatus({
        kotId: kotId, // Make sure we're passing the ID string
        status: newStatus
      })).unwrap();
      
      message.success(`KOT status updated to ${newStatus}`);
      // Refresh KOTs after successful update
      dispatch(fetchKOTs());
    } catch (error) {
      console.error('Error updating KOT status:', error);
      message.error('Failed to update KOT status: ' + (error.message || 'Unknown error'));
    }
  };

  if (!kots || kots.length === 0) {
    return <div>No KOTs available</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {kots.map(kot => (
        <Card 
          key={kot.id} 
          title={`KOT #${kot.id.slice(-4)}`} 
          extra={<span>Status: {kot.status}</span>}
        >
          <List
            itemLayout="horizontal"
            dataSource={kot.items || []}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.name}
                  description={`Quantity: ${item.quantity}`}
                />
              </List.Item>
            )}
          />
          <div style={{ marginTop: '16px' }}>
            <Button 
              onClick={() => handleStatusChange(kot.id, 'PREPARING')} 
              style={{ marginRight: '8px' }}
              disabled={kot.status === 'PREPARING'}
            >
              Start Preparing
            </Button>
            <Button 
              onClick={() => handleStatusChange(kot.id, 'READY')} 
              style={{ marginRight: '8px' }}
              disabled={kot.status === 'READY'}
            >
              Mark as Ready
            </Button>
            <Button 
              onClick={() => handleStatusChange(kot.id, 'COMPLETED')} 
              type="primary"
              disabled={kot.status === 'COMPLETED'}
            >
              Complete Order
            </Button>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default POSKitchenDisplay;