import React from "react";
import Icon from "../../pages/assets/Icon2.svg";
import ShareButton from "./ShareButton";
const UserHeader = ({ cafeName, cafeId }) => {
  return (
    <div className="flex w-full flex-col relative">
      <div className="flex justify-between items-center p-4 text-xl border-b w-full top-0 h-16 bg-white z-20 ">
        <div className="flex items-center">
          <img
            src={Icon}
            alt=""
            className="h-8"
          />
          <h1 className="text-center ml-4">{cafeName}</h1>
        </div>
       <ShareButton cafeId={cafeId} />
      </div>
    </div>
  );
};

export default UserHeader;
