import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: {},
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    addNewOrder: (state, action) => {
      const { date, status } = action.payload;
      if (!state.orders[date]) {
        state.orders[date] = {};
      }
      if (!state.orders[date][status]) {
        state.orders[date][status] = [];
      }
      state.orders[date][status].unshift(action.payload);
    },
    updateOrderStatus: (state, action) => {
      const { date, oldStatus, newStatus, orderId } = action.payload;
      if (state.orders[date] && state.orders[date][oldStatus]) {
        const orderIndex = state.orders[date][oldStatus].findIndex(order => order._id === orderId);
        if (orderIndex !== -1) {
          const order = state.orders[date][oldStatus][orderIndex];
          state.orders[date][oldStatus].splice(orderIndex, 1);
          if (!state.orders[date][newStatus]) {
            state.orders[date][newStatus] = [];
          }
          state.orders[date][newStatus].unshift({ ...order, status: newStatus });
        }
      }
    },
  },
});

export const { setOrders, addNewOrder, updateOrderStatus } = adminSlice.actions;
export default adminSlice.reducer;