import React from "react";
import Universalinput from "./Universalnput"
import UniversalInput from "./Universalnput";
const Pin = ({ onChange, onCompletion }) => {
  const codeChangeHandler = (event) => {
    const element = event.target;
    const nextSibling = element.nextElementSibling;
    nextSibling ? nextSibling.focus() : element.blur();
    onChange(element.value);

    let reset = false;
    if (!nextSibling)
      reset = onCompletion(element.value);
    if (reset) {
      document.querySelectorAll("input").forEach((input) => {
        input.value = "";
      });
    }
  };

  return (
    <div class="flex flex-col space-y-16 max-w-80 w-full">
      <div class="flex flex-row items-center justify-between mx-auto w-full ">
        {new Array(4).fill(0).map((_, index) => (
          <input key={index}
            autoFocus={index === 0}
            class="w-16 h-16 flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border focus:border-none border-sky-400 focus:outline-none text-lg bg-white focus:bg-gray-50 focus:ring-2 ring-teal-500"
            type="password"
            pattern="[0-9]*"
            inputMode="numeric"
            name={`code-${index}`}
            maxLength={1}
            onChange={(event) => codeChangeHandler(event)}/>
        ))}
      </div>
    </div>
  );
};

export default Pin;
