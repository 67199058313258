import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { recordAnalyticsEvent } from '../../api/analytics';

export const useTrackedNavigation = () => {
  const navigate = useNavigate();

  const trackedNavigate = useCallback((to, options) => {
    if (typeof to === 'string' && to.startsWith('/cafe/')) {
      recordAnalyticsEvent('navigation', to);
    }
    navigate(to, options);
  }, [navigate]);

  return trackedNavigate;
};