import React, { useState, useEffect } from "react";
import BottomMenu from "../../components/BottomMenu";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { alterReviewLink, alterUPI, updateImageBanner } from "../../api/cafe";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../api/auth";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const { user, cafe = {} } = useSelector((state) => state.auth);
  const [upi, setUpi] = useState("");
  const [reviewLink, setReviewLink] = useState("");
  const navigate = useNavigate();

  async function checkRegisteredCafe() {
    const resp = await fetchUser();
    if (!resp.data.cafe || !resp.data.cafe.name) {
      navigate("/cafe-admin/register");
    }
  }

  useEffect(() => {
    checkRegisteredCafe();
  }, [cafe]);

  return (
    <div className="w-full h-screen flex flex-col justify-between items-center">
      {/* Header toggle and menu */}
      <Header />
      <Helmet
        title="Dashboard"
        meta={[
          { name: "description", content: "Dashboard for your cafe" },
          { property: "og:title", content: "Dashboard" }
        ]}
      />
      {/* Greetings */}
      <div className="w-full h-1/2 flex flex-col justify-center items-center">
        <Toaster />
        <h1 className="text-3xl font-semibold">
          Welcome{" "}
          {cafe?.name && cafe?.name[0].toUpperCase() + cafe.name.slice(1)}
        </h1>
        <p>
          Manager:{" "}
          {user?.name && user?.name[0].toUpperCase() + user.name.slice(1)}
        </p>
      </div>
      <div className="h-60"></div>
      <BottomMenu />
    </div>
  );
};

export default Dashboard;
