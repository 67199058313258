import React, { useState, useEffect } from "react";
import { registerUser } from "../../api/user";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Universalinput from "../../components/Universalnput"
import { FiUser } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
const Register = () => {
  const [name, setName] = useState(localStorage.getItem("OG_name") || "");
  const [phone, setPhone] = useState(localStorage.getItem("OG_phone") || "");
  const [isUsername, setIsUsername] = useState(false);
  const location = useLocation();
  const { isFoodcourt, foodcourtId } = location.state || {};


  const navigate = useNavigate();

  function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();
    let platform = navigator?.userAgentData?.platform || navigator?.platform;

    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(platform)
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    const isUsernameRoute = sessionStorage.getItem("OG_isUsername") === "true";
    setIsUsername(isUsernameRoute);

    const name = localStorage.getItem("OG_name");
    const phone = localStorage.getItem("OG_phone");
    if (name && phone) {
      try {
        registerUser({ name, phone });
      } catch (error) {
        navigate("/cafe/closed");
      }
      if (isFoodcourt) {
        navigate(`/foodcourt/${foodcourtId}/cafes`);
      } else {
        navigate(isUsernameRoute ? "/cafe/profile" : "/cafe/menu");
      }
    }
  }, []);



  async function requestNotificationPermission() {
    if (isIOS()) return; // iOS doesn't support web push notifications

    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          try {
            const registration = await navigator.serviceWorker.register("../service-worker.js", { scope: "/" });
            console.log("Service Worker Registered", registration);

            // Subscribe the user to push notifications
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY
            });

            // Send the subscription to your server
            await fetch(
              `${process.env.REACT_APP_BASE_URL}/api/user/subscribe`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ subscription, phone }),
              }
            );

            console.log("Push Notification Subscription: ", subscription);
          } catch (err) {
            console.error("Service Worker registration failed: ", err);
          }
        }
      }
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (phone.length !== 10 || isNaN(JSON.parse(phone))) {
      toast.error("Please enter valid phone");
      return;
    }
    localStorage.setItem("OG_name", name);
    localStorage.setItem("OG_phone", phone);
    try {
      await registerUser({ name, phone });
      await requestNotificationPermission();
      if (isFoodcourt) {
        navigate(`/foodcourt/${foodcourtId}/cafes`);
      } else {
        navigate(isUsername ? "/cafe/profile" : "/cafe/menu");
      }
    } catch (error) {
      navigate("/cafe/closed");
    }
  }

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center px-8 bg-[#FAFAFA]">
      <Toaster />
      <div className="text-center py-20">
        <h1 className="text-2xl font-extrabold mb-3">Welcome!</h1>
        <p className="text-xs">Sign in and embark on your culinary adventure with us. Your journey continues here!</p>
      </div>
      <form
        onSubmit={onSubmit}
        className="my-[100px] w-full flex flex-col items-center justify-center"
      >
        <div className="flex flex-col gap-[24px] w-full ">
          <Universalinput
            type="text"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
            className="h-[30px] "
            placeholder="Name"
            icon={FiUser}
            iconcss=" text-xl text-black"
          />
          <Universalinput
            type="number"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
            className="h-[30px] rounded-[30px]"
            placeholder="Phone Number"
            icon={FaPlus}
            iconcss=" text-xs text-black"
            para={+91}
          />
          <button
            className="mt-4 text-xs flex justify-center items-center h-12 bg-[#9277FF] text-[#FFFFFF] rounded-lg font-bold px-8"
            style={{ boxShadow: "2px 3px 0px 0px #000000" }}
          >
            {isFoodcourt ? "View Cafes" : (isUsername ? "View Profile" : "View Menu")}
          </button>


        </div>
      </form>
    </div>
  );
};

export default Register;