import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentBag } from '../../redux/slices/posSlice';

const CurrentBagDisplay = () => {
  const currentBag = useSelector(selectCurrentBag);

  if (!currentBag) {
    return <div>No active order</div>;
  }

  return (
    <div>
      <h2>Current Order</h2>
      <p>Order ID: {currentBag.id}</p>
      <p>Customer: {currentBag.userName}</p>
      <p>Phone: {currentBag.userPhone}</p>
      <p>Type: {currentBag.orderType}</p>
    </div>
  );
};

export default CurrentBagDisplay;