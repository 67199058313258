import React from "react";
import swiggy from "../../pages/assets/Group 18291.svg";
import zomato from "../../pages/assets/image 52.svg";
import banner from "../../pages/assets/banner.png";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
const Offercard = ({ offer, cafeName }) => {
  return (
    <div
      className=" mx-auto px-4    relative bg-white rounded-lg shadow-lg  overflow-hidden "
      style={{ width: "320px" }}
    >
      <div className="relative rounded-xl mt-4">
        <img
          className="w-full h-24 rounded-t-2xl  object-cover pt-4"
          src={banner}
          alt="Strawberries"
        />
        <div
          className="flex flex-row absolute  z-100  items-center justify-between  w-full h-50"
          style={{ top: "63px" }}
        >
          <p className="bg-white rounded-full px-2 py-2 text-xs font-bold text-black m-2 text-left">
            {cafeName}
          </p>
          {(offer.rewardType === "Redeem % discount" ||
            offer.rewardType === "Redeem Rs discount" ||
            offer.rewardType === "Redeem a free gift") && (
            <div className=" bg-white rounded-full px-3 flex flex-row items-center justify-center h-8 w-21 py-1  text-xs font-bold text-black m-2 rounded-b-2xl">
              {offer.rewardType === "Redeem % discount" && (
                <span className="text-black font-bold">
                  {offer.discount}% OFF
                </span>
              )}
              {offer.rewardType === "Redeem Rs discount" && (
                <span className="text-black  font-bold">
                  Rs {offer.discount} OFF
                </span>
              )}
              {offer.rewardType === "Redeem a free gift" && (
                <span className="text-black font-bold">
                  Free {offer.freeGift}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="p-6 flex flex-col items-center justify-center bg-red-500  ">
          <p className="text-md font-bold  text-white text-center ">
            {offer.campaignTitle || "Offer Title"}
          </p>
          <p className="text-sm text-white mt-2 ">
            {offer.minimumPurchase && (
              <span className="text-white font-bold block">
                On min purchase off Rs {offer.minimumPurchase}
              </span>
            )}
          </p>
          <p className="text-xs text-white mt-2 text-center">
            {offer.campaignDescription || "Offer description will appear here"}
          </p>

          <div
            className="bg-white w-full h-[1px] my-2"
            style={{ background: "bg-white", height: "1px" }}
          ></div>
          <p className=" font-bold text-sm text-white text-center">
            Expires on {formatDate(offer.expirationDate)}{" "}
          </p>
          <div
            className="bg-white w-full h-[1px] my-2"
            style={{ background: "bg-white", height: "1px" }}
          ></div>
          <ul className="list-disc list-inside text-xs text-center mt-1">
            <li>
              {offer?.termsAndConditions?.map((item, index) => {
                return (
                  item && (
                    <div
                      key={index}
                      className="text-white text-xs text-center"
                    >
                      <span>{item}</span>
                    </div>
                  )
                );
              }) || (
                <spam className="text-white text-center">
                  Terms and conditions will be listed here
                </spam>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div className="p-4 text-center text-xs text-gray-500">
        {(offer.addToSwiggy === "true" || offer.addToZomato === "true") && (
          <div>Offer also available here, click to open *</div>
        )}
      </div>
      <div className="flex items-center justify-center space-x-2 pb-2">
        {offer.addToSwiggy === "true" && (
          <img
            src={swiggy}
            alt="Swiggy"
            className="h-6"
          />
        )}
        {offer.addToZomato === "true" && (
          <img
            src={zomato}
            alt="Zomato"
            className="h-6"
          />
        )}
      </div>
    </div>
  );
};

export default Offercard;