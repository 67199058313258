import React from 'react'
import arrow from '../assets/Arrow 3.svg'
const Home = () => {
  return (
    <div className=" px-4 mb-4 flex flex-col gap-4" >
    <div className="font-bold text-lg mt-4">Your business at glance</div>
    <div className='w-full h-[40px] bg-[#00F3AE] rounded-lg font-semibold text-md flex gap-2 pl-2 items-center' style={{ boxShadow: "1px 2px 2px 2px #000000" }}> 
    <div className='w-[25px] h-[25px] rounded-full bg-white flex justify-center items-center'> <img src={arrow}/> </div>
    <spam className='text-xs'>You reachhed to 50 new people this week </spam>  </div>

    <div className='w-full h-[40px] bg-[#00F3AE] rounded-lg font-semibold text-md flex gap-2 pl-2 items-center' style={{ boxShadow: "1px 2px 2px 2px #000000" }}> 
    <div className='w-[25px] h-[25px] rounded-full bg-white flex justify-center items-center'> <img src={arrow}/> </div>
    <spam className='text-xs'>Some insight </spam>  </div>
    
  </div>
  )
}

export default Home