

import React, {useState} from 'react';
import {FaEye, FaEyeSlash} from "react-icons/fa";

const UniversalInput = ({ type = "text", value, onChange, placeholder, className = "", label,para:Para, icon: Icon,iconcss="", ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="flex items-center border p-2 rounded-[10px] shadow-md bg-white w-full" style={{boxShadow: "2px 3px 0px 0px #000000"}}>
      {Icon && <Icon className={`${iconcss} ml-3`} />}
      {Para && <spam className="text-lg text-black font-bold">{Para}</spam>}
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`flex-grow focus:outline-none  placeholder:font-medium placeholder:text-sm ${className}`}
        style={{ border: 'none', padding: '5px 10px', fontSize: '16px'  }}
        {...props}
      />
      {type === "password" && (
        <span
          onClick={() => setShowPassword(!showPassword)}
          className="mr-3 cursor-pointer"
        >
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </span>
      )}
    </div>
  );
};

export default UniversalInput;
