import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  AlertOutlined,
  HomeOutlined,
  StarOutlined,
} from "@ant-design/icons";
import menu from "../pages/assets/fluent_food-32-regular.svg";
import menu1 from "../pages/assets/fluent_food-32-regular2.svg";
import Profile from "../pages/assets/bottom-profile-icon.png";
import Settings from "../pages/assets/bottom-settings-icon.png";
import LoadingOverlay from "./LoadingOverlay";

import { fetchPermissions } from "../api/cafe";

const HoverMenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div
      className="absolute bottom-24 right-44 flex flex-col items-start justify-center h-auto px-2 py-1 bg-[#FFFFFF] text-[#CACACA]"
    >
      <Link
        to="/cafe-admin/menu"
        className="flex items-center gap-x-2 hover:text-[#9277FF]"
      >
        <img src={menu} alt="Menu" className="w-10 h-10 object-contain" /> {/* Same menu icon as before */}
        Menu
      </Link>
      <Link
        to="/cafe-admin/profile"
        className="flex items-center gap-x-2 hover:text-[#9277FF]"
      >
        <img src={Profile} alt="Profile" className="w-10 h-10 object-contain" /> {/* Increased image size */}
        Profile
      </Link>
      <Link
        to="/cafe-admin/settings"
        className="flex items-center gap-x-2 hover:text-[#9277FF]"
      >
        <img src={Settings} alt="Settings" className="w-10 h-10 object-contain" /> {/* Increased image size */}
        Settings
      </Link>
    </div>
  );
};

const BottomMenu = ({ page }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const response = await fetchPermissions();
        console.log("Fetched permissions:", response);
        setPermissions(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching permissions:", error);
        navigate("/cafe-admin/login");
      }
    };

    getPermissions();
  }, [navigate]);

  if (loading) {
    return <LoadingOverlay show={loading} />;
  }

  return (
    <div
      style={{
        justifyContent: "space-around",
        position: "fixed",
        bottom: 0,
        left: 0,
      }}
      className="sticky bottom-0 z-50 h-20 flex items-center px-8 justify-between py-4 w-full border shadow-xl bg-white"
    >
      {/* Home Button */}
      <div
        className={`flex flex-col w-[80px] justify-center p-2 items-center ${
          page === "home" && "text-white rounded-lg bg-purple-500 "
        }`}
        style={{
          boxShadow: `${page === "home" && "4px 5px 0px 0px #000000"}`,
        }}
      >
        <Link
          to="/cafe-admin/analytics"
          className="flex flex-col items-center justify-center"
        >
          <HomeOutlined
            className={`mb-2 ${page === "home" && " font-bold"}`}
          />
          <p className={`text-xs ${page === "home" && "font-bold"}`}>Home</p>
        </Link>
      </div>

      {/* Campaigns Button */}
      <div
        className={`flex flex-col w-[80px] justify-center p-2 items-center  ${
          page === "campaigns" && "text-white rounded-lg  bg-purple-500"
        }`}
        style={{
          boxShadow: `${page === "campaigns" && "4px 5px 0px 0px #000000"}`,
        }}
      >
        <Link
          to="/cafe-admin/all-offer-analytics"
          className="flex flex-col items-center justify-center"
        >
          <AlertOutlined
            className={`mb-2 ${page === "campaigns" && " font-bold"}`}
          />
          <p className={`text-xs ${page === "campaigns" && "font-bold"}`}>
            Campaigns
          </p>
        </Link>
      </div>

      {/* Reviews Button */}
      <div
        className={`flex flex-col w-[80px] justify-center p-2 items-center ${
          page === "reviews" && "text-white rounded-lg bg-purple-500"
        }`}
        style={{
          boxShadow: `${page === "reviews" && "4px 5px 0px 0px #000000"}`,
        }}
      >
        <Link
          to="/cafe-admin/review-stats"
          className="flex flex-col items-center justify-center"
        >
          <StarOutlined
            className={`mb-2 ${page === "reviews" && " font-bold"}`}
          />
          <p className={`text-xs ${page === "reviews" && "font-bold"}`}>
            Reviews
          </p>
        </Link>
      </div>

      {/* Menu Button with same icon as previous BottomMenu */}
      <div
        className={`flex flex-col justify-center w-[80px] p-2 items-center ${
          page === "menu" && "text-white rounded-lg bg-purple-500"
        }`}
        style={{
          boxShadow: `${page === "menu" && "4px 5px 0px 0px #000000"}`,
        }}
      >
        <Link
          to="/cafe-admin/menu"
          className="flex flex-col items-center justify-center"
        >
          <img
            src={page === "menu" ? menu : menu1} // Use same icons as before
            alt="Menu"
            className={`mb-1  ${page !== "menu" && "invert grayscale"}`}
            style={{ width: "23px", height: "23px",  }} // Adjusted size to match previous menu
          />
          <p className={`text-xs ${page === "menu" && "font-bold"}`}>Menu</p>
        </Link>
      </div>
    </div>
  );
};

export default BottomMenu;
