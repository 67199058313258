import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import ItemInCart from "../../components/ItemInCart";
import {
  addOrder,
  emptyCart,
  removeFromCart,
  updateQuantity,
} from "../../redux/slices/user";
import { Link } from "react-router-dom";
import { CaretLeftOutlined } from "@ant-design/icons";
import { placeOrder } from "../../api/user";
import { useNavigate } from "react-router-dom";
import Universalinput from "../../components/Universalnput"

const Bag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cafeData = useSelector((state) => state.user.cafe);
  const cartData = useSelector((state) => state.user.cart);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    setPrice(
      cartData.reduce((acc, item) => acc + item.price * item.quantity, 0)
    );
  }, [cartData]);
  const [takeaway, setTakeaway] = useState(false);
  const [note, setNote] = useState("");

  function removeFromBag(id) {
    dispatch(removeFromCart(id));
  }

  function updateQuantityInBag(id, quantity) {
    dispatch(updateQuantity({ id, quantity }));
  }

  function finishBag() {
    const items = cartData.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      variant: item.variant,
    }));
    navigate("/order/register");

    placeOrder(items, note, takeaway).then((res) => {
      if (res.success) {
        dispatch(emptyCart());
        dispatch(addOrder(res.data));
      }
    });
  }

  return (
    <div className="w-full h-screen flex flex-col items-center">
      <Helmet
        title={`${cafeData.name} Bag`}
        meta={[
          {
            name: "description",
            content: "Your Bag",
          },
          {
            property: "og:title",
            content: "Bag",
          },
        ]}
      />
      {cafeData?.image && (
        <div className="h-80 w-full fixed top-0 flex flex-col justify-center items-center bg-black">
          <img
            src={`data:image/png;base64,${cafeData?.image}`}
            alt="banner"
            className="w-full h-80 object-cover fixed top-0 z-0"
          />
        </div>
      )}
      <div
        className={`w-full px-4 ${cafeData.image && "mt-60 rounded-t-2xl"
          } pt-4 relative z-20 bg-white flex items-center flex-col justify-center`}
      >
        <div className="flex w-full justify-between">
          <Link to="/cafe/menu" className="text-sm">
            <span className="flex items-center">
              <CaretLeftOutlined /> Go Back
            </span>
          </Link>
          <div className="h-1 w-20 rounded-3xl bg-gray-400 justify-self-center self-center"></div>
          <p className="text-white text-sm">
            <CaretLeftOutlined /> Go Back
          </p>
        </div>
        <h1 className="text-3xl text-center font-bold mt-10">My Bag</h1>
        <h1 className="text-base text-center mt-3 mb-6">{cafeData.name}</h1>
        {cartData.map((cartItem, index) => (
          <ItemInCart
            key={index}
            removeFromBag={removeFromBag}
            updateQuantity={updateQuantityInBag}
            cartItem={cartItem}
          />
        ))}
        <div className="w-full h-80 bg-white"></div>
      </div>
      <div className="flex flex-col fixed py-4 bg-white z-20 bottom-0 w-full items-center">
        <div className="flex items-center mt-8 justify-between w-full px-8 text-xl">
          <p className="py-2 font-bold">Total Price:</p>
          <p>₹ {price}</p>
        </div>
        {cartData.length === 0 ? (
          <Link
            to={"/cafe/menu"}
            className="mt-4 disabled:bg-[#FD7347] mx-4 text-xl flex items-center justify-center w-80 h-16 bg-[#FD7347] text-white py-2 px-4 rounded-2xl"
          >
            Add Items
          </Link>
        ) : (
          <>
            <textarea
              name="note"
              id="note"
              cols="30"
              rows="3"
              placeholder="Any special requirement, notes, things we should take care of?"
              className="bg-gray-100 border border-gray-500 rounded-xl text-sm w-11/12 py-2 px-4"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
            <button
              onClick={finishBag}
              className="mt-4 disabled:bg-[#FD7347] block mx-4 text-lg w-80 h-14 bg-[#FD7347] text-white py-2 px-4 rounded-2xl"
            >
              Continue
            </button>
            <div className="flex items-center pt-2">
            <Universalinput
                value={takeaway}
                onChange={(e) => setTakeaway(e.target.value)}
                type="checkbox"
                name="takeaway"
                id="takeaway"
              />
              <label htmlFor="takeaway" className="pl-2">
                Want it to be a take-away order?
              </label>
            </div>
          </>
        )}
      </div>
      {/* <div className="flex items-center py-3 justify-between w-full px-8 text-xl">
          <p className="py-2 font-bold">Delivery Price:</p>
          <p>
            ₹{" "}
            {cartData.reduce((acc, item) => acc + item.price * item.quantity, 0) *
              0.2 >
              200}
          </p>
        </div> */}
    </div>
  );
};

export default Bag;
