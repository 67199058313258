import React, { useState } from 'react'
import { addItemRating } from '../api/user';

const ReviewItem = ({ item }) => {
  const [rating, setRating] = useState(item.rating)
  const [review, setReview] = useState('')
  const [animate, setAnimation] = useState("")
  const [display, setDisplay] = useState("block")

  const handleRating = (val) => {
    // setRating(val);
    addItemRating(item.id, val)
    setAnimation("transition duration-1000 opacity-0 scale-0")
    setTimeout(() => {
      setDisplay("hidden")
    }, 500)
  };

  const handleReview = (val) => {
    setReview(val);
  };

  console.log({ item })

  if (rating) {
    return (
      <></>
      // <div
      //   key={item.id}
      //   className="flex border rounded my-4 flex-col justify-between w-11/12"
      // >
      //   <div className="flex flex-col">
      //     <div className="w-full justify-center items-center py-4 border-b">
      //       <p className="text-center text-sm">
      //         You{" "}
      //         {rating === 5
      //           ? "loved"
      //           : rating === 4
      //             ? "liked"
      //             : "hated"}{" "}
      //         Mr. {item.name}
      //       </p>
      //     </div>
      //     <div className="w-full justify-center items-center py-2 flex flex-col">
      //       <p className="text-center text-xs my-2">
      //         Tell us what you {rating === 2 ? "didn't liked" : "liked"}{" "}
      //         about him (Only 1)
      //       </p>
      //       <div className="mt-2">
      //         <button onClick={() => handleReview("Food Quality")} className={`${review === "Food Quality" && "bg-sky-500 text-white"} border text-xs py-2 px-4 m-1 rounded-full`}>
      //           Food Quality
      //         </button>
      //         <button onClick={() => handleReview("Portion Size")} className={`${review === "Portion Size" && "bg-sky-500 text-white"} border text-xs py-2 px-4 m-1 rounded-full`}>
      //           Portion Size
      //         </button>
      //         <button onClick={() => handleReview("Fast Service")} className={`${review === "Fast Service" && "bg-sky-500 text-white"} border text-xs py-2 px-4 m-1 rounded-full`}>
      //           Fast Service
      //         </button>
      //         <button onClick={() => handleReview("Ambiance")} className={`${review === "Ambiance" && "bg-sky-500 text-white"} border text-xs py-2 px-4 m-1 rounded-full`}>
      //           Ambiance
      //         </button>
      //         <button onClick={() => handleReview("Staff")} className={`${review === "Staff" && "bg-sky-500 text-white"} border text-xs py-2 px-4 m-1 rounded-full`}>
      //           Staff
      //         </button>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  } else {
    return (
      <div
        key={item.id}
        className={"flex border shadow-sm rounded-lg mt-4 flex-col justify-between w-11/12 none" + animate + " " + display}
      >
        <div className="w-full justify-center items-center py-4 ">
          <p className="text-center text-sm">
            Please rate <span className='font-bold text-sky-500'>Mr. {item.name}</span>
          </p>
        </div>
        <div className="flex flex-col">
          <img
            src={`data:image/png;base64,${item?.image}`}
            alt={item.name}
            className="h-40 object-cover"
          />
          <div className="flex xs:justify-between justify-around">
            <button
              onClick={() => handleRating(2)}
              className="py-2 px-2 text-sm border border-gray-300 rounded mx-1 my-2 flex items-center shadow-sm bg-[#FD7347] text-white"
            >
              <img src="../2.png" alt="2 star" className='h-6 mr-2' /><span>Yuckk</span>
            </button>
            <button
              onClick={() => handleRating(5)}
              className="py-2 px-2 text-sm border border-gray-300 rounded mx-1 my-2 flex items-center shadow-sm bg-[#FD7347] text-white"
            >
              <img src="../5.png" alt="5 star" className='h-6 mr-2' /> <span className='font-bold'>Super YUM!</span>
            </button>
            <button
              onClick={() => handleRating(4)}
              className="py-2 px-2 text-sm border border-gray-300 rounded mx-1 my-2 flex items-center shadow-sm bg-[#FD7347] text-white"
            >
              <img src="../4.png" alt="4 star" className='h-6 mr-2' /> <span>Yummy</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewItem