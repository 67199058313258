import React from 'react';
import { toast } from 'react-toastify';
import vector from "../../pages/assets/Vector.svg"

const ShareButton = ({ cafeId }) => {
  const shareUrl = `https://app.orderg.in/cafe/${cafeId}`;

  const handleShare = async () => {
    if (navigator.share) {
      // Web Share API is supported (works on mobile devices including iOS)
      try {
        await navigator.share({
          title: 'Check out this cafe!',
          text: 'I found this great cafe on OrderG. Check it out!',
          url: shareUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
        // Fallback to copying to clipboard if sharing was cancelled or failed
        copyToClipboard();
      }
    } else {
      // Web Share API is not supported (mainly desktop browsers)
      copyToClipboard();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      toast.success('Link copied to clipboard!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy link. Please try again.');
    });
  };

  return (
    <div 
      onClick={handleShare}
      className="bg-[#FFFFFF] bg-opacity-50 h-10 w-10 flex items-center justify-center rounded-lg cursor-pointer"
    >
      <img src={vector} alt="Share" />
    </div>
  );
};

export default ShareButton;