import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_BASE_URL;

const getUserUUID = async (phone) => {
  try {
    const response = await axios.get(`${API_URL}/api/user/uuid/${phone}`);
    return response.data.data.uuid;
  } catch (error) {
    console.error('Error fetching user UUID:', error);
    return null;
  }
};

let sessionStartTime = Date.now();

export const recordAnalyticsEvent = async (eventType, componentName,additionalData = {}) => {
  try {
    const phone = localStorage.getItem('OG_phone');
    const cafeData = sessionStorage.getItem('OG_cafe');

    if (!phone || !cafeData) {
      console.warn('Missing phone or cafe data');
      return;
    }

    let cafeId;
    try {
      const parsedCafeData = JSON.parse(cafeData);
      cafeId = parsedCafeData._id;
    } catch (error) {
      console.error('Error parsing cafe data:', error);
      return;
    }

    if (!cafeId) {
      console.warn('No cafe ID found in sessionStorage');
      return;
    }

    // Fetch user UUID
    const userUUID = await getUserUUID(phone);
    if (!userUUID) {
      console.warn('Failed to get user UUID');
      return;
    }

    const payload = {
      event_type: eventType,
      user_id: userUUID,
      cafe_id: cafeId,
      link: window.location.href,
      device_type: getDeviceType(),
      browser: getBrowserInfo().browser,
      screen_resolution: `${window.screen.width}x${window.screen.height}`,
      referrer: document.referrer,
      language: navigator.language || navigator.userLanguage,
      page_load_time: parseFloat(performance.now().toFixed(2)),
      scroll_depth: additionalData.scroll_depth || 0,
      session_duration: additionalData.session_duration || 0,
      ...additionalData
    };

    console.log('Sending analytics payload:', payload);

    const response = await axios.post(`${API_URL}/api/cafe/analytics/event`, payload, {
      withCredentials: true,
    });

    if (response.data.success) {
      console.log('Analytics event recorded successfully:', response.data);
    } else {
      console.error('Failed to record analytics event:', response.data.message);
    }
  } catch (error) {
    console.error('Error recording analytics event:', error);
    if (error.response) {
      console.error('Error data:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
  }
};

// Helper functions

function getScrollDepth() {
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return Math.round((scrollTop / (documentHeight - windowHeight)) * 100);
}

function getDeviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
}

function getBrowserInfo() {
  const ua = navigator.userAgent;
  let browser = "Unknown";

  if (ua.indexOf("Firefox") > -1) {
    browser = "Firefox";
  } else if (ua.indexOf("Opera") > -1 || ua.indexOf("OPR") > -1) {
    browser = "Opera";
  } else if (ua.indexOf("Trident") > -1) {
    browser = "Internet Explorer";
  } else if (ua.indexOf("Edge") > -1) {
    browser = "Edge";
  } else if (ua.indexOf("Chrome") > -1) {
    browser = "Chrome";
  } else if (ua.indexOf("Safari") > -1) {
    browser = "Safari";
  }

  return { browser };
}

// Reset session start time when the page loads
window.addEventListener('load', () => {
  sessionStartTime = Date.now();
});
export const fetchAnalyticsEvents = async (startDate, endDate) => {
  try {
    const response = await axios.get(`${API_URL}/api/cafe/analytics/events`, {
      params: { start_date: startDate, end_date: endDate },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching analytics events:', error.response ? error.response.data : error.message);
    throw error;
  }
};