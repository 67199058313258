import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchReviewQuestions, submitCafeReview, getUserUUID, getGoogleReviewLink, updateCafeReview } from '../../api/user';
import BottomMenu from '../../components/user/BottomMenu';
import Image from '../assets/Icon.svg';
import { recordAnalyticsEvent } from '../../api/analytics';
import UserHeader from '../../components/user/UserHeader';

const CafeReviewPage = () => {
  const [cafeDetails, setCafeDetails] = useState(JSON.parse(sessionStorage.getItem('OG_cafe')));
  const [questions, setQuestions] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [googleReviewLink, setGoogleReviewLink] = useState('');
  const [currentReviewId, setCurrentReviewId] = useState(null);
  const [sources, setSources] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const cafeData = sessionStorage.getItem('OG_cafe');
    if (!cafeData) {
      toast.error('Cafe data not found. Please try again.');
      navigate('/');
      return;
    }

    try {
      const cafeObj = JSON.parse(cafeData);
      if (!cafeObj._id) {
        throw new Error('Invalid cafe data');
      }
      loadQuestions(cafeObj._id);
      fetchGoogleReviewLink();
    } catch (error) {
      console.error('Error parsing cafe data:', error);
      toast.error('Invalid cafe data. Please try again.');
      navigate('/');
    }
  }, [navigate]);

  const loadQuestions = async (cafeId) => {
    try {
      const result = await fetchReviewQuestions(cafeId);
      setQuestions(result.data.questions);
      setReviews(result.data.questions.map(q => ({ questionId: q._id, rating: 0, reviewText: '' })));
    } catch (error) {
      toast.error('Failed to fetch review questions');
    } finally {
      setLoading(false);
    }
  };

  const fetchGoogleReviewLink = async () => {
    try {
      const result = await getGoogleReviewLink();
      setGoogleReviewLink(result.data.google_review_link);
    } catch (error) {
      console.error('Failed to fetch Google review link:', error);
      toast.error('Failed to fetch Google review link');
    }
  };

  const handleSourceChange = (source) => {
    setSources(prevSources => {
      const newSources = prevSources.includes(source)
        ? prevSources.filter(s => s !== source)
        : [...prevSources, source];
      setShowSubmitButton(reviews[0].rating > 0 && newSources.length > 0);
      return newSources;
    });
  };

  const handleRatingChange = (index, rating) => {
    const updatedReviews = [...reviews];
    updatedReviews[index].rating = rating;
    setReviews(updatedReviews);
    setShowSubmitButton(rating > 0 && sources.length > 0);
  };

  const handleSubmit = async () => {
    try {
      const cafeData = sessionStorage.getItem('OG_cafe');
      const cafeObj = JSON.parse(cafeData);
      const phone = localStorage.getItem('OG_phone');

      if (!phone) {
        toast.error('User phone number not found. Please try again.');
        return;
      }

      const userUUIDResponse = await getUserUUID(phone);
      const userId = userUUIDResponse.data.uuid;

      const result = await submitCafeReview(cafeObj._id, reviews[0], phone, sources);
      const reviewId = result.data.reviewId;
      setCurrentReviewId(reviewId);

      if (reviews[0].rating <= 3) {
        setShowFeedbackForm(true);
      } else {
        await recordAnalyticsEvent('google_review_redirect', 'CafeReviewPage', {
          rating: reviews[0].rating,
          question_id: questions[0]._id,
          cafe_id: cafeObj._id,
          user_id: userId,
          review_id: reviewId
        });

        window.open(googleReviewLink, '_blank');
        navigate('/cafe/thank-you');
      }
    } catch (error) {
      toast.error('Failed to submit review');
    }
  };

  const handleFeedbackChange = (e) => {
    setFeedbackText(e.target.value);
  };

  const handleSubmitFeedback = async () => {
    try {
      if (!currentReviewId) {
        toast.error('No review ID found. Please try again.');
        return;
      }

      const result = await updateCafeReview(currentReviewId, feedbackText);
      console.log('Updated review:', result.data.updatedReview);

      toast.success('Feedback submitted successfully');
      navigate('/cafe/thank-you');
    } catch (error) {
      toast.error('Failed to submit feedback');
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <UserHeader cafeName={cafeDetails.name} cafeId={cafeDetails._id} />
      <div className="flex-grow container mx-auto px-4 pb-24">
        {!showFeedbackForm ? (
          <>
            {questions.map((question, index) => (
              <div key={index}>
                <div className="flex items-center justify-center mt-[20px]">
                  <h1 className="text-lg font-medium mb-4">{question.question}</h1>
                </div>
                <div className="flex items-center justify-center mt-[20px]">
                  <img
                    src={Image}
                    alt="icon"
                    height={100}
                    width={100}
                  />
                </div>
                <div className="flex items-center justify-center mt-[20px]">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      onClick={() => handleRatingChange(index, star)}
                      className={`text-5xl ml-2 rounded-lg border w-[60px] h-[56px] border-black ${reviews[index].rating >= star
                        ? "text-yellow-500 bg-yellow-200"
                        : "text-gray-300 bg-white"
                        }`}
                      style={{ boxShadow: "2px 3px 0px 0px #000000" }}
                    >
                      ★
                    </button>
                  ))}
                </div>
              </div>
            ))}
            <div className="mt-8 mb-8">
              <h2 className="text-lg font-medium mb-4">Where did you find us?</h2>
              {["OrderG", "Instagram", "Facebook", "Google", "Other"].map(
                (source) => (
                  <div key={source} className="mb-2">
                    <input
                      type="checkbox"
                      id={source}
                      checked={sources.includes(source)}
                      onChange={() => handleSourceChange(source)}
                      className="mr-2"
                    />
                    <label htmlFor={source}>{source}</label>
                  </div>
                )
              )}
            </div>
            {showSubmitButton && (
              <button
                onClick={handleSubmit}
                className="mt-4 w-full p-4 bg-[#9277FF] text-white rounded text-lg font-semibold"
                style={{ boxShadow: "2px 3px 0px 0px #000000" }}
              >
                Submit Review
              </button>
            )}
          </>
        ) : (
          <div className="mt-8">
            <h2 className="text-lg font-medium mb-4">Please provide additional feedback:</h2>
            <textarea
              value={feedbackText}
              onChange={handleFeedbackChange}
              className="w-full p-2 border rounded"
              rows="4"
            ></textarea>
            <button
              onClick={handleSubmitFeedback}
              className="mt-4 w-full p-4 bg-[#9277FF] text-white rounded text-lg font-semibold"
              style={{ boxShadow: "2px 3px 0px 0px #000000" }}
            >
              Submit Feedback
            </button>
          </div>
        )}
      </div>
      
      <ToastContainer />
      <div className="fixed bottom-0 left-0 right-0 z-50">
        <BottomMenu />
      </div>
    </div>
  );
};

export default CafeReviewPage;