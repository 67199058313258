import React from 'react'
import Reviewcard from "../../components/userside/reviewcarduser"
import Reviews from "../../components/frontpage/review"
const userreview = () => {
  return (
    <div className="menu px-4 mb-4 flex flex-col gap-4">
      <div className="font-bold text-lg mt-4">Reviews</div>
      <div><Reviewcard/></div>
      <div className="font-bold text-sm mt-4">What people are saying</div>
      <div><Reviews/></div>
      <div><Reviews/></div>
      <div><Reviews/></div>
    </div>
  )
}

export default userreview