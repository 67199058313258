import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Bill = () => {
  const { id } = useParams();
  const cafe = useSelector((state) => state.user.cafe);

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center p-4">
      <h1 className="font-bold text-2xl">Yum!</h1>
      <h1 className="font-bold text-2xl">Your order is in works</h1>
      <p className="text-sm text-center my-6">
        We'll keep you updated every step of the way so you know exactly when to
        expect your meal.
      </p>
      <img src="../../waiting.png" alt="Banner" className="my-8" />
      <Link to={`/order/invoice/${id}`} className="text-center block mx-4 w-full rounded-xl py-4 my-1 border-2 border-gray-400 text-gray-500">
        View Order Summary
      </Link>
      <Link to={`/cafe/${cafe._id}?table=${cafe.table}`} className="text-center block mx-4 w-full rounded-xl py-4 my-1 border-2 border-[#FD7347] bg-[#FD7347] text-white">
        Order More Items
      </Link>
    </div>
  );
};

export default Bill;
